import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FareMenuSortListComponent } from './fare-menu-sort-list.component';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonModule } from '@ui/button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FareMenuSortListComponent],
  imports: [
    CommonModule,
    PreLoaderModule,
    DragDropModule,
    ButtonModule,
    TranslateModule,
  ],
  exports: [FareMenuSortListComponent],
})
export class FareMenuSortListModule {}
