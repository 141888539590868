import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiTextareaComponent } from './ui-textarea.component';
import { InputValidationPipe } from '@pipes/input-validation/input-validation.pipe';


@NgModule({
    declarations: [UiTextareaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        InputValidationPipe,
    ],
    exports: [UiTextareaComponent],
})
export class UiTextareaModule {
}
