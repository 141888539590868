<div class="border-radius-10 bg-white w-100 text-center">
  <h2 class="mt-0 mb-4">{{ 'global.labels.attention' | translate }}</h2>
  <p class="mb-0">{{ 'modal.confirm.text.delete' | translate }}</p>
  <ui-button-row [classAlign]="'justify-content-center'">
    <ui-button
      class="me-5"
      [disabled]="pending$ | async"
      [label]="'global.labels.confirm'"
      (handlerClick)="handlerClickConfirm()"
    ></ui-button>
    <ui-button
      [type]="'outline'"
      [disabled]="pending$ | async"
      [label]="'global.labels.cancel'"
      (handlerClick)="handlerClickCancel()"
    >
    </ui-button>
  </ui-button-row>
</div>
