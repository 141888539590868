<div *ngIf="elementsDesign$ | async as elementsDesign">
    <div class="elements" *ngIf="elementsDesign | filterByProp: 'elType':type as filteredElementsDesign; else noData">
        <ngx-masonry [options]="{ gutter: 5 }" [ordered]="true" *ngIf="filteredElementsDesign?.length > 0 else noData">
            <ui-view-design-element *ngFor="let item of filteredElementsDesign; trackBy: identity"
                                    class="masonry-item mb-1"
                                    ngxMasonryItem
                                    [interactive]="true"
                                    [element]="item"
                                    [lyView]="true"
                                    [showToolbar]="false"
                                    (click)="selectDesignElement(item)">

            </ui-view-design-element>
        </ngx-masonry>
    </div>
</div>

<ng-template #noData>
    <p class="text-center">
        {{ 'global.labels.no_data' | translate }}
    </p>
</ng-template>
