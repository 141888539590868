import { FileTypes } from '@core/parameters/file-types';


const signatures: Record<string, FileTypes> = {
    'JVBERi0': FileTypes.Pdf,
    'R0lGODdh': FileTypes.Gif,
    'R0lGODlh': FileTypes.Gif,
    'iVBORw0KGgo': FileTypes.Png,
    '/9j/': FileTypes.Jpg,
};

export const getMimeType = (base64: string): string => {
    for (const sign in signatures)
        if (base64.startsWith(sign)) return signatures[sign];

    return null;
};
