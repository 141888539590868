import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { LangService } from '@libs/lang/lang.service';
import { TariffZoneBody, TariffZoneModel, TariffZoneModelId } from '@libs/tariff-zone/tariff-zone.model';
import { TariffZoneService } from '@libs/tariff-zone/tariff-zone.service';
import { Observable } from 'rxjs';
import { LangModel } from '@libs/lang/lang.model';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


/**
 * Form zone
 */
@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-edit-zone',
    templateUrl: './popup-edit-zone.component.html',
    styleUrls: ['./popup-edit-zone.component.scss'],
})
export class PopupEditZoneComponent {
    readonly #push: PushService = inject(PushService);

    public zoneId: TariffZoneModelId = +this.data.params?.serviceParams?.zoneId;
    public srvId: number = +this.data.params?.serviceParams?.srvId;
    public languages$: Observable<LangModel[]> = this.langService.languages;
    public zone$: Observable<TariffZoneModel> = this.tariffZoneService.getById(
        this.zoneId,
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupEditZoneComponent>,
        private langService: LangService,
        private tariffZoneService: TariffZoneService,
    ) {
    }

    public handlerEditZone(body: TariffZoneBody): void {
        this.tariffZoneService
            .edit({
                ...body,
                ...{ zoneId: this.zoneId },
            })
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessChange);
                this.dialogRef.close();
            });
    }

    public handlerCancel(): void {
        this.dialogRef.close();
    }
}
