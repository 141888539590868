import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { StopModel } from '@libs/stops/stop.model';
import { GoogleMapCoords, GoogleMapMarker } from '@libs/interfaces';

type StopMapState = 'list' | 'add' | 'edit';

/**
 * Map showing stops
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-stop-map',
  templateUrl: './stop-map.component.html',
  styleUrls: ['./stop-map.component.scss'],
})
export class StopMapComponent {
  /**
   * Map center coordinates
   */
  @Input() center: GoogleMapCoords;
  /**
   * Zoom value
   */
  @Input() zoom: number;
  /**
   * Changing stop coordinates
   *
   * @returns Coordinate object
   */
  @Output() outputCoord: EventEmitter<GoogleMapCoords> =
    new EventEmitter<GoogleMapCoords>();
  /**
   * Stop cache
   */
  cacheStops: StopModel[];
  /**
   * Array of markers on the map
   */
  displayMarkers: GoogleMapMarker[] = [];
  /**
   * Map state - adding a stop | editing | display all
   */
  state: StopMapState;
  /**
   * Map add mode flag
   */
  @Input() set addMode(mode: boolean) {
    if (mode) {
      this.state = 'add';
      this.displayMarkers = [];
    } else if (mode === false) {
      this.setListState(this.cacheStops);
    }
  }
  /**
   * List of stops
   */
  @Input() set stops(stops: StopModel[]) {
    if (stops) {
      this.cacheStops = stops;
      this.setListState(stops);
    }
  }
  /**
   * Stop object for editing
   */
  @Input() set stop(stop: StopModel) {
    if (stop) {
      this.state = 'edit';
      this.displayMarkers = [stop].map((stopV) => ({
        lat: stopV.lat,
        lng: stopV.lon,
        draggable: true,
        label: stopV.stName,
      }));
    } else {
      this.setListState(this.cacheStops);
    }
  }

  handlerOutputStop(coordinates: { coords: GoogleMapCoords }): void {
    this.outputCoord.emit(coordinates.coords);
  }

  handlerMapClick(coordinates: { coords: GoogleMapCoords }): void {
    if (this.state === 'add') {
      this.outputCoord.emit(coordinates.coords);
      this.displayMarkers = [
        {
          lat: coordinates.coords.lat,
          lng: coordinates.coords.lng,
          draggable: true,
          label: '',
        },
      ];
    }
  }

  private setListState(stops: StopModel[]): void {
    this.state = 'list';
    if (stops) {
      this.displayMarkers = stops.map((stop) => ({
        lat: stop.lat,
        lng: stop.lon,
        draggable: false,
        label: stop.stName,
      }));
    }
  }
}
