import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuComponent } from './mobile-menu.component';
import { UserCardModule } from '../user-card/user-card.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentProtocolPipe } from '@pipes/current-protocol/current-protocol.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html/safe-html.pipe';


@NgModule({
    declarations: [MobileMenuComponent],
    imports: [
        CommonModule,
        UserCardModule,
        RouterModule,
        TranslateModule,
        CurrentProtocolPipe,
        SafeHtmlPipe,
    ],
    exports: [MobileMenuComponent],
})
export class MobileMenuModule {
}
