import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  ConnectableObservable,
  Observable,
  Subject,
} from 'rxjs';
import {
  TariffSpecificValidityPeriodBody,
  TariffSpecificValidityPeriodIntervalBody,
  TariffSpecificValidityPeriodModel,
} from '@libs/specific-validity-period/specific-validity-period.model';
import {
  catchError,
  finalize,
  publishReplay,
  refCount,
  repeatWhen,
  tap,
} from 'rxjs/operators';
import { ErrorResponseModel } from '@libs/error/error.model';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';

/**
 * Service for working with special periods of validity
 */
@Injectable({
  providedIn: 'root',
})
export class SpecificValidityPeriodService {
  private pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  private readonly refresh$: Subject<unknown> = new Subject();
  private readonly refreshDetail$: Subject<unknown> = new Subject();

  private readonly requestToGetTariffTicketPeriod: Observable<
    TariffSpecificValidityPeriodModel[]
  > = this.http
    .get<TariffSpecificValidityPeriodModel[]>(
      this.apiConfigService.getMethodUrl('boservice.tariff.listticketperiod'),
    )
    .pipe(
      repeatWhen(() => this.refresh$),
      publishReplay(1),
      refCount(),
    ) as ConnectableObservable<TariffSpecificValidityPeriodModel[]>;

  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
  ) {}

  /**
   * Returns a list of special tariff periods
   */
  get tariffTicketPeriod(): Observable<TariffSpecificValidityPeriodModel[]> {
    return this.requestToGetTariffTicketPeriod;
  }

  public get pending(): Observable<boolean> {
    return this.pending$.asObservable();
  }

  add(body: TariffSpecificValidityPeriodBody): Observable<unknown> {
    this.pending$.next(true);
    return this.requestToAdd(body);
  }

  edit(body: TariffSpecificValidityPeriodBody): Observable<unknown> {
    this.pending$.next(true);
    return this.requestToEdit(body);
  }

  remove(ttpId: number): Observable<unknown> {
    this.pending$.next(true);
    return this.requestToRemove(ttpId);
  }

  /**
   * Get detailed information about the special period
   *
   * @param ttpId
   */
  getById(ttpId: number): Observable<TariffSpecificValidityPeriodModel> {
    return this.http
      .get<TariffSpecificValidityPeriodModel>(
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.gettariffticketperioddata',
        ),
        {
          params: {
            ttpId,
          },
        },
      )
      .pipe(
        repeatWhen(() => this.refreshDetail$),
        publishReplay(1),
        refCount(),
      );
  }

  addInterval(
    body: TariffSpecificValidityPeriodIntervalBody,
  ): Observable<unknown> {
    this.pending$.next(true);
    return this.requestToAddInterval(body);
  }

  removeInterval(ttpId: number, dateFrom: string): Observable<unknown> {
    this.pending$.next(true);
    return this.requestToRemoveInterval(ttpId, dateFrom);
  }

  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }

  private requestToRemove(ttpId: number): Observable<unknown> {
    return this.http
      .delete(
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.removetariffticketperiod',
        ),
        {
          params: {
            ttpId,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  private requestToRemoveInterval(
    ttpId: number,
    dateFrom: string,
  ): Observable<unknown> {
    return this.http
      .request(
        'DELETE',
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.removetariffticketperioddate',
        ),
        {
          params: {
            dateFromArr: [dateFrom],
            ttpId,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => {
          this.refreshDetail$.next(null);
        }),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  private requestToAdd(
    body: TariffSpecificValidityPeriodBody,
  ): Observable<unknown> {
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.addtariffticketperiod',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  private requestToEdit(
    body: TariffSpecificValidityPeriodBody,
  ): Observable<unknown> {
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.settariffticketperiodname',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  private requestToAddInterval(
    body: TariffSpecificValidityPeriodIntervalBody,
  ): Observable<unknown> {
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.tariff.addtariffticketperioddate',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refreshDetail$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }
}
