// @ts-nocheck
import { resolve } from '@angular/compiler-cli';

/**
 * Class for building trees
 * A plugin is used to display trees
 * https://github.com/CirclonGroup/angular-tree-component
 */
export abstract class TreeFactory {
  /**
   * Breadcrumbs Array
   *
   * @private
   */
  private breadcrumbs: string[] = [];
  /**
   * Tree Array
   *
   * @private
   */
  private treeStore: unknown[];
  /**
   * Array sort list
   *
   * @private
   */
  private listStore: unknown[];
  /**
   * Source array
   *
   * @private
   */
  private readonly source: any[];
  /**
   * id prop code
   *
   * @private
   */
  private readonly id: string;
  /**
   * parent id prop code
   *
   * @private
   */
  private readonly pId: string;

  constructor(source: unknown[], idField: string, parentField: string) {
    this.id = idField;
    this.pId = parentField;
    this.source = source;
  }

  get tree(): any[] {
    return this.treeStore;
  }

  get list(): any[] {
    return this.listStore;
  }

  targetProp(item: unknown): void {}
  // @ts-ignore
  targetBlankProp(item: unknown, id: string, pId: string): unknown {}

  /**
   * Tree generate
   */
  makeTree(parent?: any) {
    this.treeStore = [];
    // @ts-ignore
    const rootId = parent ? this.targetProp(parent)[this.id] : null;
    this.buildTree(this.source, parent, parent ? -1 : 0, rootId);
    return this.treeStore;
  }
  /**
   * Run sorted list generation from tree
   * (*) Requires a created tree
   */
  makeSortList(excludeIds?: string[]) {
    this.listStore = [];
    this.transformToFlat(this.treeStore, excludeIds);
    return this.listStore;
  }
  /**
   * Recursive transformation of a tree into a flat sorted list
   *
   * @param tree
   * @param excludeIds
   *@private
   */
  // @ts-ignore
  private transformToFlat(tree, excludeIds?: string[]): void {
    // @ts-ignore
    tree?.forEach((item) => {
      if (
        excludeIds &&
        excludeIds.indexOf(this.targetProp(item)[this.id]) > -1
      ) {
        return;
      }
      this.listStore.push(item);
      if (item.children instanceof Array) {
        this.transformToFlat(item.children, excludeIds);
      }
    });
  }

  /**
   * Recursive transformation of a list into a tree by id -> pId
   * Output array with children property
   *
   * @param source - source array list
   * @param parent - current parent element
   * @param level - nesting level
   * @param rootId - Root element id
   * @private
   */
  private buildTree(
    source: any[],
    parent?: any,
    level?: number,
    rootId?: number,
  ): void {
    level = typeof level !== 'undefined' ? level : 0;

    parent =
      typeof parent !== 'undefined'
        ? parent
        : this.targetBlankProp(null, this.id, this.pId);

    rootId = typeof rootId !== 'undefined' ? rootId : null;

    const filterChildren: Function =
      rootId !== null && rootId >= 0 && level === -1
        ? (item: unknown) =>
            // @ts-ignore
            this.targetProp(item)[this.id] === this.targetProp(parent)[this.id]
        : (item: unknown) =>
            // @ts-ignore
            this.targetProp(item)[this.pId] ===
            this.targetProp(parent)[this.id];

    const children = source.filter(filterChildren).map((item) => ({ ...item }));

    if ((rootId !== null && level === -1) || (rootId === null && level === 1)) {
      this.breadcrumbs = [];
    }
    parent.level = level;
    parent.breadcrumbs = [...[], ...this.breadcrumbs];

    if (children && children.length) {
      if (parent.orgName && this.breadcrumbs.indexOf(parent.orgName) === -1) {
        this.breadcrumbs.push(parent.orgName);
      }

      if (
        this.targetProp(parent)[this.id] === rootId &&
        !this.treeStore.find((item) => item[this.id] === parent[this.id])
      ) {
        this.treeStore = children;
      } else {
        parent.children = children;
      }

      children.map((child) => {
        this.buildTree(source, child, level + 1, rootId);
      });
    }
  }
}

/**
 * Factory for building trees
 */
export class CommonTreeFactory extends TreeFactory {
  constructor(source: unknown[], idField: string, parentField: string) {
    super(source, idField, parentField);
  }

  targetProp(item: unknown): unknown {
    return item;
  }

  targetBlankProp(item: unknown, id: string, pId: string): unknown {
    return { [id]: item ? item[id] : null };
  }
}
