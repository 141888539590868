<form class="row" [formGroup]="form">
  <div class="col-12">
    <div
      *ngFor="let arrayItem of dateControls.controls; let i = index"
      [formArrayName]="'datePeriod'"
    >
      <ng-container [formGroupName]="i">
        <div
          class="d-flex flex-row flex-nowrap align-items-center justify-content-between"
          [class.border-top]="i > 0"
          [class.mt-6]="i > 0"
          [class.pt-6]="i > 0"
        >
          <div>
            <div
              class="d-flex flex-column flex-xl-row flex-nowrap justify-content-xl-between"
            >
              <div class="d-flex flex-row align-items-center">
                <ui-calendar-control
                  class="mb-5"
                  [required]="true"
                  [label]="'global.forms.labels.dateFrom'"
                  [formControlName]="'dateFrom'"
                >
                </ui-calendar-control>
                <timepicker
                  class="ms-5"
                  [formControlName]="'timeFrom'"
                  [showMeridian]="timeFormat.showMeridian"
                ></timepicker>
              </div>
              <span class="mx-md-4 my-2 my-md-0"></span>
              <div class="d-flex flex-row align-items-center">
                <ui-calendar-control
                  class="mb-5"
                  [required]="true"
                  [formControlName]="'dateTo'"
                  [label]="'ui.specific-validity-period-form.date-sell-to'"
                >
                </ui-calendar-control>
                <timepicker
                  class="ms-5"
                  [formControlName]="'timeTo'"
                  [showMeridian]="timeFormat.showMeridian"
                ></timepicker>
              </div>
            </div>

            <div
              class="d-flex flex-column flex-xl-row flex-nowrap justify-content-xl-between pt-3 mt-3"
            >
              <div class="d-flex flex-row align-items-center">
                <ui-calendar-control
                  [required]="true"
                  [label]="'ui.specific-validity-period-form.date-sell'"
                  [formControlName]="'sellDateFrom'"
                >
                </ui-calendar-control>
                <timepicker
                  class="ms-5"
                  [formControlName]="'sellTimeFrom'"
                  [showMeridian]="timeFormat.showMeridian"
                ></timepicker>
              </div>
              <span class="mx-md-4 my-2 my-md-0"></span>
              <div class="d-flex flex-row align-items-center">
                <ui-calendar-control
                  [required]="true"
                  [label]="'ui.specific-validity-period-form.date-sell-to'"
                  [formControlName]="'sellDateTo'"
                >
                </ui-calendar-control>
                <timepicker
                  class="ms-5"
                  [formControlName]="'sellTimeTo'"
                  [showMeridian]="timeFormat.showMeridian"
                ></timepicker>
              </div>
            </div>
          </div>

          <div class="ms-3 controls" *ngIf="!lyView">
            <span
              role="button"
              tabindex="0"
              class="pseudo pseudo--danger ms-2 pseudo--border-off border p-1 control control--minus"
              *ngIf="i < dateControls.controls?.length - 1"
              (click)="handlerRemove(i)"
              (keydown.enter)="handlerRemove(i)"
              >&minus;</span
            >
            <i
              role="button"
              tabindex="0"
              class="pseudo pseudo--border-off icon-plus control border p-1"
              (click)="handlerAdd(dateControls, i + 1)"
              (keydown.enter)="handlerAdd(dateControls, i + 1)"
            ></i>
          </div>
        </div>
      </ng-container>
    </div>
    <ui-button-row [lyView]="lyView">
      <ui-button
        [size]="'lg'"
        [disabled]="form?.status === 'INVALID' || pending"
        [label]="'global.actions.add'"
        (handlerClick)="handlerSubmit()"
      >
      </ui-button>
    </ui-button-row>
  </div>
</form>
