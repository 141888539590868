<button class="btn d-print-none"
        [class.w-100]="wide"
        [attr.title]="label | translate"
        [disabled]="disabled"
        [ngClass]="['btn-' + size, 'btn-' + type]"
        (click)="handlerClick.emit($event)">


    <i class="icon me-1" *ngIf="icon" [ngClass]="'icon-' + icon"></i>
    <span [innerHTML]="label | translate"></span>

    <span *ngIf="badgeText"
          [ngClass]="badgeColorClass"
          class="badge">{{ badgeText }}<span class="visually-hidden"></span></span>

</button>
