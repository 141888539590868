import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';
import { ErrorResponseModel } from '@libs/error/error.model';
import { TariffServiceModelId } from '@libs/tariff-service/tariff-service.model';
import { catchError, finalize, repeatWhen, tap } from 'rxjs/operators';
import {
  FareMenuBodyModel,
  FareMenuModel,
} from '@libs/fare-menu/fare-menu.model';

/**
 * Service for working with sorting tariffs in the menu
 */
@Injectable({
  providedIn: 'root',
})
export class FareMenuService {
  private pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private readonly refresh$: Subject<unknown> = new Subject();
  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
  ) {}
  /**
   * Server pending flag
   */
  get pending(): Observable<boolean> {
    return this.pending$.asObservable();
  }
  /**
   * Getting a list of all tariffs (except for archived ones) for sorting editing.
   *
   * @param trmId - transportation organizer ID
   * @param srvId - Service id/null
   * @param trfType - tariff type/null: 1 - one-time tariff (payg) 2 - subscription tariff (pp)
   */
  getFareMenu(
    trmId: number,
    srvId: TariffServiceModelId,
    trfType: 1 | 2,
  ): Observable<FareMenuModel[]> {
    return this.http
      .get<FareMenuModel[]>(
        this.apiConfigService.getMethodUrl('boservice.tariff.listfaremenu'),
        {
          params: {
            ...{
              trmId,
              trfType,
            },
            ...(srvId ? { srvId } : null),
          },
        },
      )
      .pipe(
        repeatWhen(() => this.refresh$),
        catchError((error) => of(null)),
      );
  }
  /**
   * Editing the sorting order of tariffs for display on the interface
   *
   * @param body - FareMenuBodyModel
   */
  setFareMenu(body: FareMenuBodyModel): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.tariff.setfaresort'),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }
  /**
   * Request error handler
   *
   * @param error - error object
   * @private
   */
  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }
}
