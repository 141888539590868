import { throwError } from 'rxjs';


export class PromiseLoad {
    readonly #url: string;
    readonly #authTokenStorageName: string;

    constructor(url: string, tokenStorageName?: string | undefined) {
        this.#authTokenStorageName = tokenStorageName;
        this.#url = url;
    }

    public get<T>(): Promise<T> {
        return new Promise((resolve, reject) => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.open('GET', this.#url, true);

            if (this.#authTokenStorageName)
                xhr.setRequestHeader(
                    'Authorization',
                    window?.localStorage?.getItem(this.#authTokenStorageName),
                );

            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(throwError('Error load config api'));
                }
            };

            xhr.onerror = (): void => {
                reject(throwError('Error load config api'));
            };

            xhr.send();
        });
    }
}

