import { Component } from '@angular/core';
import { FormSelectComponent } from '@ui/form/form-select/form-select.component';
import { NgClass, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessagePipe } from '@pipes/validation-message/validation-message.pipe';
import { ReactiveFormsModule } from '@angular/forms';


@Component({
    selector: 'ui-form-select-organization',
    standalone: true,
    templateUrl: './form-select-organization.component.html',
    styleUrls: ['./form-select-organization.component.scss'],
    imports: [
        NgIf,
        NgSelectModule,
        TranslateModule,
        ValidationMessagePipe,
        NgClass,
        ReactiveFormsModule,
    ],
})
export class FormSelectOrganizationComponent extends FormSelectComponent {

}
