import { ChangeDetectionStrategy, Component, Inject, TrackByFunction } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { Observable } from 'rxjs';
import { ApElementActListResponse } from '@api/models/BannerApi/Contract/ap-element-act-list-response';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-select-design-element',
    templateUrl: './popup-select-design-element.component.html',
    styleUrls: ['./popup-select-design-element.component.scss'],
})
export class PopupSelectDesignElementComponent {
    public control: AbstractControl = this.data.params?.serviceParams?.control;
    public type: number = +this.data.params?.serviceParams?.type;
    public elementsDesign$: Observable<ApElementActListResponse[]> =
        this.data.params?.serviceParams?.elementsDesign;

    public readonly identity: TrackByFunction<ApElementActListResponse> =
        (index: number, item: ApElementActListResponse): number => item.elId;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupSelectDesignElementComponent>,
    ) {
    }

    public selectDesignElement(item: ApElementActListResponse): void {
        setTimeout(() => {
            this.control.setValue(item?.elId);
        }, 0);

        this.dialogRef.close();
    }
}
