<h2 class="mt-0 mb-4">{{ 'global.labels.attention' | translate }}</h2>
<p class="mb-0">{{ 'modal.confirm.text.delete' | translate }}</p>
<ui-form-abstract-note
    class="d-block mt-6"
    [pending]="pending$ | async"
    [noteRequired]="noteRequired"
    (submitForm)="handlerClickConfirm($event)"
    (cancelClick)="dialogRef.close()"
>
</ui-form-abstract-note>
