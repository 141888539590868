<div class="form-check" [class.form-switch]="switch">
    <input class="form-check-input"
           #checkBoxInput
           [attr.id]="id"
           [attr.checked]="value || null"
           [disabled]="disabled ? disabled : null"
           (change)="onChange($event)"
           (keydown.enter)="onChange($event)"
           type="checkbox" />

    <label class="form-check-label mb-0"
           [attr.for]="id">{{ label }}<sup *ngIf="isRequiredField">*</sup></label>

    <div *ngIf="hint"
         class="description mt-0"
         [innerHTML]="hint"></div>
</div>
