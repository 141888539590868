import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({
    name: 'safeSrc',
    standalone: true,
})
export class SafeSrcPipe implements PipeTransform {
    readonly #sanitizer: DomSanitizer = inject(DomSanitizer);

    public transform(url: string): SafeResourceUrl {
        return this.#sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
