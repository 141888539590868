import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@ui/icon/icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApElementListResponse } from '@api/models/BannerApi/Contract/ap-element-list-response';
import { FileTypes } from '@core/parameters/file-types';
import { ApElementActSetRequest } from '@api/models/BannerApi/Contract/ap-element-act-set-request';
import { Base64ImgWrapPipe } from '@pipes/base64-img-wrap/base64-img-wrap.pipe';
import { SafeSrcPipe } from '@pipes/safe-src/safe-src.pipe';
import { FormCheckboxComponent } from '@ui/form/form-checkbox/form-checkbox.component';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-view-design-element',
    templateUrl: './view-design-element.component.html',
    styleUrls: ['./view-design-element.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        TranslateModule,
        IconModule,
        MatTooltipModule,
        Base64ImgWrapPipe,
        SafeSrcPipe,
        FormCheckboxComponent,
    ],
})
export class ViewDesignElementComponent {
    /**
     * Interactive behavior flag
     */
    @Input() public interactive: boolean;
    /**
     * Design element object
     */
    @Input() public element: ApElementListResponse;
    /**
     * Read-only mode flag
     */
    @Input() public lyView: boolean;
    /**
     * Version id
     */
    @Input() public apvId: number;
    /**
     * Show toolbar flag
     */
    @Input() public showToolbar: boolean = true;
    /**
     * Toggle bar flag
     */
    @Input() public showToggle: boolean = true;
    /**
     * Mark active elements
     */
    @Input() public showActive: boolean;

    @Output() public editDesignElement: EventEmitter<ApElementListResponse> = new EventEmitter<ApElementListResponse>();
    @Output() public deleteDesignElement: EventEmitter<ApElementListResponse> = new EventEmitter<ApElementListResponse>();

    @Output() public toggleActivateFlagDesignElement: EventEmitter<ApElementActSetRequest> = new EventEmitter<ApElementActSetRequest>();

    public readonly fileTypes: typeof FileTypes = FileTypes;

    public onChangeActivateFlag(element: ApElementListResponse): void {
        this.toggleActivateFlagDesignElement.emit({
            edId: element.edId,
            apvId: this.apvId,
            isActive: element.isActive ? 0 : 1,
        });
    }

    protected readonly Boolean = Boolean;
}
