<div class="form-group mb-0">
  <label
    class="d-block"
    *ngIf="label"
    [class.mb-1]="!description"
    [class.mb-0]="description"
    [attr.for]="identifier"
    >{{ label | translate }}<sup *ngIf="required">*</sup>
  </label>
  <div
    class="description mb-1"
    *ngIf="description"
    [innerHTML]="description | translate"
  ></div>

  <textarea
    class="form-control form-control--textarea"
    #textarea
    [attr.id]="identifier"
    [attr.placeholder]="filler | translate"
    [class.is-blured]="
      control?.invalid && control?.touched && !control?.pristine
    "
    [class.is-invalid]="changed && control?.invalid"
    [class.is-valid]="changed && control?.valid"
    (change)="onChange($event)"
    (blur)="onBlur($event)"
    (keyup)="onKeyUp($event)"
    type="text"
    autosize=""
  ></textarea>

  <div
    class="invalid-feedback"
    [@visibilityChanged]="changed && control?.valid"
  >
    <span
      class="invalid-feedback-box"
      >{{ (control?.errors | inputValidation: formControlName) | translate }}</span
    >
  </div>
</div>
