import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiInputComponent } from './ui-input.component';
import { InputValidationPipe } from '@pipes/input-validation/input-validation.pipe';


@NgModule({
    declarations: [UiInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        InputValidationPipe,
    ],
    exports: [UiInputComponent],
})
export class UiInputModule {
}
