import { Injectable } from '@angular/core';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import { catchError, filter, finalize, map, publishReplay, refCount, repeatWhen, tap } from 'rxjs/operators';
import { RoutesModel, RoutesServiceModel, RoutesTransportTypeModel, SdIdType } from './routes.model';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';
import { ErrorResponseModel } from '@libs/error/error.model';
import { OrganizationFilterType, OrganizationModel } from '@libs/organization/organization.model';
import { CommonTreeFactory } from '@libs/factory/tree/tree.factory';
import { AddServiceDetailRequest } from '@api/models/BoLanka/Contract/add-service-detail-request';
import { GetServiceDetailResponse } from '@api/models/BoLanka/Contract/get-service-detail-response';


/**
 * Service Routes
 */
@Injectable({
    providedIn: 'root',
})
export class RoutesService {
    private pending$: Subject<boolean> = new Subject();
    private refreshRoutesData$: Subject<boolean> = new Subject();
    private refreshRoutesServicesData$: Subject<boolean> = new Subject();
    private refreshRoutesTransportTypesData$: Subject<boolean> = new Subject();

    private readonly requestToGetRoutes: Observable<RoutesModel[]> = this.http
        .get<RoutesModel[]>(
            this.apiConfigService.getMethodUrl('boservice.routes.listservicedetail'),
        )
        .pipe(
            repeatWhen(() => this.refreshRoutesData$),
            filter((routes: RoutesModel[]) => !!routes),
            publishReplay(1),
            refCount(),
        ) as ConnectableObservable<RoutesModel[]>;

    private readonly requestToGetServicesRoutes: Observable<
        RoutesServiceModel[]
    > = this.http
        .get<RoutesServiceModel[]>(
            this.apiConfigService.getMethodUrl(
                'boservice.routes.listservicesforroute',
            ),
        )
        .pipe(
            repeatWhen(() => this.refreshRoutesServicesData$),
            filter((services: RoutesServiceModel[]) => !!services),
            publishReplay(1),
            refCount(),
        ) as ConnectableObservable<RoutesServiceModel[]>;

    private readonly requestToGetTransportTypesRoutes: Observable<
        RoutesTransportTypeModel[]
    > = this.http
        .get<RoutesTransportTypeModel[]>(
            this.apiConfigService.getMethodUrl('boservice.routes.listsdtype'),
        )
        .pipe(
            repeatWhen(() => this.refreshRoutesTransportTypesData$),
            filter((types: RoutesTransportTypeModel[]) => !!types),
            publishReplay(1),
            refCount(),
        ) as ConnectableObservable<RoutesTransportTypeModel[]>;

    readonly #requestToGetOrganizations: Observable<OrganizationModel[]> =
        this.http
            .post<OrganizationModel[]>(
                this.apiConfigService.getMethodUrl('boservice.users.listvisorg'),
                {
                    orgTypes: [OrganizationFilterType.Provider],
                    isDept: 0,
                },
            )
            .pipe(
                filter(Boolean),
                map((orgs: OrganizationModel[]) => {
                    if (orgs.length === 1) {
                        return orgs;
                    } else {
                        const tree = new CommonTreeFactory(orgs, 'orgId', 'pOrgId');
                        tree.makeTree();
                        tree.makeSortList();
                        return tree.list;
                    }
                }),
                publishReplay(1),
                refCount(),
            ) as ConnectableObservable<OrganizationModel[]>;

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
    ) {
    }

    get pending(): Observable<boolean> {
        return this.pending$;
    }

    /**
     * Returns a list of routes
     */
    get routes(): Observable<RoutesModel[]> {
        return this.requestToGetRoutes;
    }

    /**
     * Returns a list of services
     */
    get services(): Observable<RoutesServiceModel[]> {
        return this.requestToGetServicesRoutes;
    }

    /**
     * Returns a list of vehicles
     */
    get transportTypes(): Observable<RoutesTransportTypeModel[]> {
        return this.requestToGetTransportTypesRoutes;
    }

    get organisationsForExternalIds(): Observable<OrganizationModel[]> {
        return this.#requestToGetOrganizations;
    }

    /**
     * Get detailed route data
     *
     * @param sdId - Route ID
     */
    getById(sdId: SdIdType): Observable<GetServiceDetailResponse> {
        this.pending$.next(true);
        return this.http
            .get(
                this.apiConfigService.getMethodUrl('boservice.routes.getservicedetail'),
                {
                    params: {
                        sdId,
                    },
                },
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            ) as Observable<GetServiceDetailResponse>;
    }

    /**
     * Delete route
     *
     * @param sdId - Route Id
     */
    delete(sdId: SdIdType): Observable<unknown> {
        this.pending$.next(true);
        return this.http
            .delete(
                this.apiConfigService.getMethodUrl(
                    'boservice.routes.removeservicedetail',
                    {
                        sdId: String(sdId),
                    },
                ),
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshRoutesData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Create new route
     *
     * @param routeBody - RoutesBody
     */
    add(routeBody: AddServiceDetailRequest): Observable<unknown> {
        this.pending$.next(true);
        return this.http
            .post(
                this.apiConfigService.getMethodUrl('boservice.routes.addservicedetail'),
                routeBody,
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshRoutesData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Edit route
     */
    edit(sdId: SdIdType, routeBody: AddServiceDetailRequest): Observable<unknown> {
        this.pending$.next(true);
        return this.http
            .put(
                this.apiConfigService.getMethodUrl(
                    'boservice.routes.setservicedetail',
                    {
                        sdId: String(sdId),
                    },
                ),
                routeBody,
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshRoutesData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    private errorHandler(error: ErrorResponseModel): any {
        this.pending$.next(false);
        throw error;
    }
}
