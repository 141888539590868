/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUserRequest as UserServiceContractAddUserRequest } from '../models/UserService/Contract/add-user-request';
import { AddUserResponse as UserServiceContractAddUserResponse } from '../models/UserService/Contract/add-user-response';
import { GetNewPasswdRequest as UserServiceContractGetNewPasswdRequest } from '../models/UserService/Contract/get-new-passwd-request';
import { GetNewPasswdResponse as UserServiceContractGetNewPasswdResponse } from '../models/UserService/Contract/get-new-passwd-response';
import { Language as UserServiceContractLanguage } from '../models/UserService/Contract/language';
import { SetNewPasswordRequest as UserServiceContractSetNewPasswordRequest } from '../models/UserService/Contract/set-new-password-request';
import { SetPasswdRequest as UserServiceContractSetPasswdRequest } from '../models/UserService/Contract/set-passwd-request';
import { SetUserDataRequest as UserServiceContractSetUserDataRequest } from '../models/UserService/Contract/set-user-data-request';
import { User as UserServiceContractUser } from '../models/UserService/Contract/user';
import { UserType as UserServiceContractUserType } from '../models/UserService/Contract/user-type';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUsersListLanguagesGet
   */
  static readonly ApiUsersListLanguagesGetPath = '/api/Users/ListLanguages';

  /**
   * ### Claim
   *  boservice.users.listlanguages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListLanguagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListLanguagesGet$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractLanguage>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListLanguagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractLanguage>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.users.listlanguages
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListLanguagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListLanguagesGet$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<UserServiceContractLanguage>> {

    return this.apiUsersListLanguagesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractLanguage>>) => r.body as Array<UserServiceContractLanguage>)
    );
  }

  /**
   * ### Claim
   *  boservice.users.listlanguages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListLanguagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListLanguagesGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractLanguage>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListLanguagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractLanguage>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.users.listlanguages
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListLanguagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListLanguagesGet(params?: {
  },
  context?: HttpContext

): Observable<Array<UserServiceContractLanguage>> {

    return this.apiUsersListLanguagesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractLanguage>>) => r.body as Array<UserServiceContractLanguage>)
    );
  }

  /**
   * Path part for operation apiUsersListUsersGet
   */
  static readonly ApiUsersListUsersGetPath = '/api/Users/ListUsers';

  /**
   * Getting a list of users.
   *
   * ### Claim
   *  boservice.users.listusers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListUsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUsersGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organization ID
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUser>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListUsersGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUser>>;
      })
    );
  }

  /**
   * Getting a list of users.
   *
   * ### Claim
   *  boservice.users.listusers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListUsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUsersGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organization ID
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUser>> {

    return this.apiUsersListUsersGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUser>>) => r.body as Array<UserServiceContractUser>)
    );
  }

  /**
   * Getting a list of users.
   *
   * ### Claim
   *  boservice.users.listusers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListUsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUsersGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organization ID
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUser>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListUsersGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUser>>;
      })
    );
  }

  /**
   * Getting a list of users.
   *
   * ### Claim
   *  boservice.users.listusers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListUsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUsersGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organization ID
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUser>> {

    return this.apiUsersListUsersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUser>>) => r.body as Array<UserServiceContractUser>)
    );
  }

  /**
   * Path part for operation apiUsersAddUserPost
   */
  static readonly ApiUsersAddUserPostPath = '/api/Users/AddUser';

  /**
   * User creation.
   *
   * ### Claim
   *  boservice.users.adduser
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersAddUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New user
     */
    body?: UserServiceContractAddUserRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractAddUserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersAddUserPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractAddUserResponse>;
      })
    );
  }

  /**
   * User creation.
   *
   * ### Claim
   *  boservice.users.adduser
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersAddUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New user
     */
    body?: UserServiceContractAddUserRequest
  },
  context?: HttpContext

): Observable<UserServiceContractAddUserResponse> {

    return this.apiUsersAddUserPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractAddUserResponse>) => r.body as UserServiceContractAddUserResponse)
    );
  }

  /**
   * User creation.
   *
   * ### Claim
   *  boservice.users.adduser
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersAddUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New user
     */
    body?: UserServiceContractAddUserRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractAddUserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersAddUserPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractAddUserResponse>;
      })
    );
  }

  /**
   * User creation.
   *
   * ### Claim
   *  boservice.users.adduser
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersAddUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New user
     */
    body?: UserServiceContractAddUserRequest
  },
  context?: HttpContext

): Observable<UserServiceContractAddUserResponse> {

    return this.apiUsersAddUserPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractAddUserResponse>) => r.body as UserServiceContractAddUserResponse)
    );
  }

  /**
   * Path part for operation apiUsersSetUserDataPost
   */
  static readonly ApiUsersSetUserDataPostPath = '/api/Users/SetUserData';

  /**
   * Editing user data.
   *
   * ### Claim
   *  boservice.users.setuserdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersSetUserDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetUserDataPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User data
     */
    body?: UserServiceContractSetUserDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersSetUserDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing user data.
   *
   * ### Claim
   *  boservice.users.setuserdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersSetUserDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetUserDataPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User data
     */
    body?: UserServiceContractSetUserDataRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersSetUserDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersRemoveUserDelUsrIdDelete
   */
  static readonly ApiUsersRemoveUserDelUsrIdDeletePath = '/api/Users/RemoveUser/{delUsrId}';

  /**
   * Deleting a user.
   *
   * ### Claim
   *  boservice.users.removeuser
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersRemoveUserDelUsrIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveUserDelUsrIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID to be deleted
     */
    delUsrId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersRemoveUserDelUsrIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('delUsrId', params.delUsrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a user.
   *
   * ### Claim
   *  boservice.users.removeuser
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersRemoveUserDelUsrIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveUserDelUsrIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID to be deleted
     */
    delUsrId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersRemoveUserDelUsrIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersSetPasswdPost
   */
  static readonly ApiUsersSetPasswdPostPath = '/api/Users/SetPasswd';

  /**
   * Editing user data.
   *
   * ### Claim
   *  boservice.users.setpasswd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersSetPasswdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetPasswdPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User data to update
     */
    body?: UserServiceContractSetPasswdRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersSetPasswdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing user data.
   *
   * ### Claim
   *  boservice.users.setpasswd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersSetPasswdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetPasswdPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User data to update
     */
    body?: UserServiceContractSetPasswdRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersSetPasswdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersSetNewPasswdPost
   */
  static readonly ApiUsersSetNewPasswdPostPath = '/api/Users/SetNewPasswd';

  /**
   * Changing the current user's password.
   *
   * ### Claim
   *  boservice.users.setnewpasswd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersSetNewPasswdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetNewPasswdPost$Response(params?: {
    lang?: string;
    body?: UserServiceContractSetNewPasswordRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersSetNewPasswdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing the current user's password.
   *
   * ### Claim
   *  boservice.users.setnewpasswd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersSetNewPasswdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetNewPasswdPost(params?: {
    lang?: string;
    body?: UserServiceContractSetNewPasswordRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersSetNewPasswdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersUserGetByIdGet
   */
  static readonly ApiUsersUserGetByIdGetPath = '/api/Users/UserGetById';

  /**
   * ### Claim
   *  boservice.users.usergetbyid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersUserGetByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersUserGetByIdGet$Plain$Response(params?: {
    lang?: string;
    usrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractUser>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersUserGetByIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('usrId', params.usrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractUser>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.users.usergetbyid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersUserGetByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersUserGetByIdGet$Plain(params?: {
    lang?: string;
    usrId?: number;
  },
  context?: HttpContext

): Observable<UserServiceContractUser> {

    return this.apiUsersUserGetByIdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractUser>) => r.body as UserServiceContractUser)
    );
  }

  /**
   * ### Claim
   *  boservice.users.usergetbyid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersUserGetByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersUserGetByIdGet$Response(params?: {
    lang?: string;
    usrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractUser>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersUserGetByIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('usrId', params.usrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractUser>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.users.usergetbyid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersUserGetByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersUserGetByIdGet(params?: {
    lang?: string;
    usrId?: number;
  },
  context?: HttpContext

): Observable<UserServiceContractUser> {

    return this.apiUsersUserGetByIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractUser>) => r.body as UserServiceContractUser)
    );
  }

  /**
   * Path part for operation apiUsersListUserTypeGet
   */
  static readonly ApiUsersListUserTypeGetPath = '/api/Users/ListUserType';

  /**
   * Getting a list of user types.
   *
   * ### Claim
   *  boservice.users.listusertype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListUserTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUserTypeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units/organizations
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUserType>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListUserTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUserType>>;
      })
    );
  }

  /**
   * Getting a list of user types.
   *
   * ### Claim
   *  boservice.users.listusertype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListUserTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUserTypeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units/organizations
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUserType>> {

    return this.apiUsersListUserTypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUserType>>) => r.body as Array<UserServiceContractUserType>)
    );
  }

  /**
   * Getting a list of user types.
   *
   * ### Claim
   *  boservice.users.listusertype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListUserTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUserTypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units/organizations
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUserType>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersListUserTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUserType>>;
      })
    );
  }

  /**
   * Getting a list of user types.
   *
   * ### Claim
   *  boservice.users.listusertype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListUserTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListUserTypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units/organizations
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUserType>> {

    return this.apiUsersListUserTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUserType>>) => r.body as Array<UserServiceContractUserType>)
    );
  }

  /**
   * Path part for operation apiUsersGetNewPasswdPost
   */
  static readonly ApiUsersGetNewPasswdPostPath = '/api/Users/GetNewPasswd';

  /**
   * Generation of a new password (pin code).
   *
   * ### Claim
   *  boservice.users.getnewpasswd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersGetNewPasswdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersGetNewPasswdPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: UserServiceContractGetNewPasswdRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractGetNewPasswdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersGetNewPasswdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractGetNewPasswdResponse>;
      })
    );
  }

  /**
   * Generation of a new password (pin code).
   *
   * ### Claim
   *  boservice.users.getnewpasswd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersGetNewPasswdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersGetNewPasswdPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: UserServiceContractGetNewPasswdRequest
  },
  context?: HttpContext

): Observable<UserServiceContractGetNewPasswdResponse> {

    return this.apiUsersGetNewPasswdPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractGetNewPasswdResponse>) => r.body as UserServiceContractGetNewPasswdResponse)
    );
  }

  /**
   * Generation of a new password (pin code).
   *
   * ### Claim
   *  boservice.users.getnewpasswd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersGetNewPasswdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersGetNewPasswdPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: UserServiceContractGetNewPasswdRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractGetNewPasswdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiUsersGetNewPasswdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractGetNewPasswdResponse>;
      })
    );
  }

  /**
   * Generation of a new password (pin code).
   *
   * ### Claim
   *  boservice.users.getnewpasswd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersGetNewPasswdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersGetNewPasswdPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: UserServiceContractGetNewPasswdRequest
  },
  context?: HttpContext

): Observable<UserServiceContractGetNewPasswdResponse> {

    return this.apiUsersGetNewPasswdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractGetNewPasswdResponse>) => r.body as UserServiceContractGetNewPasswdResponse)
    );
  }

}
