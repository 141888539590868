import { StartWithUnit } from '@core/operators/start-with-unit';
import { RxState } from '@rx-angular/state';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';


export class CustomRxState<T extends object> extends RxState<T> {
    protected selectLazy<K extends keyof T>(selector: K, dataGetter: () => Observable<T[K]>): Observable<T[K]> {
        return of(null)
            .pipe(
                switchMap(() => dataGetter.apply(this)),
                tap((result: T[K]) => this.set(selector, (oldState: T) => oldState[selector] = result)),
                switchMap(() => this.select(selector)),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
    }

    protected patch<K extends keyof T>(property: K, value: Partial<T[K]>): void {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            this.set(property, (oldState: T) => ({
                ...oldState[property],
                ...value,
            }));
        } else {
            this.set(property, () => value as T[K]);
        }
    }

    public resetError<K extends keyof T>(errorCode: StartWithUnit, property: K): void {
        this.set(property, (oldState: T) => ({
            ...oldState[property],
            [errorCode]: null,
        }));
    }
}
