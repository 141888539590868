import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  RouteVersionEntriesModel,
  RouteVersionModel,
} from '@services/route-version/route-version.model';
import { SdIdType } from '@services/routes/routes.model';
import { map } from 'rxjs/operators';
import { get, set } from "lodash-es";

@Injectable({
  providedIn: 'root',
})
export class RouteVersionService {
  versions;

  private version$: BehaviorSubject<RouteVersionModel> =
    new BehaviorSubject<RouteVersionModel>(null);

  constructor() {
    if (!this.versions) {
      this.versions = {};
    }
  }

  getVersion(sdId: SdIdType): Observable<RouteVersionEntriesModel> {
    return this.version$.pipe(map((versions) => get(versions, sdId)));
  }

  setVersion(sdId: SdIdType, version: RouteVersionEntriesModel): void {
    if (version) {
      const current = get(this.versions, sdId);
      // @ts-ignore
      this.versions = set(this.versions, sdId, { ...current, ...version });
      this.version$.next(this.versions);
    }
  }
}
