import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Html wrapper for form field
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-form-group-wrapper',
  templateUrl: './form-group-wrapper.component.html',
  styleUrls: ['./form-group-wrapper.component.scss'],
})
export class FormGroupWrapperComponent {
  /**
   * Label text
   */
  @Input() label!: string;
  /**
   * Form-group margin class
   */
  @Input() marginClass = 'mb-5';
  /**
   * Additional label class
   */
  @Input() labelClass = 'mb-1';
  /**
   * Required flag
   */
  @Input() required!: boolean;
  /**
   * Link for inner id element
   */
  @Input() for: string = null;

  constructor() {}
}
