import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RoutesServiceModel, SdIdType } from '@services/routes/routes.model';
import { StopModel } from '@libs/stops/stop.model';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TariffZoneModel } from '@libs/tariff-zone/tariff-zone.model';
import {
  RouteStopBody,
  RouteStopModel,
} from '@services/route-stops/route-stops.model';
import { AbstractListModel } from '@libs/factory/abstract-list/abstract-list.model';
import { RouteVersionEntriesModel } from '@services/route-version/route-version.model';

/**
 * Form add stop to route
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-stop-by-route-form',
  templateUrl: './stop-by-route-form.component.html',
  styleUrls: ['./stop-by-route-form.component.scss'],
})
export class StopByRouteFormComponent {
  /**
   * Zones list
   */
  @Input() zones: TariffZoneModel[];
  /**
   * List stop types
   */
  @Input() stopTypes: AbstractListModel[];
  /**
   * Service date
   */
  @Input() service: RoutesServiceModel;
  /**
   * Stops list
   */
  @Input() stops: StopModel[];
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * Add stop to route
   *
   * @returns Form data
   */
  @Output() submitForm: EventEmitter<RouteStopBody> =
    new EventEmitter<RouteStopBody>();
  /**
   * Form object
   */
  readonly form: FormGroup = this.fb.group({
    sdId: this.fb.control(null, []),
    nextDate: this.fb.control(null, [Validators.required]),
    direction: this.fb.control(null, [Validators.required]),
    stId: this.fb.control(null, []),
    stpId: this.fb.control(null, []),
    typ: this.fb.control(null, [Validators.required]),
    zoneId: this.fb.control(null, []),
    fareStage: this.fb.control(null, []),
    distance: this.fb.control(null, []),
    varCode: this.fb.control(1, []),
    stNumber: this.fb.control(null, [Validators.required]),
  });
  /**
   * Head for edit mode
   */
  stopName: string;

  constructor(private fb: FormBuilder) {}

  get stpIdControl(): AbstractControl {
    return this.form.get('stpId');
  }
  /**
   * Stop
   */
  @Input() set stop(stop: RouteStopModel) {
    if (stop) {
      this.stopName = stop.stName;
      this.form.patchValue(stop);
    }
  }
  /**
   * Direction
   */
  @Input() set direction(direction: number) {
    if (direction) {
      this.form.patchValue({
        direction,
      });
    }
  }
  /**
   * Route Id
   */
  @Input() set sdId(sdId: SdIdType) {
    if (sdId) {
      this.form.patchValue({
        sdId,
      });
    }
  }
  /**
   * Last stNumber
   */
  @Input() set stNumber(stNumber: number) {
    if (stNumber) {
      this.form.patchValue({
        stNumber,
      });
    }
  }
  /**
   * Version value
   */
  @Input() set versions(versions: RouteVersionEntriesModel | null) {
    if (versions && versions.nextVersion) {
      this.form.patchValue({
        nextDate: versions.nextVersion,
      });
    }
  }

  handlerSubmit(): void {
    this.submitForm.emit({
      ...this.form.getRawValue(),
      ...{
        distance: this.form.get('distance').value
          ? +this.form.get('distance').value
          : null,
        fareStage: this.form.get('fareStage').value
          ? +this.form.get('fareStage').value
          : null,
      },
    });
  }
}
