import { EMPTY, ObservedValueOf, OperatorFunction } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { HttpErrorResponse } from '@angular/common/http';
import { StartWithUnit } from './start-with-unit';
import { catchError } from 'rxjs/operators';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiError = Record<string, any>;
export type ApiErrorExtra = { extra: { fields: { [key: string]: string[] } } };

export enum ApiErrorCode {
    AuthenticationFailed = 'authentication_failed',
    NotFound = 'not_found',
    Used = 'used',
    IsDraft = 'is_draft',
    PermissionDenied = 'permission_denied',
    NotVerified = 'not_verified',
    Invalid = 'invalid',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function startWithErrors<T, V extends { errors: Record<StartWithUnit, ApiError> }>(
    state: RxState<V>,
    unit: StartWithUnit,
    rethrow: boolean = false,
): OperatorFunction<T, T | ObservedValueOf<never>> {
    return catchError((errorResponse: HttpErrorResponse) => {
        const errors: ApiError = errorResponse instanceof HttpErrorResponse ? errorResponse.error : errorResponse;

        state.set({ errors: { [unit]: typeof errors === 'string' ? JSON.parse(errors) : errors } } as V);

        if (rethrow) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw errors;
        }

        return EMPTY;
    });
}
