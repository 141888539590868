import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FareMenuService } from '@libs/fare-menu/fare-menu.service';
import { Observable } from 'rxjs';
import { FareMenuBodyModel, FareMenuModel } from '@libs/fare-menu/fare-menu.model';
import { SessionStorage } from 'ngx-webstorage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';
import { StorageKeys } from '@core/storage/storage.keys';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-sort-product-list',
    templateUrl: './popup-sort-product-list.component.html',
    styleUrls: ['./popup-sort-product-list.component.scss'],
})
export class PopupSortProductListComponent implements OnInit {
    @SessionStorage(StorageKeys.RefSelectedTrm)
    trmId: number;

    readonly #push: PushService = inject(PushService);

    fareMenu$: Observable<FareMenuModel[]>;
    pending$: Observable<boolean> = this.fareMenuService.pending;
    type: 1 | 2 = 1;

    constructor(
        public dialogRef: MatDialogRef<PopupSortProductListComponent>,
        private fareMenuService: FareMenuService,
    ) {
    }

    public ngOnInit(): void {
        this.fareMenu$ = this.fareMenuService.getFareMenu(
            this.trmId,
            null,
            this.type,
        );
    }

    public handlerChangeType(type: { value: 1 | 2 }): void {
        this.type = type?.value;
        this.fareMenu$ = this.fareMenuService.getFareMenu(
            this.trmId,
            null,
            this.type,
        );
    }

    public handlerSortList(value: FareMenuBodyModel): void {
        this.fareMenuService
            .setFareMenu(value)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessChange);
            });
    }
}
