import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpBackend } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CookiePolicyTextModule } from '@ui/cookie-policy-text/cookie-policy-text.module';
import {
    BsDatePickerDeps,
    DomDeps,
    HttpTranslateLoaderFactory,
    startupAppConfigs,
    startupDeps,
} from '@core/app/app.module.helpers';
import { ThirdLevelMenuFactory } from '@libs/menu/third-level-menu.service';
import { thirdLevelMenu } from './sub.menu';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GOOGLE_MAPS_API_KEY } from '@libs/constants';
import { AgmCoreModule } from '@agm/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { StatisticsModule } from '../../../statistics/src/lib/statistics.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { SharedCommonModule } from '@libs/shared-common/shared-common.module';
import { PopupContainersModule } from './popup-containers/popup-containers.module';
import { DefaultPushConfig } from '@core/push/push.config';
import { PlacesState } from './shared/state/places.state';
import { ProvidersState } from './shared/state/providers.state';


export const loadSubMenu =
    (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () =>
        thirdLevelMenuFactory.loadMenu(thirdLevelMenu);

@NgModule({
    imports: [
        CommonAppModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        ToastrModule.forRoot(DefaultPushConfig),
        NgxWebstorageModule.forRoot(),
        CookiePolicyTextModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: GOOGLE_MAPS_API_KEY,
            libraries: ['drawing'],
        }),
        StatisticsModule,
        SharedCommonModule,
        PopupContainersModule,
    ],
    providers: [
        ...DomDeps,
        ...BsDatePickerDeps,
        ...[
            {
                provide: APP_INITIALIZER,
                useFactory: startupAppConfigs,
                deps: startupDeps,
                multi: true,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: loadSubMenu,
                deps: [ThirdLevelMenuFactory],
                multi: true,
            },
            PlacesState,
            ProvidersState,
        ],
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
