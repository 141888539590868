import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuApplicationComponent } from './menu-application.component';
import { CurrentProtocolPipe } from '@pipes/current-protocol/current-protocol.pipe';


@NgModule({
    declarations: [MenuApplicationComponent],
    imports: [CommonModule, CurrentProtocolPipe],
    exports: [MenuApplicationComponent],
})
export class MenuApplicationModule {
}
