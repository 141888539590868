// @ts-nocheck
import { Injectable } from '@angular/core';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import { catchError, filter, finalize, publishReplay, refCount, repeatWhen, tap } from 'rxjs/operators';
import { ApiConfigService } from '../api-config/api-config.service';
import { HttpClient } from '@angular/common/http';
import { OrganizationAddResponse, OrganizationId, OrganizationModel, OrganizationName } from './organization.model';
import { ErrorResponseModel } from '../error/error.model';
import { SessionStorageService } from 'ngx-webstorage';
import { notNullOrUndefined, nullOrUndefined } from '@libs/functions';
import { CommonTreeFactory } from '@libs/factory/tree/tree.factory';
import { StorageKeys } from '@core/storage/storage.keys';


/**
 * Service organizations
 */
@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    private readonly refreshOrganizationData$: Subject<any> = new Subject();
    private pending$: Subject<boolean> = new Subject();

    private readonly requestToGetOrganizations: Observable<OrganizationModel[]> =
        this.http
            .get<OrganizationModel[]>(
                this.apiConfigService.getMethodUrl('boservice.users.listvisorg'),
            )
            .pipe(
                tap((organizations) => {
                    if (organizations?.length) {
                        const orgTrm = organizations.find((org) =>
                            notNullOrUndefined(org.trmId),
                        );
                        const userTrmId = this.sessionStorage.retrieve(
                            StorageKeys.RefSelectedTrm,
                        );

                        if (nullOrUndefined(userTrmId) && orgTrm) {
                            this.sessionStorage.store(
                                StorageKeys.RefSelectedTrm,
                                orgTrm.trmId,
                            );
                        }
                    } else {
                        this.sessionStorage.clear(StorageKeys.RefSelectedTrm);
                    }
                }),
                repeatWhen(() => this.refreshOrganizationData$),
                filter((organization: OrganizationModel[]) => !!organization),
                publishReplay(1),
                refCount(),
            ) as ConnectableObservable<OrganizationModel[]>;

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
        private sessionStorage: SessionStorageService,
    ) {
        this.organizations.subscribe();
    }

    /**
     * Get a list of organizations
     *
     * @returnsObservable<OrganizationModel[]>
     */
    get organizations(): Observable<OrganizationModel[]> {
        return this.requestToGetOrganizations;
    }

    /**
     * Get a list of organizations forcibly from the server
     *
     * @returnsObservable<OrganizationModel[]>
     */
    get organizationsForce(): Observable<OrganizationModel[]> {
        this.refreshOrganizationData$.next(null);
        return this.requestToGetOrganizations;
    }

    get pending(): Observable<boolean> {
        return this.pending$.asObservable();
    }

    /**
     * Get a list of subsidiaries by Organization ID
     *
     * @param organizations - Array of organizations
     * @param orgId - Organization ID
     * @returns OrganizationModel[]
     */
    getDivisionsBranch(
        organizations: OrganizationModel[],
        orgId: OrganizationId,
    ): OrganizationModel[] {
        let currentOrg = organizations.find((org) => org.orgId === orgId);
        const tree = new CommonTreeFactory(organizations, 'orgId', 'pOrgId');
        if (currentOrg) {
            /** If org is not Dept */
            if (currentOrg.isDept === 0) {
                tree.makeTree(currentOrg);
            } else if (currentOrg.isDept === 1) {
                const pOrg = this.findParentOrg(organizations, currentOrg.orgId);
                tree.makeTree(pOrg);
            }
        }
        return tree.makeSortList();
    }

    /**
     * Deletes an organization
     *
     * @param orgId - Organization ID
     */
    deleteOrganizationById(orgId: OrganizationId): Observable<any> {
        this.pending$.next(true);
        return this.http
            .delete(
                this.apiConfigService.getMethodUrl(
                    'boservice.users.removeorganization',
                    {
                        orgId: orgId.toString(),
                    },
                ),
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((response: OrganizationAddResponse) => {
                    setTimeout(() => this.refreshOrganizationData$.next(null), 0);
                }),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Creates an organization
     *
     * @param orgName - The name of the organization to create
     * @param pOrgId - parent organization ID
     */
    addOrganization(
        orgName: OrganizationName,
        pOrgId: OrganizationId,
    ): Observable<any> {
        this.pending$.next(true);
        return this.http
            .post(
                this.apiConfigService.getMethodUrl('boservice.users.addorganization'),
                {
                    orgName,
                    pOrgId,
                },
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((response: OrganizationAddResponse) => {
                    setTimeout(() => this.refreshOrganizationData$.next(null), 0);
                }),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Edits the organization name
     *
     * @param orgName - New name
     * @param orgId - Organization ID
     * @param pOrgId - Parent organization ID
     */
    editOrganization(
        orgName: OrganizationName,
        orgId: OrganizationId,
        pOrgId: OrganizationId,
    ): Observable<any> {
        this.pending$.next(true);
        return this.http
            .post(
                this.apiConfigService.getMethodUrl('boservice.users.setorgdata', {
                    orgId: String(orgId),
                }),
                {
                    orgName,
                    pOrgId,
                },
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((response: any) => {
                    setTimeout(() => this.refreshOrganizationData$.next(null), 0);
                }),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    private findParentOrg(
        organizations: OrganizationModel[],
        orgId: number,
    ): OrganizationModel {
        const parent: OrganizationModel = organizations.find((item) => item.orgId === orgId);
        if (parent && parent.pOrgId !== null && parent.isDept !== 0) {
            return this.findParentOrg(organizations, parent.pOrgId);
        } else {
            return parent;
        }
    }

    private errorHandler(error: ErrorResponseModel): any {
        this.pending$.next(false);
        throw error;
    }
}
