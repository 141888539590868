import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAbstractNoteComponent } from './form-abstract-note.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTextareaModule } from '@ui/ui-textarea/ui-textarea.module';
import { ButtonModule } from '@ui/button/button.module';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormAbstractNoteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiTextareaModule,
    ButtonModule,
    ButtonRowModule,
    TranslateModule,
  ],
  exports: [FormAbstractNoteComponent],
})
export class FormAbstractNoteModule {}
