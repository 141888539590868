import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultilangInputComponent } from './multilang-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiInputModule } from '../ui-input/ui-input.module';

@NgModule({
  declarations: [MultilangInputComponent],
  exports: [MultilangInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    UiInputModule,
  ],
})
export class MultilangInputModule {}
