import { PrivilegesUsers } from '@core/privileges/privileges-users';

export type AdminUsrId = string;
export type AdminOrgId = number;
export type AdminOrgType = string;
export type AdminUsrName = string;
export type AdminSurName = string;
export type AdminOrgName = string;
export type AdminUsrLogin = string;

export class AdminUserModel {
    usrId: AdminUsrId;
    orgId: AdminOrgId;
    orgType: AdminOrgType;
    usrName: AdminUsrName;
    surname: AdminSurName;
    persN: string;
    orgName: AdminOrgName;
    privs: AdminPrivs[];
    rgnId: number;
    newPwdRequired: 0 | 1;
}

export interface AdminPrivs {
    objName: string;
    objType: number;
    privName: AdminPrivsValue;
}

export type AdminPrivsValue = PrivilegesUsers.Edit | PrivilegesUsers.Select | PrivilegesUsers.Disp;
