import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { FormGroupWrapperModule } from '@ui/form-group-wrapper/form-group-wrapper.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessagePipe } from '@pipes/validation-message/validation-message.pipe';


@Component({
    selector: 'ui-form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['./form-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        FormGroupWrapperModule,
        NgSelectModule,
        NgClass,
        TranslateModule,
        ValidationMessagePipe,
    ],
    host: {
        'class': 'ui-form-select',
    },
})
export class FormSelectComponent implements ControlValueAccessor, OnInit {
    @Input()
    public idAttrValue: string = 'ui-select-' + Math.random().toString().replace('.', '');

    @Input()
    public label: string = '';

    @Input()
    public placeholder: string = '';

    @Input()
    public multiple: boolean = false;

    @Input()
    public bindLabel: string = 'name';

    @Input()
    public bindValue: string = null;

    @Input()
    public clearable: boolean = true;

    @Input()
    public addTag: boolean = false;

    @Input()
    public searchable: boolean = false;

    @Input()
    public additionalGroupClass: string;

    @Input()
    public options: any[] = [];

    @Input()
    public translateOptions: boolean;

    @Input()
    public size?: 'sm' | 'lg' = null;

    public selectControl: FormControl;

    private readonly ngControl: NgControl = inject(NgControl, { self: true, optional: true });

    constructor() {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public get isRequiredField(): boolean {
        return this.selectControl.hasValidator(Validators.required);
    }

    public ngOnInit(): void {
        this.selectControl = this.ngControl.control as FormControl;
    }

    public registerOnChange(): void {
    }

    public registerOnTouched(): void {
    }

    public writeValue(): void {
    }
}
