import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { AbstractNoteFormBody } from '@containers/form-abstract-note/form-abstract-note.component';
import { takeUntil } from 'rxjs/operators';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'popup-confirm-with-note-dialog',
    templateUrl: './confirm-with-note-dialog.component.html',
    styleUrls: ['./confirm-with-note-dialog.component.scss'],
})
export class ConfirmWithNoteDialogComponent {
    readonly #push: PushService = inject(PushService);

    readonly pending$: Observable<boolean> | undefined =
        this.data.params?.pending$;

    readonly noteRequired: boolean | undefined = this.data.params?.noteRequired;
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        public dialogRef: MatDialogRef<ConfirmWithNoteDialogComponent>,
    ) {
    }

    public handlerClickConfirm(body: AbstractNoteFormBody): void {
        this.data.params
            .confirmFunction(body.note)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessDelete);
                this.handlerClickCancel();
            });
    }

    public handlerClickCancel(): void {
        this.dialogRef.close();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
