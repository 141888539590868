import { inject, Injectable } from '@angular/core';
import { LangService } from '@libs/lang/lang.service';
import { DateFormatModel } from '@services/date-format/date-format.model';
import { DEFAULT_LANG } from '@libs/constants';
import * as moment from 'moment';
import { AvailableLanguage } from '@core/localization/available-language';


const dateFormats: Record<AvailableLanguage, DateFormatModel> = {
    [AvailableLanguage.EnGb]: {
        bsDatePicker: 'DD-MM-YYYY',
        moment: 'DD-MM-YYYY HH:mm',
        angular: 'dd-MM-yyyy HH:mm',
        angularDate: 'dd-MM-yyyy',
    },
    [AvailableLanguage.RuRu]: {
        bsDatePicker: 'DD-MM-YYYY',
        moment: 'DD-MM-YYYY HH:mm',
        angular: 'dd-MM-yyyy HH:mm',
        angularDate: 'dd-MM-yyyy',
    },
    [AvailableLanguage.EnUs]: {
        bsDatePicker: 'MM-DD-YYYY',
        moment: 'MM-DD-YYYY hh:mm a',
        angular: 'MM-dd-yyyy hh:mm a',
        angularDate: 'MM-dd-yyyy',
    },
    [AvailableLanguage.EsEs]: {
        bsDatePicker: 'DD-MM-YYYY',
        moment: 'DD-MM-YYYY HH:mm',
        angular: 'dd-MM-yyyy HH:mm',
        angularDate: 'dd-MM-yyyy',
    },
};

@Injectable({
    providedIn: 'root',
})
export class DateFormatService {
    readonly #lanService: LangService = inject(LangService);

    readonly #formats: Record<AvailableLanguage, DateFormatModel> = dateFormats;

    public get format(): DateFormatModel {
        const langCode: AvailableLanguage =
            this.#lanService.currentLangCode.toLowerCase() as AvailableLanguage;
        return this.#formats.hasOwnProperty(langCode)
            ? this.#formats[langCode]
            : this.#formats[DEFAULT_LANG as AvailableLanguage];
    }

    public prepareDateTimeForDB(dateFrom: Date | string, timeFrom: Date | string): string {
        const m: moment.Moment = moment(dateFrom);
        return m.format('YYYY-MM-DD') + 'T' + moment(timeFrom).format('HH:mm:ss');
    }

    public prepareDateForDB(date: Date | string): string {
        const m: moment.Moment = moment(date);
        return m.format('YYYY-MM-DD');
    }
}
