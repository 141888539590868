export enum StorageKeys {
    RefFilterPlaces = 'bo.ref.filterPlaces',
    RefSelectedProvider = 'bo.ref.selectedProviderId',
    RefSelectedOrg = 'bo.ref.selectedOrgId',
    RefSelectedTrm = 'bo.ref.selectedTrmId',
    RefSelectedTariffService = 'bo.ref.selectedTariffServiceId',
    RefSelectedRouteService = 'bo.ref.selectedRouteServiceId',
    RefSelectedTariffDomain = 'bo.ref.selectedTariffDomainId',
    UsersSelectedOrg = 'bo.users.selectedOrgId',
    UsersOrgRolesSelectedOrg = 'bo.users.orgRolesSelectedOrgId',
    MonitoringTechFilterValue = 'bo.monTech.filterValue',
    MonitoringSchemeSrvId = 'bo.valman.mon.selectedSchemeSrvId',
    MonitoringFilter = 'bo.valman.mon.filter',
    MonitoringFilterError = 'bo.valman.mon.filterError',
    LoadLoadType = 'bo.load.selectedLoadType',
    CookieLang = 'bo.multipass.lang',
    CookiesAccept = 'bo.multipass.cookies_accept',
}
