<form class="row" [formGroup]="form">
    <div class="col-12">

        <h2 class="mb-5" *ngIf="stId !== null">
            {{ 'global.actions.edit' | translate }}
        </h2>

        <h2 class="mb-2" *ngIf="stId === null">
            {{ 'containers.stop-form.head' | translate }}
        </h2>

        <ui-multilang-input
            [label]="'global.forms.labels.name'"
            [languages]="languages"
            [formControlName]="stopFormField.Names"
            [patch]="names"
            [required]="true"
        ></ui-multilang-input>

        <ui-form-input
            [formControlName]="stopFormField.Code"
            [label]="'global.forms.labels.code' | translate">

        </ui-form-input>
    </div>

    <div class="col-6">
        <ui-form-input
            [additionalGroupClass]="'mb-0'"
            [formControlName]="stopFormField.Lat"
            [label]="'global.forms.labels.lat' | translate">

        </ui-form-input>
    </div>

    <div class="col-6">
        <ui-form-input
            [additionalGroupClass]="'mb-0'"
            [formControlName]="stopFormField.Lon"
            [label]="'global.forms.labels.lon' | translate">

        </ui-form-input>
    </div>

    <div class="col-12 mt-5">
        <h4>{{'global.forms.labels.extCodes' | translate}}</h4>
        <div class="border border-radius-4 position-relative px-5 py-6"
             [class.mt-2]="j > 0"
             *ngFor="let code of externalCodes.controls; let j = index"
             [formArrayName]="stopFormField.ExternalCodes">

            <ng-container [formGroupName]="j">
                <ui-icon
                    [fontSize]="'.8'"
                    class="d-block position-absolute stop-form-extClose"
                    [type]="'close'"
                    [iconClass]="'pseudo--black'"
                    (handlerClick)="removeExtCodeForm(j)"
                >

                </ui-icon>

                <ui-form-select-organization
                    [size]="'sm'"
                    [clearable]="false"
                    [bindLabel]="'orgName'"
                    [bindValue]="'orgId'"
                    [options]="organizations"
                    [formControlName]="stopFormField.OrgId"
                    [placeholder]="'global.forms.labels.organization' | translate">

                </ui-form-select-organization>

                <ui-form-input
                    [size]="'sm'"
                    [additionalGroupClass]="'mb-0'"
                    [formControlName]="stopFormField.ExtCode"
                    [placeholder]="'global.forms.labels.extCode' | translate">

                </ui-form-input>
            </ng-container>
        </div>

        <div class="mt-3">
            <span
                (click)="addExtCodeForm()"
                class="pseudo pseudo--black">{{'global.actions.add' | translate}}</span>
        </div>
    </div>

    <div class="col-12">
        <ui-button-row>
            <ui-button
                *ngIf="stId !== null"
                [disabled]="form?.status === 'INVALID' || pending || !form.dirty"
                [label]="'global.actions.save'"
                (handlerClick)="editStop()"
                size="sm"
            ></ui-button>

            <ui-button
                *ngIf="stId === null"
                [disabled]="form?.status === 'INVALID' || pending"
                [label]="'global.actions.add'"
                (handlerClick)="addStop()"
                size="sm"
            ></ui-button>

            <ui-button
                [disabled]="pending"
                [label]="'global.actions.cancel'"
                (handlerClick)="reset()"
                size="sm"
                type="outline"
            ></ui-button>
        </ui-button-row>
    </div>
</form>
