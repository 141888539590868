import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FareMenuBodyModel,
  FareMenuModel,
} from '@libs/fare-menu/fare-menu.model';
import { srvIdIdentity, trfIdIdentity } from '@libs/functions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

/**
 * Sortable menu tariffs
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-fare-menu-sort-list',
  templateUrl: './fare-menu-sort-list.component.html',
  styleUrls: ['./fare-menu-sort-list.component.scss'],
})
export class FareMenuSortListComponent implements OnChanges {
  /**
   * Tariffs list
   */
  @Input() tariffs: FareMenuModel[];
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * Sort event
   */
  @Output() sortItems: EventEmitter<FareMenuBodyModel> =
    new EventEmitter<FareMenuBodyModel>();
  /**
   * Identity function
   */
  identity = srvIdIdentity;
  /**
   * Identity function
   */
  identityTrf = trfIdIdentity;

  isChanged: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.isChanged = !changes?.tariffs?.currentValue;
  }

  onSort(event: CdkDragDrop<string[]>, index: number): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(
        this.tariffs[index].trfItems,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.isChanged = true;
  }

  handlerSort(): void {
    this.sortItems.emit({
      trfIds: this.tariffs.reduce(
        (trfIds, item) => [...trfIds, ...item?.trfItems.map((t) => t.trfId)],
        [],
      ),
    });
  }
}
