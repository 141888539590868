<form [formGroup]="form">
  <ui-textarea
    class="mb-0"
    [filler]="'global.forms.labels.note'"
    [label]="null"
    [formControlName]="'note'"
  ></ui-textarea>
  <ui-button-row>
    <ui-button
      [disabled]="form.status === 'INVALID' || pending"
      [label]="'global.labels.submit'"
      (handlerClick)="handlerSubmit()"
    >
    </ui-button>
    <ui-button
      [disabled]="pending"
      [label]="'global.labels.cancel'"
      (handlerClick)="cancelClick.emit()"
      type="outline"
    >
    </ui-button>
  </ui-button-row>
</form>
