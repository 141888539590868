import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from '@ui/navbar/navbar.component';
import { AuthGuard } from '@libs/auth/auth.guard';
import { submenu } from './sub.menu';
import { RouteEntity } from '@core/router/route.entity';
import { commonErrorRoutes, commonModuleRoutes } from '@core/router/routing.common';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { RoutingQueryParamsName } from '@core/router/routing.params';


const dataPrivilege: Record<'moduleMenu', MenuItemsModel[]> = {
    moduleMenu: submenu,
};

const routes: Routes = [
    ...commonModuleRoutes,
    {
        path: RouteEntity.Empty,
        component: NavbarComponent,
        outlet: 'navbar',
        data: {
            subMenuItems: submenu,
        },
    },
    {
        path: RouteEntity.Empty,
        redirectTo: RouteEntity.Places,
        pathMatch: 'full',
    },
    {
        path: RouteEntity.Places,
        loadChildren: () =>
            import('./features/places/places.module').then(
                (m) => m.PlacesModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Places + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/place/place.module').then(
                (m) => m.PlaceModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Places + '/:' + RoutingQueryParamsName.PlaceId,
        loadChildren: () =>
            import('./features/place/place.module').then(
                (m) => m.PlaceModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Validators,
        loadChildren: () =>
            import('./features/validators/validators.module').then(
                (m) => m.ValidatorsModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Tariffs,
        loadChildren: () =>
            import('./features/page-tariff-versions/page-tariffs.module').then(
                (m) => m.PageTariffsModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Tariffs + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/page-tariff-version/page-tariff-version.module').then(
                (m) => m.PageTariffVersionModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Tariffs + '/:' + RoutingQueryParamsName.VersionId,
        loadChildren: () =>
            import('./features/page-tariff/page-tariff.module').then(
                (m) => m.PageTariffModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Routes,
        loadChildren: () =>
            import('./features/page-routes/page-routes.module').then(
                (m) => m.PageRoutesModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Routes + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/page-route-add/page-route-add.module').then(
                (m) => m.PageRouteAddModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Routes + '/:' + +RoutingQueryParamsName.RouteId,
        loadChildren: () =>
            import('./features/page-route/page-route.module').then(
                (m) => m.PageRouteModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Providers,
        loadChildren: () =>
            import('./features/providers/providers.module').then(
                (m) => m.ProvidersModule,
            ),
        canLoad: [AuthGuard],
    },
    {
        path: RouteEntity.Providers + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/provider-add/page-provider-add.module').then(
                (m) => m.PageProviderAddModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Privcats,
        loadChildren: () =>
            import('./features/privilege-cats/privilege-cats.module').then(
                (m) => m.PrivilegeCatsModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Providers + '/:' + RoutingQueryParamsName.ProviderId,
        loadChildren: () =>
            import('./features/provider/provider.module').then(
                (m) => m.ProviderModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    ...commonErrorRoutes,
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
