import { MenuItemsModel } from './menu.model';
import { Injectable } from '@angular/core';

/**
 * Factory generation menu 3 level module
 */
@Injectable({
    providedIn: 'root',
})
export class ThirdLevelMenuFactory {
    private menu: { [key: string]: MenuItemsModel[] };
    /**
     * Download menu 3 levels
     *
     * @param menu - Object with menu items { [key: string]: MenuItemsModel[] }
     */
    public loadMenu(menu: { [key: string]: MenuItemsModel[] }): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.menu = menu;
            resolve(true);
        });
    }

    /**
     * Get a list of sub-menu items
     *
     * @param section - section code
     * @param replaceParamKey - url template code for replace
     * @param replaceParamValue - value for replace
     */
    menuItems(section: string, replaceParamKey?: string, replaceParamValue?: string ): MenuItemsModel[] {
        if (this.menu && this.menu.hasOwnProperty(section)) {
            if (replaceParamKey && replaceParamValue) {
                return this.menu[section].map((item: MenuItemsModel) => {
                    item.url = item.urlTemplate.replace(
                        '#' + replaceParamKey + '#',
                        encodeURIComponent(replaceParamValue),
                    );
                    return item;
                });
            } else {
                return this.menu[section];
            }
        } else {
            return [];
        }
    }
}
