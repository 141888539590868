<h2 class="mt-0 mb-4">
    {{ 'modal.popup-sort-product-list.head' | translate }}
</h2>

<mat-button-toggle-group class="mb-5"
                         [value]="type"
                         [attr.aria-label]="'aria-label.routeMapType' | translate"
                         (change)="handlerChangeType($event)"
                         name="permitType">

    <mat-button-toggle [value]="1" [checked]="true">{{ 'submenu.tariffs.pass' | translate }}</mat-button-toggle>
    <mat-button-toggle [value]="2">{{ 'submenu.tariffs.ticket' | translate }}</mat-button-toggle>

</mat-button-toggle-group>

<ui-fare-menu-sort-list [pending]="pending$ | async"
                        [tariffs]="fareMenu$ | async"
                        (sortItems)="handlerSortList($event)">

</ui-fare-menu-sort-list>
