import { AvailableLanguage } from '@core/localization/available-language';
import { RouteEntity } from '@core/router/route.entity';


export const PATTERN_EMAIL = `[A-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`;
export const PATTERN_WORDS = '[\\da-zA-Z\\-\\s"&._,+\']*';
export const PATTERN_EMAIL_PATH_SUPPORT = '[^\\s]{3,}$';
export const PATTERN_PHONE = '^[0-9]{1,12}$';
export const PATTERN_NUMERIC = '^-?[0-9]\\d*(\\.\\d*)?$';
export const PATTERN_POSITIVE_NUMERIC = '^[0-9]\\d*(\\.\\d*)?$';
export const PATTERN_INTEGER = '^-?[0-9]\\d*$';
export const PATTERN_PERCENTAGE =
    '^100(\\.0{0,2})? *%?$|^\\d{1,2}(\\.\\d{1,2})? *%?$';
export const PATTERN_PERCENTAGE_INTEGER =
    '^100(\\.0{0,2})? *%?$|^\\d{1,2}? *%?$';
export const PATTERN_POSITIVE_INTEGER_WITHOUT_ZERO = '^[1-9]+[0-9]*$';
export const PHONE_CLEAR_CODE_REGEX = /^0+/;

export const DEFAULT_LANG: AvailableLanguage = AvailableLanguage.EnGb;
export const LOCAL_LOGIN_URL: string = RouteEntity.Root + RouteEntity.Login;
export const APP_NAME: string = 'bo';

export const NUMBER_MAX_INT32: number = 2147483647;
export const CHAR_LENGTH: number = 100;

export const API_CONFIG_URL: string = '/settings/api.json';
export const DB_CONFIG_URL: string = '/api/App/ListSettings?lang=en-gb';
export const API_ENDPOINT_DEFAULT: string = 'main';
export const AUTH_TOKEN_DEV_NAME: string = 'accessToken';
export const AUTH_TOKEN_PROD_NAME: string = 'xZMcb2EUzZqXQfgxtGp@vdb!K2T';
export const DATE_CALENDAR_FORMAT: string = 'YYYY-MM-DD';
export const DATE_MOMENT_JS_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const TIME_FORMAT: Record<AvailableLanguage, string> = {
    [AvailableLanguage.RuRu]: 'HH:mm',
    [AvailableLanguage.EnGb]: 'HH:mm',
    [AvailableLanguage.EsEs]: 'HH:mm',
    [AvailableLanguage.EnUs]: 'hh:mm a',
};

export const GOOGLE_MAPS_API_KEY: string = 'AIzaSyBW7kV4kjSYtMEfo6TXBTuNywd9KuAg0kQ';
export const GOOGLE_MAPS_STOP_DEFAULT_ZOOM: number = 1;
export const GOOGLE_MAPS_STOP_DETAIL_ZOOM: number = 16;

export const DIALOG_PANEL_CLASS: string = 'dialog-panel--adaptive';

export const DEFAULT_FILE_EXPORT_NAME: string = 'export.xlsx';
export const DEFAULT_FILE_EXPORT_LOG_NAME: string = 'errorLog.xlsx';
export const DEFAULT_FILE_EXPORT_SHEET_NAME: string = 'sheet';

export const TABLE_PAGE_SIZE_OPTIONS_DEFAULT: number[] = [20, 50, 100];
