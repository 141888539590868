<form class="row form-group mb-0" [formGroup]="form">
  <div class="col-12">
    <div class="d-flex flex-row align-items-center">
      <label class="label" *ngIf="label"
        >{{ label | translate }}<sup *ngIf="required">*</sup></label
      >
      <div class="lang-checker ms-6">
        <span
          class="pseudo pseudo--border-off pseudo--black me-3"
          *ngFor="let lang of languagesValue"
          [class.pseudo--selected]="selectedLang === lang.code"
          (click)="selectedLang = lang.code"
          >{{ lang.code }}</span
        >
      </div>
    </div>
    <ui-input
      *ngFor="let lang of languagesValue"
      [ngClass]="customClass ? customClass : 'mb-4'"
      [required]="required"
      [disabledControl]="disabledControl"
      [class.d-none]="lang.code !== selectedLang"
      [class.d-block]="lang.code === selectedLang"
      [filler]="lang.code"
      [formControlName]="lang.code"
    >
    </ui-input>
  </div>
</form>
