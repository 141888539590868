import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeFormatService } from '@libs/time-format/time-format.service';
import { TimeFormatModel } from '@libs/time-format/time-format.model';
import { SdIdType } from '@services/routes/routes.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { RouteVersionService } from '@services/route-version/route-version.service';
import { DateFormatService } from '@services/date-format/date-format.service';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-set-route-version',
    templateUrl: './popup-set-route-version.component.html',
    styleUrls: ['./popup-set-route-version.component.scss'],
})
export class PopupSetRouteVersionComponent {
    readonly #push: PushService = inject(PushService);

    readonly sdId: SdIdType = this.data.params?.serviceParams?.sdId;
    readonly timeFormat: TimeFormatModel = this.timeFormatService.format;
    readonly minDate: Date = new Date();
    /**
     * Form data
     */
    readonly form: FormGroup = this.fb.group({
        dateFrom: this.fb.control(new Date(), [Validators.required]),
        timeFrom: this.fb.control(new Date(), [Validators.required]),
    });

    constructor(
        private fb: FormBuilder,
        private dateFormatService: DateFormatService,
        private timeFormatService: TimeFormatService,
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        public dialogRef: MatDialogRef<PopupSetRouteVersionComponent>,
        private routeVersionService: RouteVersionService,
    ) {
    }

    public handlerSetNextVersion(): void {
        const { dateFrom, timeFrom } = this.form.getRawValue();
        this.routeVersionService.setVersion(this.sdId, {
            nextVersion: this.dateFormatService.prepareDateTimeForDB(
                dateFrom,
                timeFrom,
            ),
        });
        this.#push.success(PushCodeModel.SuccessAdd);
        this.dialogRef.close();
    }
}
