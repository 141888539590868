import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TariffTicket } from '@libs/tariff/models';
import { TariffService } from '@libs/tariff/tariff.service';
import { CacheService } from '@libs/cache/cache.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ref-popup-add-group',
  templateUrl: './popup-detail.component.html',
  styleUrls: ['./popup-detail.component.scss'],
})
export class PopupDetailComponent implements OnInit {
  id: number = +this.data.params?.serviceParams?.tariffId;
  code: string = this.data.params?.serviceParams?.code;
  versionId: string = this.data.params?.serviceParams?.versionId;

  content: string;
  head: string;
  cacheKey: string = this.versionId + '#' + String(this.id);

  destroy$: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    public dialogRef: MatDialogRef<PopupDetailComponent>,
    public tariffService: TariffService,
    public cache: CacheService,
  ) {}

  ngOnInit() {
    const fromCache = this.cache.getItem('ticketDescription', this.cacheKey);
    switch (this.code) {
      case 'ticket':
        if (fromCache) {
          this.content = fromCache.content;
          this.head = fromCache.head;
        } else {
          this.tariffService
            .getTickets(this.versionId)
            .pipe(
              untilDestroyed(this),
              map((items: TariffTicket[]) =>
                items.find((item) => item.tariffId === this.id),
              ),
            )
            .subscribe((ticket: TariffTicket) => {
              this.head = ticket.name;
              this.content = ticket.tariffDescr;
              this.cache.setItem('ticketDescription', this.cacheKey, {
                head: this.head,
                content: this.content,
              });
            });
        }
        break;
    }
  }
}
