export enum ValidatorBoNetStatus {
    Offline,
    Warning,
    Online
}

export enum ValidatorBoErrorStatus {
    Ok = 1,
    Error = 2,
    Stock = 3
}

export enum ValidatorSchemePointColorId {
    Gray,
    Red,
    Green,
    Yellow
}

export enum ValidatorSchemePointColorValue {
    Gray = 'gray',
    Red = 'red',
    Green = 'green',
    Yellow = 'yellow'
}
