import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopByRouteFormComponent } from './stop-by-route-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiInputModule } from '@ui/ui-input/ui-input.module';
import { FormGroupWrapperModule } from '@ui/form-group-wrapper/form-group-wrapper.module';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { ButtonModule } from '@ui/button/button.module';

@NgModule({
  declarations: [StopByRouteFormComponent],
  exports: [StopByRouteFormComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UiInputModule,
    FormGroupWrapperModule,
    ButtonRowModule,
    ButtonModule,
  ],
})
export class StopByRouteFormModule {}
