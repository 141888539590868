/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddSdStopPointRequest as BoLankaContractAddSdStopPointRequest } from '../models/BoLanka/Contract/add-sd-stop-point-request';
import { AddSdStopPointResponse as BoLankaContractAddSdStopPointResponse } from '../models/BoLanka/Contract/add-sd-stop-point-response';
import { AddServiceDetailRequest as BoLankaContractAddServiceDetailRequest } from '../models/BoLanka/Contract/add-service-detail-request';
import { AddServiceDetailResponse as BoLankaContractAddServiceDetailResponse } from '../models/BoLanka/Contract/add-service-detail-response';
import { AddStopPointRequest as BoLankaContractAddStopPointRequest } from '../models/BoLanka/Contract/add-stop-point-request';
import { AddStopPointResponse as BoLankaContractAddStopPointResponse } from '../models/BoLanka/Contract/add-stop-point-response';
import { AddStpRouteRequest as BoLankaContractAddStpRouteRequest } from '../models/BoLanka/Contract/add-stp-route-request';
import { FareTable as BoLankaContractFareTable } from '../models/BoLanka/Contract/fare-table';
import { GetBarCodeResponse as BoLankaContractGetBarCodeResponse } from '../models/BoLanka/Contract/get-bar-code-response';
import { GetServiceDetailResponse as BoLankaContractGetServiceDetailResponse } from '../models/BoLanka/Contract/get-service-detail-response';
import { GetStopPointResponse as BoLankaContractGetStopPointResponse } from '../models/BoLanka/Contract/get-stop-point-response';
import { PriceType as BoLankaContractPriceType } from '../models/BoLanka/Contract/price-type';
import { Route as BoLankaContractRoute } from '../models/BoLanka/Contract/route';
import { RouteForRoute as BoLankaContractRouteForRoute } from '../models/BoLanka/Contract/route-for-route';
import { AddServiceDetailStpRequest as BoLankaContractRoutesAddServiceDetailStpRequest } from '../models/BoLanka/Contract/Routes/add-service-detail-stp-request';
import { AddServiceDetailStpResponse as BoLankaContractRoutesAddServiceDetailStpResponse } from '../models/BoLanka/Contract/Routes/add-service-detail-stp-response';
import { ListServiceDetailRouteResponse as BoLankaContractRoutesListServiceDetailRouteResponse } from '../models/BoLanka/Contract/Routes/list-service-detail-route-response';
import { ListServiceDetailStpResponse as BoLankaContractRoutesListServiceDetailStpResponse } from '../models/BoLanka/Contract/Routes/list-service-detail-stp-response';
import { SetServiceDetailRouteRequest as BoLankaContractRoutesSetServiceDetailRouteRequest } from '../models/BoLanka/Contract/Routes/set-service-detail-route-request';
import { SetServiceDetailStpRequest as BoLankaContractRoutesSetServiceDetailStpRequest } from '../models/BoLanka/Contract/Routes/set-service-detail-stp-request';
import { Service as BoLankaContractService } from '../models/BoLanka/Contract/service';
import { ServiceForRoute as BoLankaContractServiceForRoute } from '../models/BoLanka/Contract/service-for-route';
import { SetServiceDetailRequest as BoLankaContractSetServiceDetailRequest } from '../models/BoLanka/Contract/set-service-detail-request';
import { SetStopPointRequest as BoLankaContractSetStopPointRequest } from '../models/BoLanka/Contract/set-stop-point-request';
import { StPoint as BoLankaContractStPoint } from '../models/BoLanka/Contract/st-point';
import { TransportType as BoLankaContractTransportType } from '../models/BoLanka/Contract/transport-type';
import { TypeFull as BoLankaContractTypeFull } from '../models/BoLanka/Contract/type-full';
import { AddZoneRequest as BoLankaContractZoneAddZoneRequest } from '../models/BoLanka/Contract/Zone/add-zone-request';
import { AddZoneResponse as BoLankaContractZoneAddZoneResponse } from '../models/BoLanka/Contract/Zone/add-zone-response';
import { GetZoneResponse as BoLankaContractZoneGetZoneResponse } from '../models/BoLanka/Contract/Zone/get-zone-response';
import { SetZoneRequest as BoLankaContractZoneSetZoneRequest } from '../models/BoLanka/Contract/Zone/set-zone-request';

@Injectable({
  providedIn: 'root',
})
export class RoutesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRoutesTrmIdServicePsdIdDelete
   */
  static readonly ApiRoutesTrmIdServicePsdIdDeletePath = '/api/routes/{trmId}/service/{psdId}';

  /**
   * Delete a service from a provider.
   *
   * ### Claim
   *  boservice.routes.removeprvservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdServicePsdIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdServicePsdIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organizer ID
     */
    trmId: number;

    /**
     * Service Provider Setting ID
     */
    psdId: number;

    /**
     * deletion flag: 0/NULL – disable. Otherwise, delete
     */
    force?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesTrmIdServicePsdIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('trmId', params.trmId, {});
      rb.path('psdId', params.psdId, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a service from a provider.
   *
   * ### Claim
   *  boservice.routes.removeprvservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdServicePsdIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdServicePsdIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Organizer ID
     */
    trmId: number;

    /**
     * Service Provider Setting ID
     */
    psdId: number;

    /**
     * deletion flag: 0/NULL – disable. Otherwise, delete
     */
    force?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesTrmIdServicePsdIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesPrvIdServiceGet
   */
  static readonly ApiRoutesPrvIdServiceGetPath = '/api/routes/{prvId}/service';

  /**
   * Getting a list of Provider's services.
   *
   * ### Claim
   *  boservice.routes.listprvservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesPrvIdServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesPrvIdServiceGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesPrvIdServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractService>>;
      })
    );
  }

  /**
   * Getting a list of Provider's services.
   *
   * ### Claim
   *  boservice.routes.listprvservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesPrvIdServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesPrvIdServiceGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractService>> {

    return this.apiRoutesPrvIdServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractService>>) => r.body as Array<BoLankaContractService>)
    );
  }

  /**
   * Path part for operation apiRoutesPrvIdRouteGet
   */
  static readonly ApiRoutesPrvIdRouteGetPath = '/api/routes/{prvId}/route';

  /**
   * Route directory.
   *
   * ### Claim
   *  boservice.routes.listroutes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesPrvIdRouteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesPrvIdRouteGet$Response(params: {

    /**
     * Data language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesPrvIdRouteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractRoute>>;
      })
    );
  }

  /**
   * Route directory.
   *
   * ### Claim
   *  boservice.routes.listroutes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesPrvIdRouteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesPrvIdRouteGet(params: {

    /**
     * Data language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractRoute>> {

    return this.apiRoutesPrvIdRouteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractRoute>>) => r.body as Array<BoLankaContractRoute>)
    );
  }

  /**
   * Path part for operation apiRoutesListServicesForRouteGet
   */
  static readonly ApiRoutesListServicesForRouteGetPath = '/api/routes/ListServicesForRoute';

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.routes.listservicesforroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListServicesForRouteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServicesForRouteGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractServiceForRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListServicesForRouteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractServiceForRoute>>;
      })
    );
  }

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.routes.listservicesforroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListServicesForRouteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServicesForRouteGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractServiceForRoute>> {

    return this.apiRoutesListServicesForRouteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractServiceForRoute>>) => r.body as Array<BoLankaContractServiceForRoute>)
    );
  }

  /**
   * Path part for operation apiRoutesStopPointGet
   */
  static readonly ApiRoutesStopPointGetPath = '/api/routes/stopPoint';

  /**
   * List of stops (all available).
   *
   * ### Claim
   *  boservice.routes.liststpoints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesStopPointGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesStopPointGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractStPoint>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesStopPointGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractStPoint>>;
      })
    );
  }

  /**
   * List of stops (all available).
   *
   * ### Claim
   *  boservice.routes.liststpoints
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesStopPointGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesStopPointGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractStPoint>> {

    return this.apiRoutesStopPointGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractStPoint>>) => r.body as Array<BoLankaContractStPoint>)
    );
  }

  /**
   * Path part for operation apiRoutesStopPointPost
   */
  static readonly ApiRoutesStopPointPostPath = '/api/routes/stopPoint';

  /**
   * Create a stop (not tied to a route).
   *
   * ### Claim
   *  boservice.routes.addstoppoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesStopPointPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesStopPointPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddStopPointRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractAddStopPointResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesStopPointPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractAddStopPointResponse>;
      })
    );
  }

  /**
   * Create a stop (not tied to a route).
   *
   * ### Claim
   *  boservice.routes.addstoppoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesStopPointPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesStopPointPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddStopPointRequest
  },
  context?: HttpContext

): Observable<BoLankaContractAddStopPointResponse> {

    return this.apiRoutesStopPointPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractAddStopPointResponse>) => r.body as BoLankaContractAddStopPointResponse)
    );
  }

  /**
   * Path part for operation apiRoutesRoutePost
   */
  static readonly ApiRoutesRoutePostPath = '/api/routes/route';

  /**
   * Add route.
   *
   * ### Claim
   *  boservice.routes.addservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddServiceDetailRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractAddServiceDetailResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractAddServiceDetailResponse>;
      })
    );
  }

  /**
   * Add route.
   *
   * ### Claim
   *  boservice.routes.addservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddServiceDetailRequest
  },
  context?: HttpContext

): Observable<BoLankaContractAddServiceDetailResponse> {

    return this.apiRoutesRoutePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractAddServiceDetailResponse>) => r.body as BoLankaContractAddServiceDetailResponse)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesSdIdStopPointPost
   */
  static readonly ApiRoutesRoutesSdIdStopPointPostPath = '/api/routes/routes/{sdId}/stopPoint';

  /**
   * Add a stop to a route (no order specified).
   *
   * ### Claim
   *  boservice.routes.addsdstoppoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesSdIdStopPointPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdStopPointPost$Response(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddSdStopPointRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractAddSdStopPointResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesSdIdStopPointPostPath, 'post');
    if (params) {
      rb.path('sdId', params.sdId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractAddSdStopPointResponse>;
      })
    );
  }

  /**
   * Add a stop to a route (no order specified).
   *
   * ### Claim
   *  boservice.routes.addsdstoppoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesSdIdStopPointPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdStopPointPost(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddSdStopPointRequest
  },
  context?: HttpContext

): Observable<BoLankaContractAddSdStopPointResponse> {

    return this.apiRoutesRoutesSdIdStopPointPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractAddSdStopPointResponse>) => r.body as BoLankaContractAddSdStopPointResponse)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesSdIdStopPointStpIdPost
   */
  static readonly ApiRoutesRoutesSdIdStopPointStpIdPostPath = '/api/routes/routes/{sdId}/stopPoint/{stpId}';

  /**
   * Embed a route stop in a route.
   *
   * ### Claim
   *  boservice.routes.addstproute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesSdIdStopPointStpIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdStopPointStpIdPost$Response(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Route Stop ID
     */
    stpId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddStpRouteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesSdIdStopPointStpIdPostPath, 'post');
    if (params) {
      rb.path('sdId', params.sdId, {});
      rb.path('stpId', params.stpId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Embed a route stop in a route.
   *
   * ### Claim
   *  boservice.routes.addstproute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesSdIdStopPointStpIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdStopPointStpIdPost(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Route Stop ID
     */
    stpId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddStpRouteRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesRoutesSdIdStopPointStpIdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesListSdTypeGet
   */
  static readonly ApiRoutesListSdTypeGetPath = '/api/routes/ListSdType';

  /**
   * List of modes of transport/route types.
   *
   * ### Claim
   *  boservice.routes.listsdtype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListSdTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListSdTypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractTransportType>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListSdTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractTransportType>>;
      })
    );
  }

  /**
   * List of modes of transport/route types.
   *
   * ### Claim
   *  boservice.routes.listsdtype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListSdTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListSdTypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractTransportType>> {

    return this.apiRoutesListSdTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractTransportType>>) => r.body as Array<BoLankaContractTransportType>)
    );
  }

  /**
   * Path part for operation apiRoutesStopPointStIdPut
   */
  static readonly ApiRoutesStopPointStIdPutPath = '/api/routes/stopPoint/{stId}';

  /**
   * Changing stop data (from the general list).
   *
   * ### Claim
   *  boservice.routes.setstoppoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesStopPointStIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesStopPointStIdPut$Response(params: {

    /**
     * Stop ID
     */
    stId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractSetStopPointRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesStopPointStIdPutPath, 'put');
    if (params) {
      rb.path('stId', params.stId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing stop data (from the general list).
   *
   * ### Claim
   *  boservice.routes.setstoppoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesStopPointStIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesStopPointStIdPut(params: {

    /**
     * Stop ID
     */
    stId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractSetStopPointRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesStopPointStIdPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesStopPointStIdDelete
   */
  static readonly ApiRoutesStopPointStIdDeletePath = '/api/routes/stopPoint/{stId}';

  /**
   * Removing a stop as a named geographic point.
   *
   * To remove a stop from a route, the stop must not be present in the route tree
   * ### Claim
   *  boservice.routes.removestoppoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesStopPointStIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesStopPointStIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Stop ID to remove
     */
    stId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesStopPointStIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a stop as a named geographic point.
   *
   * To remove a stop from a route, the stop must not be present in the route tree
   * ### Claim
   *  boservice.routes.removestoppoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesStopPointStIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesStopPointStIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Stop ID to remove
     */
    stId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesStopPointStIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesGet
   */
  static readonly ApiRoutesRoutesGetPath = '/api/routes/routes';

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.routes.listservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractRouteForRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractRouteForRoute>>;
      })
    );
  }

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.routes.listservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractRouteForRoute>> {

    return this.apiRoutesRoutesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractRouteForRoute>>) => r.body as Array<BoLankaContractRouteForRoute>)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesSdIdPut
   */
  static readonly ApiRoutesRoutesSdIdPutPath = '/api/routes/routes/{sdId}';

  /**
   * Editing a Route Name.
   *
   * ### Claim
   *  boservice.routes.setservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesSdIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdPut$Response(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Interface language. If not defined, then the system default language
     */
    lang?: string;
    body?: BoLankaContractSetServiceDetailRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesSdIdPutPath, 'put');
    if (params) {
      rb.path('sdId', params.sdId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing a Route Name.
   *
   * ### Claim
   *  boservice.routes.setservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesSdIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesRoutesSdIdPut(params: {

    /**
     * route ID
     */
    sdId: number;

    /**
     * Interface language. If not defined, then the system default language
     */
    lang?: string;
    body?: BoLankaContractSetServiceDetailRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesRoutesSdIdPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesSdIdDelete
   */
  static readonly ApiRoutesRoutesSdIdDeletePath = '/api/routes/routes/{sdId}';

  /**
   * Deleting a route.
   *
   * ### Claim
   *  boservice.routes.removeservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesSdIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesSdIdDelete$Response(params: {

    /**
     * interface language (NULL - default language)
     */
    lang?: string;

    /**
     * route ID
     */
    sdId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesSdIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('sdId', params.sdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a route.
   *
   * ### Claim
   *  boservice.routes.removeservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesSdIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesSdIdDelete(params: {

    /**
     * interface language (NULL - default language)
     */
    lang?: string;

    /**
     * route ID
     */
    sdId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesRoutesSdIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesRoutesSdIdFareStageGet
   */
  static readonly ApiRoutesRoutesSdIdFareStageGetPath = '/api/routes/routes/{sdId}/fareStage';

  /**
   * Getting the list of fare table on the route.
   *
   * ### Claim
   *  boservice.routes.listfarestage
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRoutesSdIdFareStageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesSdIdFareStageGet$Response(params: {

    /**
     * Interface language. If not defined, then the system default language
     */
    lang?: string;

    /**
     * route ID
     */
    sdId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractFareTable>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRoutesSdIdFareStageGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('sdId', params.sdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractFareTable>>;
      })
    );
  }

  /**
   * Getting the list of fare table on the route.
   *
   * ### Claim
   *  boservice.routes.listfarestage
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRoutesSdIdFareStageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRoutesSdIdFareStageGet(params: {

    /**
     * Interface language. If not defined, then the system default language
     */
    lang?: string;

    /**
     * route ID
     */
    sdId: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractFareTable>> {

    return this.apiRoutesRoutesSdIdFareStageGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractFareTable>>) => r.body as Array<BoLankaContractFareTable>)
    );
  }

  /**
   * Path part for operation apiRoutesGetBarCodeGet
   */
  static readonly ApiRoutesGetBarCodeGetPath = '/api/routes/GetBarCode';

  /**
   * Getting qr code.
   *
   * ### Claim
   *  boservice.routes.getbarcode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesGetBarCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetBarCodeGet$Response(params?: {

    /**
     * Installation location
     */
    plId?: number;

    /**
     * provider ID
     */
    providerId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractGetBarCodeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesGetBarCodeGetPath, 'get');
    if (params) {
      rb.query('plId', params.plId, {});
      rb.query('providerId', params.providerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractGetBarCodeResponse>;
      })
    );
  }

  /**
   * Getting qr code.
   *
   * ### Claim
   *  boservice.routes.getbarcode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesGetBarCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetBarCodeGet(params?: {

    /**
     * Installation location
     */
    plId?: number;

    /**
     * provider ID
     */
    providerId?: number;
  },
  context?: HttpContext

): Observable<BoLankaContractGetBarCodeResponse> {

    return this.apiRoutesGetBarCodeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractGetBarCodeResponse>) => r.body as BoLankaContractGetBarCodeResponse)
    );
  }

  /**
   * Path part for operation apiRoutesGetBarCodePost
   */
  static readonly ApiRoutesGetBarCodePostPath = '/api/routes/GetBarCode';

  /**
   * Getting qr code.
   *
   * ### Claim
   *  boservice.routes.getbarcode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesGetBarCodePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesGetBarCodePost$Response(params?: {

    /**
     * provider ID
     */
    providerId?: number;

    /**
     * List of installation locations
     */
    body?: Array<number>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractGetBarCodeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesGetBarCodePostPath, 'post');
    if (params) {
      rb.query('providerId', params.providerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractGetBarCodeResponse>>;
      })
    );
  }

  /**
   * Getting qr code.
   *
   * ### Claim
   *  boservice.routes.getbarcode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesGetBarCodePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesGetBarCodePost(params?: {

    /**
     * provider ID
     */
    providerId?: number;

    /**
     * List of installation locations
     */
    body?: Array<number>
  },
  context?: HttpContext

): Observable<Array<BoLankaContractGetBarCodeResponse>> {

    return this.apiRoutesGetBarCodePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractGetBarCodeResponse>>) => r.body as Array<BoLankaContractGetBarCodeResponse>)
    );
  }

  /**
   * Path part for operation apiRoutesListPriceTypeGet
   */
  static readonly ApiRoutesListPriceTypeGetPath = '/api/routes/ListPriceType';

  /**
   * getting a list of pricing types.
   *
   * ### Claim
   *  boservice.routes.listpricetype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListPriceTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListPriceTypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractPriceType>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListPriceTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractPriceType>>;
      })
    );
  }

  /**
   * getting a list of pricing types.
   *
   * ### Claim
   *  boservice.routes.listpricetype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListPriceTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListPriceTypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractPriceType>> {

    return this.apiRoutesListPriceTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractPriceType>>) => r.body as Array<BoLankaContractPriceType>)
    );
  }

  /**
   * Path part for operation apiRoutesListPriceTypeFullGet
   */
  static readonly ApiRoutesListPriceTypeFullGetPath = '/api/routes/ListPriceTypeFull';

  /**
   * Full list of pricing types.
   *
   * ### Claim
   *  boservice.routes.listpricetypefull
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListPriceTypeFullGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListPriceTypeFullGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractTypeFull>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListPriceTypeFullGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractTypeFull>>;
      })
    );
  }

  /**
   * Full list of pricing types.
   *
   * ### Claim
   *  boservice.routes.listpricetypefull
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListPriceTypeFullGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListPriceTypeFullGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractTypeFull>> {

    return this.apiRoutesListPriceTypeFullGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractTypeFull>>) => r.body as Array<BoLankaContractTypeFull>)
    );
  }

  /**
   * Path part for operation apiRoutesGetStopPointGet
   */
  static readonly ApiRoutesGetStopPointGetPath = '/api/routes/GetStopPoint';

  /**
   * Get Stop Details.
   *
   * ### Claim
   *  boservice.routes.getstoppoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesGetStopPointGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetStopPointGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * stop id
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractGetStopPointResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesGetStopPointGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractGetStopPointResponse>;
      })
    );
  }

  /**
   * Get Stop Details.
   *
   * ### Claim
   *  boservice.routes.getstoppoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesGetStopPointGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetStopPointGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * stop id
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<BoLankaContractGetStopPointResponse> {

    return this.apiRoutesGetStopPointGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractGetStopPointResponse>) => r.body as BoLankaContractGetStopPointResponse)
    );
  }

  /**
   * Path part for operation apiRoutesGetServiceDetailGet
   */
  static readonly ApiRoutesGetServiceDetailGetPath = '/api/routes/GetServiceDetail';

  /**
   * Getting detailed route data.
   *
   * ### Claim
   *  boservice.routes.getservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesGetServiceDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetServiceDetailGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractGetServiceDetailResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesGetServiceDetailGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('sdId', params.sdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractGetServiceDetailResponse>;
      })
    );
  }

  /**
   * Getting detailed route data.
   *
   * ### Claim
   *  boservice.routes.getservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesGetServiceDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetServiceDetailGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<BoLankaContractGetServiceDetailResponse> {

    return this.apiRoutesGetServiceDetailGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractGetServiceDetailResponse>) => r.body as BoLankaContractGetServiceDetailResponse)
    );
  }

  /**
   * Path part for operation apiRoutesAddServiceDetailStpPost
   */
  static readonly ApiRoutesAddServiceDetailStpPostPath = '/api/routes/AddServiceDetailStp';

  /**
   * Adding a stop to a route.
   *
   * The addition always happens in the next version
   * ### Claim
   *  boservice.routes.addservicedetailstp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesAddServiceDetailStpPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesAddServiceDetailStpPost$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractRoutesAddServiceDetailStpRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractRoutesAddServiceDetailStpResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesAddServiceDetailStpPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractRoutesAddServiceDetailStpResponse>;
      })
    );
  }

  /**
   * Adding a stop to a route.
   *
   * The addition always happens in the next version
   * ### Claim
   *  boservice.routes.addservicedetailstp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesAddServiceDetailStpPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesAddServiceDetailStpPost(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractRoutesAddServiceDetailStpRequest
  },
  context?: HttpContext

): Observable<BoLankaContractRoutesAddServiceDetailStpResponse> {

    return this.apiRoutesAddServiceDetailStpPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractRoutesAddServiceDetailStpResponse>) => r.body as BoLankaContractRoutesAddServiceDetailStpResponse)
    );
  }

  /**
   * Path part for operation apiRoutesListServiceDetailRouteGet
   */
  static readonly ApiRoutesListServiceDetailRouteGetPath = '/api/routes/ListServiceDetailRoute';

  /**
   * Retrieving Route Line Data.
   *
   * ### Claim
   *  boservice.routes.listservicedetailroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListServiceDetailRouteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServiceDetailRouteGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;

    /**
     * data version: 0 - current 1 - next null - all
     */
    version?: number;

    /**
     * movement direction: 1 – forward 2 – reverse null - all
     */
    direction?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailRouteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListServiceDetailRouteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('sdId', params.sdId, {});
      rb.query('version', params.version, {});
      rb.query('direction', params.direction, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailRouteResponse>>;
      })
    );
  }

  /**
   * Retrieving Route Line Data.
   *
   * ### Claim
   *  boservice.routes.listservicedetailroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListServiceDetailRouteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServiceDetailRouteGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;

    /**
     * data version: 0 - current 1 - next null - all
     */
    version?: number;

    /**
     * movement direction: 1 – forward 2 – reverse null - all
     */
    direction?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractRoutesListServiceDetailRouteResponse>> {

    return this.apiRoutesListServiceDetailRouteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailRouteResponse>>) => r.body as Array<BoLankaContractRoutesListServiceDetailRouteResponse>)
    );
  }

  /**
   * Path part for operation apiRoutesRemoveServiceDetailStpDelete
   */
  static readonly ApiRoutesRemoveServiceDetailStpDeletePath = '/api/routes/RemoveServiceDetailStp';

  /**
   * Deleting a stop on a route.
   *
   * Deletion always happens in the next version
   * ### Claim
   *  boservice.routes.removeservicedetailstp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesRemoveServiceDetailStpDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRemoveServiceDetailStpDelete$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * start date of the next version (existing or new)
     */
    nextDate?: string;

    /**
     * * id of the stop on the route
     */
    stpId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesRemoveServiceDetailStpDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('nextDate', params.nextDate, {});
      rb.query('stpId', params.stpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a stop on a route.
   *
   * Deletion always happens in the next version
   * ### Claim
   *  boservice.routes.removeservicedetailstp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesRemoveServiceDetailStpDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesRemoveServiceDetailStpDelete(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * start date of the next version (existing or new)
     */
    nextDate?: string;

    /**
     * * id of the stop on the route
     */
    stpId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesRemoveServiceDetailStpDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesSetServiceDetailRoutePost
   */
  static readonly ApiRoutesSetServiceDetailRoutePostPath = '/api/routes/SetServiceDetailRoute';

  /**
   * Adding/Changing a Route Line.
   *
   * Produced only in the next version of the data (existing or new)
   * ### Claim
   *  boservice.routes.setservicedetailroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesSetServiceDetailRoutePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetServiceDetailRoutePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractRoutesSetServiceDetailRouteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesSetServiceDetailRoutePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding/Changing a Route Line.
   *
   * Produced only in the next version of the data (existing or new)
   * ### Claim
   *  boservice.routes.setservicedetailroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesSetServiceDetailRoutePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetServiceDetailRoutePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractRoutesSetServiceDetailRouteRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesSetServiceDetailRoutePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesListServiceDetailStpGet
   */
  static readonly ApiRoutesListServiceDetailStpGetPath = '/api/routes/ListServiceDetailStp';

  /**
   * Getting a list of stops on a route.
   *
   * ### Claim
   *  boservice.routes.listservicedetailstp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesListServiceDetailStpGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServiceDetailStpGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;

    /**
     * data version: 0 - current 1 - next null - all
     */
    version?: number;

    /**
     * direction of movement: 1 - straight; 2 - return null - all
     */
    direction?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailStpResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesListServiceDetailStpGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('sdId', params.sdId, {});
      rb.query('version', params.version, {});
      rb.query('direction', params.direction, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailStpResponse>>;
      })
    );
  }

  /**
   * Getting a list of stops on a route.
   *
   * ### Claim
   *  boservice.routes.listservicedetailstp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesListServiceDetailStpGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesListServiceDetailStpGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;

    /**
     * data version: 0 - current 1 - next null - all
     */
    version?: number;

    /**
     * direction of movement: 1 - straight; 2 - return null - all
     */
    direction?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractRoutesListServiceDetailStpResponse>> {

    return this.apiRoutesListServiceDetailStpGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractRoutesListServiceDetailStpResponse>>) => r.body as Array<BoLankaContractRoutesListServiceDetailStpResponse>)
    );
  }

  /**
   * Path part for operation apiRoutesSetServiceDetailStpPost
   */
  static readonly ApiRoutesSetServiceDetailStpPostPath = '/api/routes/SetServiceDetailStp';

  /**
   * Editing a stop on a route.
   *
   * Editing always happens in the next version
   * ### Claim
   *  boservice.routes.setservicedetailstp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesSetServiceDetailStpPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetServiceDetailStpPost$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractRoutesSetServiceDetailStpRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesSetServiceDetailStpPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing a stop on a route.
   *
   * Editing always happens in the next version
   * ### Claim
   *  boservice.routes.setservicedetailstp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesSetServiceDetailStpPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetServiceDetailStpPost(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractRoutesSetServiceDetailStpRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesSetServiceDetailStpPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesGetZoneGet
   */
  static readonly ApiRoutesGetZoneGetPath = '/api/routes/GetZone';

  /**
   * Getting Zone Data.
   *
   * ### Claim
   *  boservice.routes.getzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesGetZoneGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetZoneGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id zone
     */
    zoneId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractZoneGetZoneResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesGetZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('zoneId', params.zoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractZoneGetZoneResponse>;
      })
    );
  }

  /**
   * Getting Zone Data.
   *
   * ### Claim
   *  boservice.routes.getzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesGetZoneGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesGetZoneGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id zone
     */
    zoneId?: number;
  },
  context?: HttpContext

): Observable<BoLankaContractZoneGetZoneResponse> {

    return this.apiRoutesGetZoneGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractZoneGetZoneResponse>) => r.body as BoLankaContractZoneGetZoneResponse)
    );
  }

  /**
   * Path part for operation apiRoutesAddZonePost
   */
  static readonly ApiRoutesAddZonePostPath = '/api/routes/AddZone';

  /**
   * Adding a zone.
   *
   * ### Claim
   *  boservice.routes.addzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesAddZonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesAddZonePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractZoneAddZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractZoneAddZoneResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesAddZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractZoneAddZoneResponse>;
      })
    );
  }

  /**
   * Adding a zone.
   *
   * ### Claim
   *  boservice.routes.addzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesAddZonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesAddZonePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractZoneAddZoneRequest
  },
  context?: HttpContext

): Observable<BoLankaContractZoneAddZoneResponse> {

    return this.apiRoutesAddZonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractZoneAddZoneResponse>) => r.body as BoLankaContractZoneAddZoneResponse)
    );
  }

  /**
   * Path part for operation apiRoutesSetZonePost
   */
  static readonly ApiRoutesSetZonePostPath = '/api/routes/SetZone';

  /**
   * Zone editing.
   *
   * ### Claim
   *  boservice.routes.setzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesSetZonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetZonePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractZoneSetZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesSetZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Zone editing.
   *
   * ### Claim
   *  boservice.routes.setzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesSetZonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesSetZonePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BoLankaContractZoneSetZoneRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesSetZonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesDelZoneDelete
   */
  static readonly ApiRoutesDelZoneDeletePath = '/api/routes/DelZone';

  /**
   * Deleting a zone.
   *
   * ### Claim
   *  boservice.routes.delzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesDelZoneDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesDelZoneDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id zone
     */
    zoneId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoutesService.ApiRoutesDelZoneDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('zoneId', params.zoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a zone.
   *
   * ### Claim
   *  boservice.routes.delzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesDelZoneDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesDelZoneDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id zone
     */
    zoneId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesDelZoneDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
