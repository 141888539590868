<div class="form-group" [ngClass]="additionalGroupClass" [class.form-group-disabled]="disabled">
    <label class="d-block"
           *ngIf="label"
           [class.mb-1]="!hint"
           [class.mb-0]="hint"
           [attr.for]="id">{{ label }}<sup *ngIf="isRequiredField">*</sup>

    </label>

    <div *ngIf="hint" class="hint mb-1" [innerHTML]="hint"></div>

    <div class="position-relative">
        <input [ngClass]="size ? ('form-control-' + size) : null"
               #input
               class="form-control"
               [attr.id]="id"
               [disabled]="disabled ? disabled : null"
               [attr.placeholder]="placeholder"
               [attr.type]="type"
               [value]="value"
               [class.is-invalid]="inputControl?.touched && !inputControl?.pristine && inputControl?.invalid && hasBlur"
               [class.is-valid]="hasBlur && inputControl?.valid"
               (keydown.enter)="handlePressEnter($event)"
               (blur)="hasBlur = true"
               (change)="handlerChange($event)"
               (keyup)=handlerKeyUp($event) />

        <button class="form-control-suffix" *ngIf="suffixElement"
                (click)="handleSuffixClick($event)">
            <ng-content select="[suffix]"></ng-content>
        </button>

        <div class="invalid-feedback"
             *ngIf="inputControl?.errors && inputControl?.touched && hasBlur && showErrorMessage">
            <span class="invalid-feedback-box">{{ inputControl.errors | validationMessage }}</span>
        </div>
    </div>
</div>
