import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Injector,
    Type,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_DATA, DialogParams } from './dialog.tokens';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'popup-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    public close: EventEmitter<boolean> = new EventEmitter();

    public injectorData: Injector;

    public component: Type<any>;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            component: Type<any>;
            params: DialogParams;
        },

        private injector: Injector,
    ) {
        this.component = data.component;
        this.injectorData = Injector.create({
            providers: [
                {
                    provide: DIALOG_DATA,
                    useValue: {
                        ...data?.params,
                        ...{ dialogRef: this.dialogRef },
                    },
                },
            ],
            parent: injector,
        });
    }

    handlerClose(): void {
        this.dialogRef.close();
    }
}
