/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListPrvGroupTypesResponse as ProviderEditorApiContractListPrvGroupTypesResponse } from '../models/ProviderEditorApi/Contract/list-prv-group-types-response';
import { ListPrvGroupsResponse as ProviderEditorApiContractListPrvGroupsResponse } from '../models/ProviderEditorApi/Contract/list-prv-groups-response';
import { ListPrvServicesResponse as ProviderEditorApiContractListPrvServicesResponse } from '../models/ProviderEditorApi/Contract/list-prv-services-response';
import { PrvAddRequest as ProviderEditorApiContractPrvAddRequest } from '../models/ProviderEditorApi/Contract/prv-add-request';
import { PrvAddResponse as ProviderEditorApiContractPrvAddResponse } from '../models/ProviderEditorApi/Contract/prv-add-response';
import { PrvCaListResponse as ProviderEditorApiContractPrvCaListResponse } from '../models/ProviderEditorApi/Contract/prv-ca-list-response';
import { PrvDeptAddRequest as ProviderEditorApiContractPrvDeptAddRequest } from '../models/ProviderEditorApi/Contract/prv-dept-add-request';
import { PrvDeptAddResponse as ProviderEditorApiContractPrvDeptAddResponse } from '../models/ProviderEditorApi/Contract/prv-dept-add-response';
import { PrvDeptListResponse as ProviderEditorApiContractPrvDeptListResponse } from '../models/ProviderEditorApi/Contract/prv-dept-list-response';
import { PrvDeptSetRequest as ProviderEditorApiContractPrvDeptSetRequest } from '../models/ProviderEditorApi/Contract/prv-dept-set-request';
import { PrvInsurListResponse as ProviderEditorApiContractPrvInsurListResponse } from '../models/ProviderEditorApi/Contract/prv-insur-list-response';
import { PrvInsurSetRequest as ProviderEditorApiContractPrvInsurSetRequest } from '../models/ProviderEditorApi/Contract/prv-insur-set-request';
import { PrvListResponse as ProviderEditorApiContractPrvListResponse } from '../models/ProviderEditorApi/Contract/prv-list-response';
import { PrvRateListResponse as ProviderEditorApiContractPrvRateListResponse } from '../models/ProviderEditorApi/Contract/prv-rate-list-response';
import { PrvRateSetRequest as ProviderEditorApiContractPrvRateSetRequest } from '../models/ProviderEditorApi/Contract/prv-rate-set-request';
import { PrvSetRequest as ProviderEditorApiContractPrvSetRequest } from '../models/ProviderEditorApi/Contract/prv-set-request';
import { PrvSrvAddRequest as ProviderEditorApiContractPrvSrvAddRequest } from '../models/ProviderEditorApi/Contract/prv-srv-add-request';
import { PrvSrvAddResponse as ProviderEditorApiContractPrvSrvAddResponse } from '../models/ProviderEditorApi/Contract/prv-srv-add-response';
import { PrvSrvListResponse as ProviderEditorApiContractPrvSrvListResponse } from '../models/ProviderEditorApi/Contract/prv-srv-list-response';
import { PrvValidatorAddRequest as ProviderEditorApiContractPrvValidatorAddRequest } from '../models/ProviderEditorApi/Contract/prv-validator-add-request';
import { PrvValidatorListResponse as ProviderEditorApiContractPrvValidatorListResponse } from '../models/ProviderEditorApi/Contract/prv-validator-list-response';

@Injectable({
  providedIn: 'root',
})
export class ProviderEditorService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProviderEditorPrvListGet
   */
  static readonly ApiProviderEditorPrvListGetPath = '/api/ProviderEditor/PrvList';

  /**
   * Getting a list of providers to edit.
   *
   * ### Claim
   *  boservice.providereditor.prvlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvListResponse>>;
      })
    );
  }

  /**
   * Getting a list of providers to edit.
   *
   * ### Claim
   *  boservice.providereditor.prvlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvListResponse>> {

    return this.apiProviderEditorPrvListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvListResponse>>) => r.body as Array<ProviderEditorApiContractPrvListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvCaListGet
   */
  static readonly ApiProviderEditorPrvCaListGetPath = '/api/ProviderEditor/PrvCaList';

  /**
   * Getting custom attribute values for a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvcalist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvCaListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvCaListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id/don't pass for new
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvCaListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvCaListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvCaListResponse>>;
      })
    );
  }

  /**
   * Getting custom attribute values for a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvcalist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvCaListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvCaListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id/don't pass for new
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvCaListResponse>> {

    return this.apiProviderEditorPrvCaListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvCaListResponse>>) => r.body as Array<ProviderEditorApiContractPrvCaListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvAddPost
   */
  static readonly ApiProviderEditorPrvAddPostPath = '/api/ProviderEditor/PrvAdd';

  /**
   * Adding a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvAddPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProviderEditorApiContractPrvAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProviderEditorApiContractPrvAddResponse>;
      })
    );
  }

  /**
   * Adding a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvAddPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvAddRequest
  },
  context?: HttpContext

): Observable<ProviderEditorApiContractPrvAddResponse> {

    return this.apiProviderEditorPrvAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProviderEditorApiContractPrvAddResponse>) => r.body as ProviderEditorApiContractPrvAddResponse)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvSetPost
   */
  static readonly ApiProviderEditorPrvSetPostPath = '/api/ProviderEditor/PrvSet';

  /**
   * Change provider.
   *
   * ### Claim
   *  boservice.providereditor.prvset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvSetPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change provider.
   *
   * ### Claim
   *  boservice.providereditor.prvset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvSetPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvRemoveDelete
   */
  static readonly ApiProviderEditorPrvRemoveDeletePath = '/api/ProviderEditor/PrvRemove';

  /**
   * Deleting a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvremove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvRemoveDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvRemoveDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvRemoveDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a provider.
   *
   * ### Claim
   *  boservice.providereditor.prvremove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvRemoveDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvRemoveDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvRemoveDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvRateListGet
   */
  static readonly ApiProviderEditorPrvRateListGetPath = '/api/ProviderEditor/PrvRateList';

  /**
   * Getting commission value The list can contain no more than one entry of each type of period.
   *
   * ### Claim
   *  boservice.providereditor.prvratelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvRateListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvRateListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvRateListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvRateListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvRateListResponse>>;
      })
    );
  }

  /**
   * Getting commission value The list can contain no more than one entry of each type of period.
   *
   * ### Claim
   *  boservice.providereditor.prvratelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvRateListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvRateListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvRateListResponse>> {

    return this.apiProviderEditorPrvRateListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvRateListResponse>>) => r.body as Array<ProviderEditorApiContractPrvRateListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvRateSetPost
   */
  static readonly ApiProviderEditorPrvRateSetPostPath = '/api/ProviderEditor/PrvRateSet';

  /**
   * Setting commission data.
   *
   * Adding/updating/deleting a commission record If NULL==i_rate_type==i_full_rate==i_priv_rate, then data is deleted
   * ### Claim
   *  boservice.providereditor.prvrateset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvRateSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvRateSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvRateSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvRateSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Setting commission data.
   *
   * Adding/updating/deleting a commission record If NULL==i_rate_type==i_full_rate==i_priv_rate, then data is deleted
   * ### Claim
   *  boservice.providereditor.prvrateset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvRateSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvRateSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvRateSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvRateSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvInsurListGet
   */
  static readonly ApiProviderEditorPrvInsurListGetPath = '/api/ProviderEditor/PrvInsurList';

  /**
   * Obtaining insurance policy data.
   *
   * Getting the current/future value for a policy There can be no more than one entry of each type of period in the list
   * ### Claim
   *  boservice.providereditor.prvinsurlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvInsurListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvInsurListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvInsurListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvInsurListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvInsurListResponse>>;
      })
    );
  }

  /**
   * Obtaining insurance policy data.
   *
   * Getting the current/future value for a policy There can be no more than one entry of each type of period in the list
   * ### Claim
   *  boservice.providereditor.prvinsurlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvInsurListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvInsurListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvInsurListResponse>> {

    return this.apiProviderEditorPrvInsurListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvInsurListResponse>>) => r.body as Array<ProviderEditorApiContractPrvInsurListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvInsurSetPost
   */
  static readonly ApiProviderEditorPrvInsurSetPostPath = '/api/ProviderEditor/PrvInsurSet';

  /**
   * Setting insurance policy data.
   *
   * Adding/updating/deleting a commission record If NULL==i_insur_num, then the data is deleted
   * ### Claim
   *  boservice.providereditor.prvinsurset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvInsurSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvInsurSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvInsurSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvInsurSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Setting insurance policy data.
   *
   * Adding/updating/deleting a commission record If NULL==i_insur_num, then the data is deleted
   * ### Claim
   *  boservice.providereditor.prvinsurset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvInsurSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvInsurSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvInsurSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvInsurSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvSrvListGet
   */
  static readonly ApiProviderEditorPrvSrvListGetPath = '/api/ProviderEditor/PrvSrvList';

  /**
   * Getting a list of provider services/routes.
   *
   * ### Claim
   *  boservice.providereditor.prvsrvlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvSrvListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvSrvListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvSrvListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvSrvListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvSrvListResponse>>;
      })
    );
  }

  /**
   * Getting a list of provider services/routes.
   *
   * ### Claim
   *  boservice.providereditor.prvsrvlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvSrvListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvSrvListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvSrvListResponse>> {

    return this.apiProviderEditorPrvSrvListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvSrvListResponse>>) => r.body as Array<ProviderEditorApiContractPrvSrvListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvSrvAddPost
   */
  static readonly ApiProviderEditorPrvSrvAddPostPath = '/api/ProviderEditor/PrvSrvAdd';

  /**
   * Adding a service/route.
   *
   * Adding a link to a service/route
   * ### Claim
   *  boservice.providereditor.prvsrvadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvSrvAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvSrvAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvSrvAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProviderEditorApiContractPrvSrvAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvSrvAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProviderEditorApiContractPrvSrvAddResponse>;
      })
    );
  }

  /**
   * Adding a service/route.
   *
   * Adding a link to a service/route
   * ### Claim
   *  boservice.providereditor.prvsrvadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvSrvAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvSrvAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvSrvAddRequest
  },
  context?: HttpContext

): Observable<ProviderEditorApiContractPrvSrvAddResponse> {

    return this.apiProviderEditorPrvSrvAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProviderEditorApiContractPrvSrvAddResponse>) => r.body as ProviderEditorApiContractPrvSrvAddResponse)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvSrvRemoveDelete
   */
  static readonly ApiProviderEditorPrvSrvRemoveDeletePath = '/api/ProviderEditor/PrvSrvRemove';

  /**
   * Deleting a service/route.
   *
   * Removing a link to a service/route
   * ### Claim
   *  boservice.providereditor.prvsrvremove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvSrvRemoveDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvSrvRemoveDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * record id if null then all provider records are deleted
     */
    psdId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvSrvRemoveDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('psdId', params.psdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a service/route.
   *
   * Removing a link to a service/route
   * ### Claim
   *  boservice.providereditor.prvsrvremove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvSrvRemoveDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvSrvRemoveDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * record id if null then all provider records are deleted
     */
    psdId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvSrvRemoveDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvDeptListGet
   */
  static readonly ApiProviderEditorPrvDeptListGetPath = '/api/ProviderEditor/PrvDeptList';

  /**
   * Getting a list of parks.
   *
   * Getting a list of parks (sites)
   * ### Claim
   *  boservice.providereditor.prvdeptlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvDeptListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvDeptListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvDeptListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvDeptListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvDeptListResponse>>;
      })
    );
  }

  /**
   * Getting a list of parks.
   *
   * Getting a list of parks (sites)
   * ### Claim
   *  boservice.providereditor.prvdeptlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvDeptListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvDeptListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvDeptListResponse>> {

    return this.apiProviderEditorPrvDeptListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvDeptListResponse>>) => r.body as Array<ProviderEditorApiContractPrvDeptListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvDeptAddPost
   */
  static readonly ApiProviderEditorPrvDeptAddPostPath = '/api/ProviderEditor/PrvDeptAdd';

  /**
   * Adding a park.
   *
   * Adding a park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvDeptAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvDeptAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvDeptAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProviderEditorApiContractPrvDeptAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvDeptAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProviderEditorApiContractPrvDeptAddResponse>;
      })
    );
  }

  /**
   * Adding a park.
   *
   * Adding a park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvDeptAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvDeptAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvDeptAddRequest
  },
  context?: HttpContext

): Observable<ProviderEditorApiContractPrvDeptAddResponse> {

    return this.apiProviderEditorPrvDeptAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProviderEditorApiContractPrvDeptAddResponse>) => r.body as ProviderEditorApiContractPrvDeptAddResponse)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvDeptSetPost
   */
  static readonly ApiProviderEditorPrvDeptSetPostPath = '/api/ProviderEditor/PrvDeptSet';

  /**
   * Park editing.
   *
   * Editing the park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvDeptSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvDeptSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvDeptSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvDeptSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Park editing.
   *
   * Editing the park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvDeptSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvDeptSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvDeptSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvDeptSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvDeptRemoveDelete
   */
  static readonly ApiProviderEditorPrvDeptRemoveDeletePath = '/api/ProviderEditor/PrvDeptRemove';

  /**
   * Removing a park.
   *
   * Removal of the park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptremove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvDeptRemoveDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvDeptRemoveDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * park id
     */
    prdtId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvDeptRemoveDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prdtId', params.prdtId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a park.
   *
   * Removal of the park (site)
   * ### Claim
   *  boservice.providereditor.prvdeptremove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvDeptRemoveDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvDeptRemoveDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * park id
     */
    prdtId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvDeptRemoveDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvValidatorListGet
   */
  static readonly ApiProviderEditorPrvValidatorListGetPath = '/api/ProviderEditor/PrvValidatorList';

  /**
   * Getting a list of validators.
   *
   * ### Claim
   *  boservice.providereditor.prvvalidatorlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvValidatorListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvValidatorListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * c provider ID
     */
    prvId?: number;

    /**
     * park id if not set, then the provider id must be specified; if set, then it is used for filtering and validators are not shown in the warehouse
     */
    prdtId?: number;

    /**
     * 0 - not in stock 1 - in stock null - all
     */
    storage?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractPrvValidatorListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvValidatorListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('prdtId', params.prdtId, {});
      rb.query('storage', params.storage, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractPrvValidatorListResponse>>;
      })
    );
  }

  /**
   * Getting a list of validators.
   *
   * ### Claim
   *  boservice.providereditor.prvvalidatorlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvValidatorListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvValidatorListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * c provider ID
     */
    prvId?: number;

    /**
     * park id if not set, then the provider id must be specified; if set, then it is used for filtering and validators are not shown in the warehouse
     */
    prdtId?: number;

    /**
     * 0 - not in stock 1 - in stock null - all
     */
    storage?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractPrvValidatorListResponse>> {

    return this.apiProviderEditorPrvValidatorListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractPrvValidatorListResponse>>) => r.body as Array<ProviderEditorApiContractPrvValidatorListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvValidatorAddPost
   */
  static readonly ApiProviderEditorPrvValidatorAddPostPath = '/api/ProviderEditor/PrvValidatorAdd';

  /**
   * Adding validators to the warehouse.
   *
   * Adding one or a batch of validators to the warehouse of the specified provider.
   * If there is any duplicate from the range, an error occurs.
   * ### Claim
   *  boservice.providereditor.prvvalidatoradd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvValidatorAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvValidatorAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvValidatorAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvValidatorAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding validators to the warehouse.
   *
   * Adding one or a batch of validators to the warehouse of the specified provider.
   * If there is any duplicate from the range, an error occurs.
   * ### Claim
   *  boservice.providereditor.prvvalidatoradd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvValidatorAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderEditorPrvValidatorAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderEditorApiContractPrvValidatorAddRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvValidatorAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorPrvValidatorRemoveDelete
   */
  static readonly ApiProviderEditorPrvValidatorRemoveDeletePath = '/api/ProviderEditor/PrvValidatorRemove';

  /**
   * Removing validators from the warehouse.
   *
   * Removing validators from the provider's warehouse.
   * Succeeds only if all specified validators are on
   * warehouse of this provider.
   * ### Claim
   *  boservice.providereditor.prvvalidatorremove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorPrvValidatorRemoveDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvValidatorRemoveDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * * validator number (initial)
     */
    vlNFirst?: number;

    /**
     * validator number final if not set (null) one number is removed.
     */
    vlNLast?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorPrvValidatorRemoveDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('vlNFirst', params.vlNFirst, {});
      rb.query('vlNLast', params.vlNLast, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing validators from the warehouse.
   *
   * Removing validators from the provider's warehouse.
   * Succeeds only if all specified validators are on
   * warehouse of this provider.
   * ### Claim
   *  boservice.providereditor.prvvalidatorremove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorPrvValidatorRemoveDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorPrvValidatorRemoveDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * * validator number (initial)
     */
    vlNFirst?: number;

    /**
     * validator number final if not set (null) one number is removed.
     */
    vlNLast?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderEditorPrvValidatorRemoveDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEditorListPrvServicesGet
   */
  static readonly ApiProviderEditorListPrvServicesGetPath = '/api/ProviderEditor/ListPrvServices';

  /**
   * Getting a list of Provider's services.
   *
   * Getting a list of services related to the specified Provider
   * (Spanish. For example, in MU, there you need to specify a service for place_service
   * ### Claim
   *  boservice.providereditor.listprvservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorListPrvServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvServicesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractListPrvServicesResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorListPrvServicesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractListPrvServicesResponse>>;
      })
    );
  }

  /**
   * Getting a list of Provider's services.
   *
   * Getting a list of services related to the specified Provider
   * (Spanish. For example, in MU, there you need to specify a service for place_service
   * ### Claim
   *  boservice.providereditor.listprvservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorListPrvServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvServicesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractListPrvServicesResponse>> {

    return this.apiProviderEditorListPrvServicesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractListPrvServicesResponse>>) => r.body as Array<ProviderEditorApiContractListPrvServicesResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorListPrvGroupTypesGet
   */
  static readonly ApiProviderEditorListPrvGroupTypesGetPath = '/api/ProviderEditor/ListPrvGroupTypes';

  /**
   * Getting a list of Provider installation site group types.
   *
   * ### Claim
   *  boservice.providereditor.listprvgrouptypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorListPrvGroupTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvGroupTypesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;

    /**
     * parent group id/null when adding root element
     */
    pPgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupTypesResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorListPrvGroupTypesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('pPgId', params.pPgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupTypesResponse>>;
      })
    );
  }

  /**
   * Getting a list of Provider installation site group types.
   *
   * ### Claim
   *  boservice.providereditor.listprvgrouptypes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorListPrvGroupTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvGroupTypesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;

    /**
     * parent group id/null when adding root element
     */
    pPgId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractListPrvGroupTypesResponse>> {

    return this.apiProviderEditorListPrvGroupTypesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupTypesResponse>>) => r.body as Array<ProviderEditorApiContractListPrvGroupTypesResponse>)
    );
  }

  /**
   * Path part for operation apiProviderEditorListPrvGroupsGet
   */
  static readonly ApiProviderEditorListPrvGroupsGetPath = '/api/ProviderEditor/ListPrvGroups';

  /**
   * Getting a list of groups of Provider installation locations.
   *
   * ### Claim
   *  boservice.providereditor.listprvgroups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEditorListPrvGroupsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvGroupsGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;

    /**
     * return only lower level elements - 0(null)/1
     */
    lowest?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderEditorService.ApiProviderEditorListPrvGroupsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('lowest', params.lowest, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupsResponse>>;
      })
    );
  }

  /**
   * Getting a list of groups of Provider installation locations.
   *
   * ### Claim
   *  boservice.providereditor.listprvgroups
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEditorListPrvGroupsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEditorListPrvGroupsGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider id
     */
    prvId?: number;

    /**
     * return only lower level elements - 0(null)/1
     */
    lowest?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderEditorApiContractListPrvGroupsResponse>> {

    return this.apiProviderEditorListPrvGroupsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderEditorApiContractListPrvGroupsResponse>>) => r.body as Array<ProviderEditorApiContractListPrvGroupsResponse>)
    );
  }

}
