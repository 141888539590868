import { ProviderModelId } from '../providers/provider.model';

export type OrganizationId = number;
export type OrganizationName = string;
export type OrganizationIsDept = number;

export interface OrganizationModel {
    orgId?: OrganizationId;
    orgName?: OrganizationName;
    pOrgId?: OrganizationId;
    prvId?: ProviderModelId;
    pPrvId?: ProviderModelId;
    isDept?: OrganizationIsDept;
    level?: number;
    rootParent?: number;
    children?: OrganizationModel[];
    breadcrumbs?: string[];
    prdtId?: number;
    slrId?: number;
    trmId?: number;
}

export interface OrganizationAddResponse {
    newOrgId: OrganizationId;
}

export enum OrganizationFilterType {
    Provider = 3,
    Platform = 4,
}
