import { Pipe, PipeTransform } from '@angular/core';
import { getMimeType } from '@libs/base64mime/base64mime';


@Pipe({
    name: 'base64ImgWrap',
    standalone: true,
})
export class Base64ImgWrapPipe implements PipeTransform {
    public transform(base64code: string, ...args: unknown[]): string {
        return `data:${
            args[0] ? args[0] : getMimeType(base64code)
        };base64,${base64code}`;
    }
}
