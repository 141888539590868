<agm-map
  [latitude]="center?.lat"
  [longitude]="center?.lng"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  (mapClick)="handlerMapClick($event)"
>
  <agm-marker
    *ngFor="let m of displayMarkers; let i = index"
    [markerDraggable]="m.draggable"
    [iconUrl]="'/assets/svg/pin.svg'"
    [latitude]="m.lat"
    [longitude]="m.lng"
    (dragEnd)="handlerOutputStop($event)"
  >
    <agm-info-window>
      <strong>{{ m.label }}</strong>
    </agm-info-window>
  </agm-marker>
</agm-map>
