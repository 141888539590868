import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormControlStatus,
    NgControl,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidationMessagePipe } from '@pipes/validation-message/validation-message.pipe';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
    selector: 'ui-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatFormFieldModule,
        ReactiveFormsModule,
        ValidationMessagePipe,
        NgIf,
        TranslateModule,
    ],
    host: {
        'class': 'ui-form-checkbox',
    },
})
export class FormCheckboxComponent implements ControlValueAccessor, OnInit {
    @Input()
    public label: string = '';

    @Input()
    public hint: string = '';

    @Input()
    public disabled: boolean;

    @Input()
    public value: boolean;

    @Input()
    public switch: boolean;

    @Input()
    public id: string = 'ui-checkbox-' + Math.random().toString().replace('.', '');

    @Output()
    public changeCheckedValue: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('checkBoxInput', { static: true }) checkboxElement: ElementRef;

    public inputControl: FormControl;

    private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);
    private readonly ngControl: NgControl = inject(NgControl, { self: true, optional: true });
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor() {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public get isRequiredField(): boolean {
        return this.inputControl?.hasValidator(Validators.required);
    }

    public ngOnInit(): void {
        this.inputControl = this.ngControl?.control as FormControl;

        if (this.inputControl) {
            this.inputControl.setValue(this.inputControl.value);
            this.changeCheckedValue.emit(this.inputControl.value);
            this.checkboxElement.nativeElement.checked = Boolean(this.inputControl.value);

            this.disabled = this.inputControl.status === 'DISABLED';

            this.inputControl.statusChanges.pipe(
                takeUntil(this.destroy$),
            ).subscribe((status: FormControlStatus) => {
                this.disabled = status === 'DISABLED';
            });
        }
    }

    public registerOnChange(): void {
    }

    public registerOnTouched(): void {

    }

    public writeValue(value: boolean): void {
        this.checkboxElement.nativeElement.checked = Boolean(value);
    }

    public onChange(event: Event): void {
        const element: HTMLInputElement = event.target as HTMLInputElement;
        this.changeCheckedValue.emit(element.checked);
        this.inputControl.setValue(element.checked);
        this.inputControl.markAsDirty();
        this.inputControl.markAsTouched();
        this.cd.markForCheck();
    }
}
