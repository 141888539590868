import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneFormComponent } from './zone-form.component';
import { MultilangInputModule } from '@ui/multilang-input/multilang-input.module';
import { UiInputModule } from '@ui/ui-input/ui-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { ButtonModule } from '@ui/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ZoneFormComponent],
  imports: [
    CommonModule,
    MultilangInputModule,
    UiInputModule,
    TranslateModule,
    ButtonRowModule,
    ButtonModule,
    ReactiveFormsModule,
  ],
  exports: [ZoneFormComponent],
})
export class ZoneFormModule {}
