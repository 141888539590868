import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SettingsService } from '@libs/settings/settings.service';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { StorageKeys } from '@core/storage/storage.keys';


/**
 * Cookies policy message
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-cookie-message',
    templateUrl: './cookie-message.component.html',
    styleUrls: ['./cookie-message.component.scss'],
    providers: [CookieService],
})
export class CookieMessageComponent implements OnInit, OnDestroy {
    /**
     * Show flag
     */
    public isShowMessage: boolean;
    /**
     * Destroy var for unsubscribe
     */
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor(
        private settingsService: SettingsService,
        private cd: ChangeDetectorRef,
        private cookieService: CookieService,
    ) {
    }

    public ngOnInit(): void {
        const cookieExists: boolean = this.cookieService.check(StorageKeys.CookiesAccept);
        if (!cookieExists) {
            this.isShowMessage = true;
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Handling a click on the OK button
     */
    public handlerAccept(): void {
        const cookieDomain: string = environment.localSettings
            ? null
            : this.settingsService.settings.APP_SETTINGS_BASE_DOMAIN;

        this.cookieService.set(StorageKeys.CookiesAccept, 'Y', null, '/', cookieDomain);
        this.isShowMessage = false;
        this.cd.markForCheck();
    }
}
