import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ButtonModule } from '@ui/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonRowModule } from '@ui/button-row/button-row.module';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, ButtonRowModule],
  exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
