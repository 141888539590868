<div class="position-fixed py-4" *ngIf="isShowMessage">
  <div class="container-fluid">
    <div
      class="cookie-message d-flex flex-row flex-wrap flex-md-nowrap align-items-center justify-content-center"
    >
      <p
        class="mb-0 me-6 text-white"
        [innerHTML]="'ui.cookie-message.text' | translate"
      ></p>
      <button class="btn btn-lg btn-primary mt-3 mt-md-0" (click)="handlerAccept()">
        {{ 'ui.cookie-message.btn' | translate }}
      </button>
    </div>
  </div>
</div>
