import { StartWithUnit } from '@core/operators/start-with-unit';
import { Place } from '@api/models/Postgres/Models/place';
import { Observable } from 'rxjs';
import { VisOrgFiltered } from '@api/models/UserService/Contract/vis-org-filtered';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { VisOrgService } from '@api/services/vis-org.service';
import { inject, Injectable } from '@angular/core';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { StorageKeys } from '@core/storage/storage.keys';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CustomRxState } from '@core/state/rx.state';
import { ProviderService } from '@api/services/provider.service';
import { FilterPlacesValueModel } from '../../features/places/filter-places-form/filter-places-form.service';
import { Service } from '@api/models/Postgres/Models/service';
import { StPoint } from '@api/models/BoLanka/Contract/st-point';
import { RoutesService } from '@api/services/routes.service';
import { Run } from '@api/models/Postgres/Models/run';
import { PrvValidatorListResponse } from '@api/models/ProviderEditorApi/Contract/prv-validator-list-response';
import { ProviderEditorService } from '@api/services/provider-editor.service';
import { Employer } from '@api/models/Postgres/Models/employer';
import { Route } from '@api/models/Postgres/Models/route';
import { PrvDeptListResponse } from '@api/models/ProviderEditorApi/Contract/prv-dept-list-response';
import { ValidatorCreateData } from '@api/models/Postgres/Models/validator-create-data';
import { startWithLoading } from '@core/operators/start-with-loading';
import { PlaceCreateData } from '@api/models/Postgres/Models/place-create-data';
import { SetShiftModeData } from '@api/models/Postgres/Models/set-shift-mode-data';
import { GetBarCodeResponse } from '@api/models/BoLanka/Contract/get-bar-code-response';
import { PrvPlaceSetRequest } from '@api/models/ProviderApi/Contract/prv-place-set-request';
import { PrvDomainListResponse } from '@api/models/ProviderApi/Contract/prv-domain-list-response';


interface IPlacesState {
    loading: Record<StartWithUnit, boolean>;
    places: Place[];
    providers: VisOrgFiltered[];
    services: Service[];
    stops: StPoint[];
    routes: Route[];
    employers: Employer[];
    runs: Run[];
    departments: PrvDeptListResponse[];
    validators: PrvValidatorListResponse[];
    provider: VisOrgFiltered;
    filterPlaces: FilterPlacesValueModel;
    domains: PrvDomainListResponse[];
}

interface PlacesActions {
    requestPlaces: Place[];
    requestProviders: VisOrgFiltered[];
    requestServices: Service[];
    requestRoutes: Route[];
    requestValidators: PrvValidatorListResponse[];
    requestRuns: Run[];
    requestDomains: PrvDomainListResponse[];
    requestEmployers: Employer[];
    requestDepartments: PrvDeptListResponse[];
    setSelectProvider: VisOrgFiltered;
    setFilterPlaces: FilterPlacesValueModel;
}

@Injectable()
export class PlacesState extends CustomRxState<IPlacesState> {
    @LocalStorage(StorageKeys.RefSelectedProvider)
    protected providerFromStorage: string;

    @SessionStorage(StorageKeys.RefFilterPlaces)
    protected filterPlacesFromStorage: string;

    readonly #visOrgService: VisOrgService = inject(VisOrgService);
    readonly #providerService: ProviderService = inject(ProviderService);
    readonly #providerEditorService: ProviderEditorService = inject(ProviderEditorService);
    readonly #routesService: RoutesService = inject(RoutesService);
    readonly #actions: RxActions<PlacesActions> = new RxActionFactory<PlacesActions>().create();

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly providers$: Observable<VisOrgFiltered[]> = this.select('providers');
    public readonly provider$: Observable<VisOrgFiltered> = this.select('provider');
    public readonly places$: Observable<Place[]> = this.select('places');
    public readonly services$: Observable<Service[]> = this.select('services');
    public readonly runs$: Observable<Run[]> = this.select('runs');
    public readonly routes$: Observable<Route[]> = this.select('routes');
    public readonly domains$: Observable<PrvDomainListResponse[]> = this.select('domains');
    public readonly employers$: Observable<Employer[]> = this.select('employers');
    public readonly validators$: Observable<PrvValidatorListResponse[]> = this.select('validators');
    public readonly departments$: Observable<PrvDeptListResponse[]> = this.select('departments');
    public readonly filterPlaces$: Observable<FilterPlacesValueModel> = this.select('filterPlaces');
    public readonly stops$: Observable<StPoint[]> = this.selectLazy('stops', this.requestStops);

    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.subscribeDependencyOnProvider();
        this.requestProviders();
    }

    public setSelectProvider(provider: VisOrgFiltered): void {
        this.#actions.setSelectProvider(provider);
    }

    public setFilterPlaces(filter: FilterPlacesValueModel): void {
        setTimeout(() => {
            this.#actions.setFilterPlaces(filter);
        });
    }

    public requestProviders(): void {
        this.hold(
            this.#visOrgService.apiUsersListVisOrgPost({
                body: {
                    orgTypes: [3, 4],
                    isDept: 0,
                },
            }),
            this.#actions.requestProviders,
        );
    }

    public requestPlaces(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerService.apiProviderPlacesGet(this.getParamsForProviderEntityGetRequest(provider)).pipe(
                startWithLoading(this, StartWithUnit.RequestLocations),
            ),
            this.#actions.requestPlaces,
        );
    }

    public requestRuns(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerService.apiProviderRunsGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestRuns,
        );
    }

    public requestValidators(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvValidatorListGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestValidators,
        );
    }

    public requestEmployers(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerService.apiProviderEmployersGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestEmployers,
        );
    }

    public requestDomains(srvId: number): void {
        if (!srvId) {
            this.#actions.requestDomains([]);
        } else {
            this.hold(
                this.provider$.pipe(
                    switchMap((provider: VisOrgFiltered) =>
                        this.#providerService.apiProviderPrvDomainListGet({
                            prvId: this.getProviderIdForPostRequests(provider), srvId,
                        })),
                ),
                this.#actions.requestDomains,
            );
        }
    }

    public requestRoutes(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerService.apiProviderRoutesGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestRoutes,
        );
    }

    public requestDepartments(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvDeptListGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestDepartments,
        );
    }

    public requestServices(provider: VisOrgFiltered): void {
        this.hold(
            this.#providerService.apiProviderServicesGet(this.getParamsForProviderEntityGetRequest(provider)),
            this.#actions.requestServices,
        );
    }

    private requestStops(): Observable<StPoint[]> {
        return this.#routesService.apiRoutesStopPointGet();
    }

    public createPlace(body: PlaceCreateData): Observable<Place> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderPlacesPost({
                        prvId: this.getProviderIdForPostRequests(provider),
                        body,
                    },
                ).pipe(
                    startWithLoading(this, StartWithUnit.AddLocation),
                    tap(() => this.requestPlaces(provider)),
                ),
            ),
        );
    }

    public editPlace(body: PrvPlaceSetRequest): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderPrvPlaceSetPost({
                        body: {
                            ...body,
                            ...{ prvId: this.getProviderIdForPostRequests(provider) },
                        },
                    },
                ).pipe(
                    startWithLoading(this, StartWithUnit.AddLocation),
                    tap(() => this.requestPlaces(provider)),
                ),
            ),
        );
    }

    public removePlace(placeId: number): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderPlacesPlaceIdDelete({
                        prvId: this.getProviderIdForPostRequests(provider),
                        placeId,
                    },
                ).pipe(
                    startWithLoading(this, StartWithUnit.RemoveLocation),
                    tap(() => this.requestPlaces(provider)),
                ),
            ),
        );
    }

    public changePlaceShiftMode(body: SetShiftModeData): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderSetShiftModePost({
                        body,
                    },
                ).pipe(
                    startWithLoading(this, StartWithUnit.EditLocationShiftMode),
                    tap(() => this.requestPlaces(provider)),
                ),
            ),
        );
    }

    public addValidatorToLocation(validatorId: number, body: ValidatorCreateData): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderValidatorsValidatorIdPlacePost({
                    validatorId,
                    prvId: this.getProviderIdForPostRequests(provider),
                    body,
                }).pipe(
                    startWithLoading(this, StartWithUnit.AddValidatorToLocation),
                    tap(() => {
                        this.requestValidators(provider);
                        this.requestPlaces(provider);
                    }),
                ),
            ),
        );
    }

    public removeValidatorFromLocation(validatorId: number, placeId: number): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderValidatorsValidatorIdDelete({
                    validatorId,
                    prvId: this.getProviderIdForPostRequests(provider),
                    placeId,
                }).pipe(
                    startWithLoading(this, StartWithUnit.RemoveValidatorFromLocation),
                    tap(() => {
                        this.requestValidators(provider);
                        this.requestPlaces(provider);
                    }),
                ),
            ),
        );
    }

    public closeValidatorShift(validatorId: number, placeId: number): Observable<void> {
        return this.provider$.pipe(
            switchMap(
                (provider: VisOrgFiltered) => this.#providerService.apiProviderValidatorsValidatorIdCloseShiftPost({
                    validatorId,
                    prvId: this.getProviderIdForPostRequests(provider),
                    body: {
                        placeId,
                    },
                }).pipe(
                    startWithLoading(this, StartWithUnit.CloseValidatorShift),
                    tap(() => this.requestValidators(provider)),
                ),
            ),
        );
    }

    public getPlaceBarCode(plId: number): Observable<GetBarCodeResponse> {
        return this.provider$.pipe(
            switchMap((provider: VisOrgFiltered) =>
                this.#routesService.apiRoutesGetBarCodeGet({
                        plId,
                        providerId: this.getProviderIdForPostRequests(provider),
                    },
                ),
            ),
        );
    }

    public getPlaceBarCodes(body: number[]): Observable<GetBarCodeResponse[]> {
        return this.provider$.pipe(
            switchMap((provider: VisOrgFiltered) =>
                this.#routesService.apiRoutesGetBarCodePost({
                        body,
                        providerId: this.getProviderIdForPostRequests(provider),
                    },
                ),
            ),
        );
    }

    private connectSelectors(): void {
        this.connect('providers', this.#actions.requestProviders$.pipe(
            tap((providers: VisOrgFiltered[]) => {
                if (!this.get('provider')) {
                    this.setSelectProvider(providers[0]);
                }
            }),
        ));

        this.connect('filterPlaces', this.#actions.setFilterPlaces$.pipe(
            tap((filter: FilterPlacesValueModel) => {
                this.filterPlacesFromStorage = JSON.stringify(filter);
            }),
        ));
        this.connect('places', this.#actions.requestPlaces$);
        this.connect('services', this.#actions.requestServices$);
        this.connect('runs', this.#actions.requestRuns$);
        this.connect('validators', this.#actions.requestValidators$);
        this.connect('employers', this.#actions.requestEmployers$);
        this.connect('routes', this.#actions.requestRoutes$);
        this.connect('domains', this.#actions.requestDomains$);
        this.connect('departments', this.#actions.requestDepartments$);
        this.connect('provider', this.#actions.setSelectProvider$.pipe(
            filter(Boolean),
            tap((provider: VisOrgFiltered) => {
                this.setFilterPlaces(null);
                this.providerFromStorage = JSON.stringify(provider);
            }),
        ));
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            providers: null,
            services: [],
            employers: [],
            validators: [],
            departments: [],
            routes: [],
            runs: [],
            places: null,
            provider: !!this.providerFromStorage ? JSON.parse(this.providerFromStorage) : null,
            filterPlaces: !!this.filterPlacesFromStorage ? JSON.parse(this.filterPlacesFromStorage) : null,
        });
    }

    private subscribeDependencyOnProvider(): void {
        this.hold(
            this.provider$.pipe(filter(Boolean)),
            (provider: VisOrgFiltered) => {
                this.requestPlaces(provider);
                this.requestServices(provider);
                this.requestEmployers(provider);
                this.requestRoutes(provider);
                this.requestRuns(provider);
                this.requestDepartments(provider);
                this.requestValidators(provider);
            },
        );
    }

    private getParamsForProviderEntityGetRequest(provider: VisOrgFiltered): Record<string, string> {
        const params: Record<string, string> = {
            prvId: provider.prdtId !== null && provider.pPrvId !== null
                ? String(provider.pPrvId)
                : String(provider.prvId),
        };

        if (
            provider.prdtId &&
            provider.prvId === null
        ) {
            params.prdtId = String(provider.prdtId);
        }

        return params;
    }

    /**
     * Returned provider id for Api
     */
    public getProviderIdForPostRequests(provider: VisOrgFiltered): number {
        if (!provider) {
            return null;
        }

        return provider.prdtId !== null && provider.pPrvId !== null
            ? provider.pPrvId
            : provider.prvId;
    }
}

