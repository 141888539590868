<ng-container *ngIf="tariffs; else preloader">
  <ng-container *ngIf="tariffs?.length > 0; else no_data">
    <ng-container
      *ngFor="let srvSection of tariffs; trackBy: identity; let i = index"
    >
      <section>
        <h3 class="mb-2">{{ srvSection.srvName }}</h3>
        <div class="sort" (cdkDropListDropped)="onSort($event, i)" cdkDropList>
          <ng-container
            *ngFor="let tariff of srvSection.trfItems; trackBy: identityTrf"
          >
            <div class="sort__item" cdkDrag>
              {{ tariff.trfName }}
              <i class="icon-drag"></i>
            </div>
          </ng-container>
        </div>
        <div class=""></div>
      </section>
    </ng-container>
  </ng-container>
</ng-container>

<ui-button
  [size]="'lg'"
  [label]="'global.labels.save'"
  [disabled]="!isChanged || pending"
  (handlerClick)="handlerSort()"
>
</ui-button>

<ng-template #no_data>
  <p>{{ 'global.labels.no_data' | translate }}</p>
</ng-template>

<ng-template #preloader>
  <ui-pre-loader></ui-pre-loader>
</ng-template>
