/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddProviderRequest as BoLankaContractAddProviderRequest } from '../models/BoLanka/Contract/add-provider-request';
import { AddProviderResponse as BoLankaContractAddProviderResponse } from '../models/BoLanka/Contract/add-provider-response';
import { Provider as BoLankaContractProvider } from '../models/BoLanka/Contract/provider';
import { SetPrvDataRequest as BoLankaContractSetPrvDataRequest } from '../models/BoLanka/Contract/set-prv-data-request';
import { Dept as PostgresModelsDept } from '../models/Postgres/Models/dept';
import { Employer as PostgresModelsEmployer } from '../models/Postgres/Models/employer';
import { Lang as PostgresModelsLang } from '../models/Postgres/Models/lang';
import { Place as PostgresModelsPlace } from '../models/Postgres/Models/place';
import { PlaceCreateData as PostgresModelsPlaceCreateData } from '../models/Postgres/Models/place-create-data';
import { Route as PostgresModelsRoute } from '../models/Postgres/Models/route';
import { Run as PostgresModelsRun } from '../models/Postgres/Models/run';
import { Service as PostgresModelsService } from '../models/Postgres/Models/service';
import { SetShiftModeData as PostgresModelsSetShiftModeData } from '../models/Postgres/Models/set-shift-mode-data';
import { Storage as PostgresModelsStorage } from '../models/Postgres/Models/storage';
import { Validator as PostgresModelsValidator } from '../models/Postgres/Models/validator';
import { ValidatorCreateData as PostgresModelsValidatorCreateData } from '../models/Postgres/Models/validator-create-data';
import { PrvDomainListResponse as ProviderApiContractPrvDomainListResponse } from '../models/ProviderApi/Contract/prv-domain-list-response';
import { PrvPlaceSetRequest as ProviderApiContractPrvPlaceSetRequest } from '../models/ProviderApi/Contract/prv-place-set-request';
import { PrvServiceAddRequest as ProviderApiContractPrvServiceAddRequest } from '../models/ProviderApi/Contract/prv-service-add-request';
import { PrvServiceAddResponse as ProviderApiContractPrvServiceAddResponse } from '../models/ProviderApi/Contract/prv-service-add-response';
import { PrvServiceListResponse as ProviderApiContractPrvServiceListResponse } from '../models/ProviderApi/Contract/prv-service-list-response';
import { CloseShiftBody as ProviderApiModelsCloseShiftBody } from '../models/ProviderApi/Models/close-shift-body';

@Injectable({
  providedIn: 'root',
})
export class ProviderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProviderValidatorsValidatorIdPlacePost
   */
  static readonly ApiProviderValidatorsValidatorIdPlacePostPath = '/api/Provider/validators/{validatorId}/place';

  /**
   * Binding the validator to the place where the Provider is installed.
   *
   * ### Claim
   *  boservice.provider.addvalidator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderValidatorsValidatorIdPlacePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderValidatorsValidatorIdPlacePost$Response(params: {
    validatorId: number;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data for binding the validator to the place of installation of the Provider
     */
    body?: PostgresModelsValidatorCreateData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderValidatorsValidatorIdPlacePostPath, 'post');
    if (params) {
      rb.path('validatorId', params.validatorId, {});
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Binding the validator to the place where the Provider is installed.
   *
   * ### Claim
   *  boservice.provider.addvalidator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderValidatorsValidatorIdPlacePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderValidatorsValidatorIdPlacePost(params: {
    validatorId: number;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data for binding the validator to the place of installation of the Provider
     */
    body?: PostgresModelsValidatorCreateData
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderValidatorsValidatorIdPlacePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderValidatorsValidatorIdDelete
   */
  static readonly ApiProviderValidatorsValidatorIdDeletePath = '/api/Provider/validators/{validatorId}';

  /**
   * Removing the validator from the Provider's installation site.
   *
   * ### Claim
   *  boservice.provider.delvalidator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderValidatorsValidatorIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderValidatorsValidatorIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    groupId?: number;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Installation location ID
     */
    placeId?: number;

    /**
     * Validator ID
     */
    validatorId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderValidatorsValidatorIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('groupId', params.groupId, {});
      rb.query('prvId', params.prvId, {});
      rb.query('placeId', params.placeId, {});
      rb.path('validatorId', params.validatorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing the validator from the Provider's installation site.
   *
   * ### Claim
   *  boservice.provider.delvalidator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderValidatorsValidatorIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderValidatorsValidatorIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;
    groupId?: number;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Installation location ID
     */
    placeId?: number;

    /**
     * Validator ID
     */
    validatorId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderValidatorsValidatorIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderStorageGet
   */
  static readonly ApiProviderStorageGetPath = '/api/Provider/storage';

  /**
   * Obtaining the name of the Provider's warehouse.
   *
   * ### Claim
   *  boservice.provider.listprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderStorageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderStorageGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PostgresModelsStorage>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderStorageGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostgresModelsStorage>;
      })
    );
  }

  /**
   * Obtaining the name of the Provider's warehouse.
   *
   * ### Claim
   *  boservice.provider.listprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderStorageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderStorageGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<PostgresModelsStorage> {

    return this.apiProviderStorageGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PostgresModelsStorage>) => r.body as PostgresModelsStorage)
    );
  }

  /**
   * Path part for operation apiProviderDeptsGet
   */
  static readonly ApiProviderDeptsGetPath = '/api/Provider/depts';

  /**
   * Getting a list of Provider parks.
   *
   * ### Claim
   *  boservice.provider.listprvdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderDeptsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderDeptsGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsDept>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderDeptsGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsDept>>;
      })
    );
  }

  /**
   * Getting a list of Provider parks.
   *
   * ### Claim
   *  boservice.provider.listprvdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderDeptsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderDeptsGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsDept>> {

    return this.apiProviderDeptsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsDept>>) => r.body as Array<PostgresModelsDept>)
    );
  }

  /**
   * Path part for operation apiProviderPlacesGet
   */
  static readonly ApiProviderPlacesGetPath = '/api/Provider/places';

  /**
   * Getting a list of Provider installation locations.
   *
   * ### Claim
   *  boservice.provider.listprvplaces
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPlacesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPlacesGet$Response(params?: {
    lang?: string;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * provider id
     */
    prdtId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsPlace>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPlacesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('prdtId', params.prdtId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsPlace>>;
      })
    );
  }

  /**
   * Getting a list of Provider installation locations.
   *
   * ### Claim
   *  boservice.provider.listprvplaces
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPlacesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPlacesGet(params?: {
    lang?: string;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * provider id
     */
    prdtId?: number;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsPlace>> {

    return this.apiProviderPlacesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsPlace>>) => r.body as Array<PostgresModelsPlace>)
    );
  }

  /**
   * Path part for operation apiProviderPlacesPost
   */
  static readonly ApiProviderPlacesPostPath = '/api/Provider/places';

  /**
   * Adding a grouping of Provider installation locations.
   *
   * ### Claim
   *  boservice.provider.addplace
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPlacesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPlacesPost$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data for creating a grouping of Provider installation locations
     */
    body?: PostgresModelsPlaceCreateData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PostgresModelsPlace>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPlacesPostPath, 'post');
    if (params) {
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostgresModelsPlace>;
      })
    );
  }

  /**
   * Adding a grouping of Provider installation locations.
   *
   * ### Claim
   *  boservice.provider.addplace
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPlacesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPlacesPost(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data for creating a grouping of Provider installation locations
     */
    body?: PostgresModelsPlaceCreateData
  },
  context?: HttpContext

): Observable<PostgresModelsPlace> {

    return this.apiProviderPlacesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<PostgresModelsPlace>) => r.body as PostgresModelsPlace)
    );
  }

  /**
   * Path part for operation apiProviderServicesGet
   */
  static readonly ApiProviderServicesGetPath = '/api/Provider/services';

  /**
   * Getting a list of Provider's services.
   *
   * ### Claim
   *  boservice.provider.listprvservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderServicesGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsService>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderServicesGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsService>>;
      })
    );
  }

  /**
   * Getting a list of Provider's services.
   *
   * ### Claim
   *  boservice.provider.listprvservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderServicesGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsService>> {

    return this.apiProviderServicesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsService>>) => r.body as Array<PostgresModelsService>)
    );
  }

  /**
   * Path part for operation apiProviderValidatorsGet
   */
  static readonly ApiProviderValidatorsGetPath = '/api/Provider/validators';

  /**
   * Getting a list of Provider's validators.
   *
   * ### Claim
   *  boservice.provider.listprvvalidators
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderValidatorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderValidatorsGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsValidator>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderValidatorsGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsValidator>>;
      })
    );
  }

  /**
   * Getting a list of Provider's validators.
   *
   * ### Claim
   *  boservice.provider.listprvvalidators
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderValidatorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderValidatorsGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsValidator>> {

    return this.apiProviderValidatorsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsValidator>>) => r.body as Array<PostgresModelsValidator>)
    );
  }

  /**
   * Path part for operation apiProviderLangsGet
   */
  static readonly ApiProviderLangsGetPath = '/api/Provider/langs';

  /**
   * Get a list of supported languages for a region.
   *
   * ### Claim
   *  boservice.provider.listlangservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderLangsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderLangsGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsLang>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderLangsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsLang>>;
      })
    );
  }

  /**
   * Get a list of supported languages for a region.
   *
   * ### Claim
   *  boservice.provider.listlangservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderLangsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderLangsGet(params?: {
  },
  context?: HttpContext

): Observable<Array<PostgresModelsLang>> {

    return this.apiProviderLangsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsLang>>) => r.body as Array<PostgresModelsLang>)
    );
  }

  /**
   * Path part for operation apiProviderPlacesPlaceIdDelete
   */
  static readonly ApiProviderPlacesPlaceIdDeletePath = '/api/Provider/places/{placeId}';

  /**
   * Deleting Provider Installation Location Data.
   *
   * ### Claim
   *  boservice.provider.delplace
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPlacesPlaceIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPlacesPlaceIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Installation location ID
     */
    placeId: number;

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPlacesPlaceIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('placeId', params.placeId, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting Provider Installation Location Data.
   *
   * ### Claim
   *  boservice.provider.delplace
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPlacesPlaceIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPlacesPlaceIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Installation location ID
     */
    placeId: number;

    /**
     * provider ID
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderPlacesPlaceIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderValidatorsValidatorIdCloseShiftPost
   */
  static readonly ApiProviderValidatorsValidatorIdCloseShiftPostPath = '/api/Provider/validators/{validatorId}/closeShift';

  /**
   * Emergency closing of the validator shift.
   *
   * ### Claim
   *  boservice.provider.abnormalvalshiftclose
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderValidatorsValidatorIdCloseShiftPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderValidatorsValidatorIdCloseShiftPost$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Validator ID
     */
    validatorId: number;

    /**
     * Installation location ID
     */
    body?: ProviderApiModelsCloseShiftBody
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderValidatorsValidatorIdCloseShiftPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.path('validatorId', params.validatorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Emergency closing of the validator shift.
   *
   * ### Claim
   *  boservice.provider.abnormalvalshiftclose
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderValidatorsValidatorIdCloseShiftPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderValidatorsValidatorIdCloseShiftPost(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Validator ID
     */
    validatorId: number;

    /**
     * Installation location ID
     */
    body?: ProviderApiModelsCloseShiftBody
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderValidatorsValidatorIdCloseShiftPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderEmployersGet
   */
  static readonly ApiProviderEmployersGetPath = '/api/Provider/employers';

  /**
   * Directory of employees (drivers, conductors).
   *
   * ### Claim
   *  boservice.provider.listemp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderEmployersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEmployersGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsEmployer>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderEmployersGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsEmployer>>;
      })
    );
  }

  /**
   * Directory of employees (drivers, conductors).
   *
   * ### Claim
   *  boservice.provider.listemp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderEmployersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderEmployersGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsEmployer>> {

    return this.apiProviderEmployersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsEmployer>>) => r.body as Array<PostgresModelsEmployer>)
    );
  }

  /**
   * Path part for operation apiProviderRoutesGet
   */
  static readonly ApiProviderRoutesGetPath = '/api/Provider/routes';

  /**
   * Route directory.
   *
   * ### Claim
   *  boservice.provider.listroutes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderRoutesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderRoutesGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Data language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderRoutesGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsRoute>>;
      })
    );
  }

  /**
   * Route directory.
   *
   * ### Claim
   *  boservice.provider.listroutes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderRoutesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderRoutesGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Data language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsRoute>> {

    return this.apiProviderRoutesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsRoute>>) => r.body as Array<PostgresModelsRoute>)
    );
  }

  /**
   * Path part for operation apiProviderRunsGet
   */
  static readonly ApiProviderRunsGetPath = '/api/Provider/runs';

  /**
   * Output directory.
   *
   * ### Claim
   *  boservice.provider.listruns
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderRunsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderRunsGet$Response(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Data language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelsRun>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderRunsGetPath, 'get');
    if (params) {
      rb.query('prvId', params.prvId, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelsRun>>;
      })
    );
  }

  /**
   * Output directory.
   *
   * ### Claim
   *  boservice.provider.listruns
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderRunsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderRunsGet(params?: {

    /**
     * provider ID
     */
    prvId?: number;

    /**
     * Data language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelsRun>> {

    return this.apiProviderRunsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelsRun>>) => r.body as Array<PostgresModelsRun>)
    );
  }

  /**
   * Path part for operation apiProviderSetShiftModePost
   */
  static readonly ApiProviderSetShiftModePostPath = '/api/Provider/setShiftMode';

  /**
   * Editing the opening mode of the shift MU.
   *
   * ### Claim
   *  boservice.provider.setshiftmode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderSetShiftModePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderSetShiftModePost$Response(params?: {

    /**
     * Data language
     */
    lang?: string;

    /**
     * Opening mode
     */
    body?: PostgresModelsSetShiftModeData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderSetShiftModePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the opening mode of the shift MU.
   *
   * ### Claim
   *  boservice.provider.setshiftmode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderSetShiftModePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderSetShiftModePost(params?: {

    /**
     * Data language
     */
    lang?: string;

    /**
     * Opening mode
     */
    body?: PostgresModelsSetShiftModeData
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderSetShiftModePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderPrvDomainListGet
   */
  static readonly ApiProviderPrvDomainListGetPath = '/api/Provider/PrvDomainList';

  /**
   * Getting a list of areas of control available to the Provider.
   *
   * ### Claim
   *  boservice.provider.prvdomainlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPrvDomainListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvDomainListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * service id/null
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderApiContractPrvDomainListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPrvDomainListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderApiContractPrvDomainListResponse>>;
      })
    );
  }

  /**
   * Getting a list of areas of control available to the Provider.
   *
   * ### Claim
   *  boservice.provider.prvdomainlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPrvDomainListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvDomainListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * service id/null
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderApiContractPrvDomainListResponse>> {

    return this.apiProviderPrvDomainListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderApiContractPrvDomainListResponse>>) => r.body as Array<ProviderApiContractPrvDomainListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderPrvPlaceSetPost
   */
  static readonly ApiProviderPrvPlaceSetPostPath = '/api/Provider/PrvPlaceSet';

  /**
   * Editing the data of the Provider's installation location.
   *
   * ### Claim
   *  boservice.provider.prvplaceset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPrvPlaceSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPrvPlaceSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderApiContractPrvPlaceSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPrvPlaceSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the data of the Provider's installation location.
   *
   * ### Claim
   *  boservice.provider.prvplaceset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPrvPlaceSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPrvPlaceSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderApiContractPrvPlaceSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderPrvPlaceSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProviderPrvServiceListGet
   */
  static readonly ApiProviderPrvServiceListGetPath = '/api/Provider/PrvServiceList';

  /**
   * Getting a list of services and provider routes.
   *
   * ### Claim
   *  boservice.provider.prvservicelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPrvServiceListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvServiceListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ProviderApiContractPrvServiceListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPrvServiceListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderApiContractPrvServiceListResponse>>;
      })
    );
  }

  /**
   * Getting a list of services and provider routes.
   *
   * ### Claim
   *  boservice.provider.prvservicelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPrvServiceListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvServiceListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;
  },
  context?: HttpContext

): Observable<Array<ProviderApiContractPrvServiceListResponse>> {

    return this.apiProviderPrvServiceListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderApiContractPrvServiceListResponse>>) => r.body as Array<ProviderApiContractPrvServiceListResponse>)
    );
  }

  /**
   * Path part for operation apiProviderPrvServiceAddPost
   */
  static readonly ApiProviderPrvServiceAddPostPath = '/api/Provider/PrvServiceAdd';

  /**
   * Adding a link to a service/route.
   *
   * ### Claim
   *  boservice.provider.prvserviceadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPrvServiceAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPrvServiceAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderApiContractPrvServiceAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProviderApiContractPrvServiceAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPrvServiceAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProviderApiContractPrvServiceAddResponse>;
      })
    );
  }

  /**
   * Adding a link to a service/route.
   *
   * ### Claim
   *  boservice.provider.prvserviceadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPrvServiceAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProviderPrvServiceAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ProviderApiContractPrvServiceAddRequest
  },
  context?: HttpContext

): Observable<ProviderApiContractPrvServiceAddResponse> {

    return this.apiProviderPrvServiceAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProviderApiContractPrvServiceAddResponse>) => r.body as ProviderApiContractPrvServiceAddResponse)
    );
  }

  /**
   * Path part for operation apiProviderPrvServiceRemoveDelete
   */
  static readonly ApiProviderPrvServiceRemoveDeletePath = '/api/Provider/PrvServiceRemove';

  /**
   * Removing a link to a service/route.
   *
   * ### Claim
   *  boservice.provider.prvserviceremove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProviderPrvServiceRemoveDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvServiceRemoveDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * record id if null then all provider records are deleted
     */
    psdId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiProviderPrvServiceRemoveDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('psdId', params.psdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a link to a service/route.
   *
   * ### Claim
   *  boservice.provider.prvserviceremove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProviderPrvServiceRemoveDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProviderPrvServiceRemoveDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * provider id
     */
    prvId?: number;

    /**
     * record id if null then all provider records are deleted
     */
    psdId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiProviderPrvServiceRemoveDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesTrmIdProviderGet
   */
  static readonly ApiRoutesTrmIdProviderGetPath = '/api/routes/{trmId}/provider';

  /**
   * Get a list of providers.
   *
   * ### Claim
   *  boservice.routes.listprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdProviderGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdProviderGet$Response(params: {

    /**
     * Transport organizer ID
     */
    trmId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractProvider>>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiRoutesTrmIdProviderGetPath, 'get');
    if (params) {
      rb.path('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractProvider>>;
      })
    );
  }

  /**
   * Get a list of providers.
   *
   * ### Claim
   *  boservice.routes.listprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdProviderGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdProviderGet(params: {

    /**
     * Transport organizer ID
     */
    trmId: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractProvider>> {

    return this.apiRoutesTrmIdProviderGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractProvider>>) => r.body as Array<BoLankaContractProvider>)
    );
  }

  /**
   * Path part for operation apiRoutesTrmIdProviderPost
   */
  static readonly ApiRoutesTrmIdProviderPostPath = '/api/routes/{trmId}/provider';

  /**
   * Adding a provider.
   *
   * ### Claim
   *  boservice.routes.addprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdProviderPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesTrmIdProviderPost$Response(params: {

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddProviderRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractAddProviderResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiRoutesTrmIdProviderPostPath, 'post');
    if (params) {
      rb.path('trmId', params.trmId, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractAddProviderResponse>;
      })
    );
  }

  /**
   * Adding a provider.
   *
   * ### Claim
   *  boservice.routes.addprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdProviderPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesTrmIdProviderPost(params: {

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractAddProviderRequest
  },
  context?: HttpContext

): Observable<BoLankaContractAddProviderResponse> {

    return this.apiRoutesTrmIdProviderPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractAddProviderResponse>) => r.body as BoLankaContractAddProviderResponse)
    );
  }

  /**
   * Path part for operation apiRoutesTrmIdProviderPrvIdPut
   */
  static readonly ApiRoutesTrmIdProviderPrvIdPutPath = '/api/routes/{trmId}/provider/{prvId}';

  /**
   * Updating provider data (only suitable for Lanka).
   *
   * ### Claim
   *  boservice.routes.setprvdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdProviderPrvIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesTrmIdProviderPrvIdPut$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Transportation ID
     */
    trmId: number;

    /**
     * provider ID
     */
    prvId: number;
    body?: BoLankaContractSetPrvDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiRoutesTrmIdProviderPrvIdPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('trmId', params.trmId, {});
      rb.path('prvId', params.prvId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updating provider data (only suitable for Lanka).
   *
   * ### Claim
   *  boservice.routes.setprvdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdProviderPrvIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRoutesTrmIdProviderPrvIdPut(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Transportation ID
     */
    trmId: number;

    /**
     * provider ID
     */
    prvId: number;
    body?: BoLankaContractSetPrvDataRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesTrmIdProviderPrvIdPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoutesTrmIdProviderPrvIdDelete
   */
  static readonly ApiRoutesTrmIdProviderPrvIdDeletePath = '/api/routes/{trmId}/provider/{prvId}';

  /**
   * Deleting a provider.
   *
   * ### Claim
   *  boservice.routes.removeprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdProviderPrvIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdProviderPrvIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderService.ApiRoutesTrmIdProviderPrvIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('trmId', params.trmId, {});
      rb.path('prvId', params.prvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a provider.
   *
   * ### Claim
   *  boservice.routes.removeprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdProviderPrvIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdProviderPrvIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * provider ID
     */
    prvId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiRoutesTrmIdProviderPrvIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
