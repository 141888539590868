// @ts-nocheck
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * @Deprecated
 * use ui-form-input
 * Input
 */
@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 0 })),
      state('false', style({ opacity: 1 })),
      transition('*=>*', animate('300ms')),
    ]),
  ],
})
export class UiInputComponent implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * Attribute ID
   */
  @Input() identifier: string =
    'c__' + Math.random().toString().replace('.', '');
  /**
   * Required flag
   */
  @Input() required: boolean;
  /**
   * Regular Expression Validation Pattern
   */
  @Input() pattern: string | RegExp = null;
  /**
   * Label text
   */
  @Input() label: string;
  /**
   * Field type text | number | password
   */
  @Input() type = 'text';
  /**
   * Placeholder value
   */
  @Input() filler: string;
  /**
   * Disable flag
   */
  @Input() disabledControl: boolean;
  /**
   * Show password eye icon
   */
  @Input() showHideControl: boolean;
  /**
   * Default value
   */
  @Input() default = null;
  /**
   * Description text
   */
  @Input() description: string;
  /**
   * Reactive form control name
   */
  @Input() formControlName: string;
  /**
   * Additional css class
   */
  @Input() className: string;
  /**
   * Additional label css class
   */
  @Input() classLabel: string;
  /**
   * Max-length attribute value
   */
  @Input() maxLength;
  /**
   * Flag "Do not show validation errors"
   */
  @Input() disableErrorMessage;
  /**
   * aria-describedby attribute
   */
  @Input() ariaDescribedby: string;
  /**
   * Form control
   */
  public control: FormControl = null;
  /**
   * Changed control flag
   */
  public changed: boolean;

  private readonly destroy$: Subject<unknown> = new Subject<unknown>();

  @ViewChild('input', { static: true }) inputRef: ElementRef;

  constructor(private injector: Injector, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('disabledControl')) {
      if (
        changes.disabledControl.currentValue !==
        changes.disabledControl.previousValue
      ) {
        if (changes.disabledControl.currentValue) {
          this.inputRef.nativeElement.setAttribute('disabled', 'disabled');
        } else {
          this.inputRef.nativeElement.removeAttribute('disabled', 'disabled');
        }
      }
    }
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get<any>(NgControl as any, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
      // this.control.
      this.control.statusChanges
        .pipe(
          distinctUntilChanged((a, b) => a === b),
          takeUntil(this.destroy$),
        )
        .subscribe((status) => {
          this.cd.markForCheck();
        });
    } else {
      // console.warn('Input is not defined');
    }
  }

  patchValue(value: string): void {
    if (this.inputRef) {
      this.inputRef.nativeElement.value = this.prepareValue(value);
    }
    setTimeout(() => {
      this.cd.markForCheck();
    }, 100);
  }

  onChange(value) {
    this.change(this.prepareValue(value.target.value));
  }

  onBlur(value) {
    this.changed = true;
    this.touchedChange('');
  }

  onKeyUp(value) {
    this.change(value.target.value);
  }

  change(value) {
    this.cd.markForCheck();
    this.propagateChange(value);
  }

  propagateChange = (_: string) => {};

  touchedChange = (_: string) => {};

  writeValue(value: string): void {
    if (value && this.default !== value) {
      this.changed = true;
    }

    this.patchValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedChange = fn;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private prepareValue(value: string | number): string | number {
    if (typeof value === 'string' && this.type === 'text' && this.maxLength) {
      return value.substring(0, this.maxLength);
    } else {
      return value;
    }
  }
}
