<div class="element border-radius-8 overflow-hidden"
     [class.element--interactive]="interactive"
     [class.border-success]="element.isActive && showActive"
     *ngIf="element">

    <div class="element__thumb"
         *ngIf="element.edType !== fileTypes.Mp4">
        <img [attr.src]="element.edData | base64ImgWrap: element.edType | safeSrc" [attr.alt]="element.edId" />
    </div>

    <div class="element__thumb" *ngIf="element?.edType === fileTypes.Mp4">
        <video controls>
            <source [src]="element.edData | base64ImgWrap: element.edType | safeSrc"
                    [type]="element.edType" />

        </video>
    </div>

    <div class="element__tools px-2 py-2" *ngIf="showToolbar">
        <ui-form-checkbox *ngIf="!lyView && showToggle"
                          class="me-auto"
                          [switch]="true"
                          [value]="Boolean(element.isActive)"
                          (changeCheckedValue)="onChangeActivateFlag(element)"
                          [title]="(element.isActive ? 'global.actions.deactivate' : 'global.actions.activate') | translate">

        </ui-form-checkbox>

        <ui-icon [matTooltip]="element.edType + '\n' + (element?.note || '')"
                 [titleText]="'global.labels.info'"
                 fontSize="1.2"
                 type="lnr-question-circle"
                 iconClass="pseudo--gray ms-2">

        </ui-icon>

        <ui-icon *ngIf="!lyView"
                 [titleText]="'global.actions.change'"
                 (handlerClick)="editDesignElement.emit(element)"
                 fontSize="1.2"
                 type="lnr-pencil"
                 iconClass="pseudo--gray ms-2">

        </ui-icon>

        <ui-icon *ngIf="!lyView && !element.isActive"
                 [titleText]="'global.actions.delete'"
                 (handlerClick)="deleteDesignElement.emit(element)"
                 fontSize="1.2"
                 type="trash"
                 iconClass="pseudo--gray ms-2">

        </ui-icon>
    </div>
</div>
