import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'currentProtocol',
    standalone: true,
})
export class CurrentProtocolPipe implements PipeTransform {
    public transform(value: string): string {
        const protocol: string = window.location.protocol || 'http:';

        if (value) {
            return protocol + '//' + value;
        }
    }
}
