import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { StopModel, StopModelId } from '@libs/stops/stop.model';
import { StopsService } from '@libs/stops/stops.service';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LangModel } from '@libs/lang/lang.model';
import { allLangRequiredValidator } from '@libs/functions';
import { MultilangInputModel } from '@ui/multilang-input/multilang-input.model';
import { GoogleMapCoords } from '@libs/interfaces';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MultilangInputModule } from '@ui/multilang-input/multilang-input.module';
import { FormInputComponent } from '@ui/form/form-input/form-input.component';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { ButtonModule } from '@ui/button/button.module';
import { StopFormField, StopFormModel, StopFormService } from './stop-form.service';
import { RxEffects } from '@rx-angular/state/effects';
import { FormSelectOrganizationComponent } from '@ui/form/form-select-organization/form-select-organization.component';
import { IconModule } from '@ui/icon/icon.module';
import { OrganizationModel } from '@libs/organization/organization.model';
import { AddStopPointRequest } from '@api/models/BoLanka/Contract/add-stop-point-request';
import { ExternalCode } from '@api/models/BoLanka/Contract/Routes/external-code';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-stop-form',
    templateUrl: './stop-form.component.html',
    styleUrls: ['./stop-form.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        MultilangInputModule,
        FormInputComponent,
        ButtonRowModule,
        ButtonModule,
        FormSelectOrganizationComponent,
        IconModule,
        NgForOf,
        AsyncPipe,
    ],
    providers: [StopFormService, RxEffects],
})
/**
 * Adding/editing a stop to the directory
 */
export class StopFormComponent implements OnInit, OnChanges {
    @Input() stId: number;
    /**
     * List languages
     */
    @Input() languages: LangModel[];
    /**
     * Server pending flag
     */
    @Input() pending: boolean;
    /**
     * Organizations for externalIds control
     */
    @Input() organizations: OrganizationModel[];
    /**
     * Cancel adding a stop
     *
     * @returns Stop ID value
     */
    @Output() cancel: EventEmitter<StopModelId> = new EventEmitter<StopModelId>();
    /**
     * Editing a stop
     *
     * @returns Form data
     */
    @Output() edit: EventEmitter<AddStopPointRequest> = new EventEmitter<AddStopPointRequest>();
    /**
     * Adding a stop
     *
     * @returns Form data
     */
    @Output() add: EventEmitter<AddStopPointRequest> = new EventEmitter<AddStopPointRequest>();

    readonly #stopFormService: StopFormService = inject(StopFormService);
    readonly #stopsService: StopsService = inject(StopsService);
    readonly #effects: RxEffects = inject(RxEffects);
    /**
     * Form object
     */
    public readonly form: FormGroup<StopFormModel> = this.#stopFormService.createReactiveForm();
    public readonly stopFormField: typeof StopFormField = StopFormField;
    /**
     * Name Multilang patch variable
     */
    public names: MultilangInputModel[];

    get namesControl(): AbstractControl {
        return this.form.get([StopFormField.Names]);
    }

    get externalCodes(): FormArray {
        return this.form.get(StopFormField.ExternalCodes) as FormArray;
    }

    @Input() set stop(stop: StopModel) {
        if (stop) {
            this.form.patchValue(stop);
            this.names = stop.names;
            this.namesControl.clearValidators();

            if (stop.externalCodes.length) {
                stop.externalCodes?.forEach((code: ExternalCode) => {
                    this.#stopFormService.addExternalCode(code);
                });
            }

        } else {
            this.namesControl.setValidators(Validators.required);
        }
    }

    @Input() set patchCoordinates(coord: GoogleMapCoords) {
        if (coord) {
            this.form.patchValue({
                [StopFormField.Lat]: coord.lat,
                [StopFormField.Lon]: coord.lng,
            });
            this.form.markAsDirty();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.languages?.currentValue) {
            this.namesControl.setValidators(
                allLangRequiredValidator(changes.languages.currentValue),
            );
        }
    }

    public ngOnInit(): void {
        this.#effects.register(
            this.#stopsService.outputCoords,
            (coordinates: GoogleMapCoords) => {
                this.form.patchValue({
                    [StopFormField.Lat]: coordinates.lat,
                    [StopFormField.Lon]: coordinates.lng,
                });
                this.form.markAsDirty();
            },
        );

        this.#effects.register(
            this.externalCodes.valueChanges,
            (value: ExternalCode[]) => {
                const selectedIds: number[] = value?.map((extCode: ExternalCode) => extCode.orgId);

                this.organizations = this.organizations?.map(
                    (organization: OrganizationModel) => ({
                        ...organization,
                        ...(selectedIds.includes(organization.orgId) ? { disabled: true } : { disabled: false }),
                    }),
                );
            }
        );
    }

    public reset(): void {
        this.cancel.emit(this.stId);
    }

    public editStop(): void {
        this.edit.emit(this.form.getRawValue());
    }

    public addStop(): void {
        this.add.emit(this.form.getRawValue());
    }

    public addExtCodeForm(): void {
        this.#stopFormService.addExternalCode();
    }

    public removeExtCodeForm(index: number): void {
        this.form.markAsDirty();
        this.#stopFormService.removeExternalCode(index);
    }
}
