<div class="form-group mb-0">
    <label class="d-block"
           *ngIf="label"
           [ngClass]="classLabel"
           [class.mb-1]="!description"
           [class.mb-0]="description"
           [attr.for]="identifier">{{ label | translate }}<sup *ngIf="required">*</sup>

    </label>

    <div class="description mb-1"
         *ngIf="description"
         [innerHTML]="description | translate">

    </div>

    <input
        class="form-control"
        #input
        [ngClass]="className ? className : ''"
        [attr.aria-describedby]="ariaDescribedby ? ariaDescribedby : null"
        [attr.maxLength]="maxLength ? maxLength : null"
        [attr.id]="identifier"
        [attr.placeholder]="filler | translate"
        [attr.type]="type"
        [pattern]="pattern"
        [class.is-invalid]="control?.touched && !control?.pristine && control?.invalid"
        [class.is-valid]="changed && control?.valid"
        [class.pe-7]="showHideControl"
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        (keyup)="onKeyUp($event)"
    />
    <div class="password-checker" *ngIf="showHideControl">
        <span class="pseudo pseudo--gray pseudo--border-off icon-eye-off"
              *ngIf="type === 'password'"
              (click)="type = 'text'">

        </span>
        <span class="pseudo pseudo--gray pseudo--border-off icon-eye"
              *ngIf="type === 'text'"
              (click)="type = 'password'">

        </span>
    </div>

    <div class="invalid-feedback"
         *ngIf="!disableErrorMessage"
         [@visibilityChanged]="changed && control?.valid">
        <span class="invalid-feedback-box">{{ (control?.errors | inputValidation: formControlName) | translate }}</span>
    </div>
</div>
