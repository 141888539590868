import { EMPTY, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { StartWithUnit } from './start-with-unit';
import { finalize } from 'rxjs/operators';


export function startWithLoading<T, V extends { loading: Record<StartWithUnit, boolean> }>(
    state: RxState<V>,
    unit: StartWithUnit,
): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
        if (state.get().loading?.[unit]) {
            return EMPTY;
        }

        state.set({ loading: { [unit]: true } } as V);

        return source.pipe(
            finalize(() => state.set({ loading: { [unit]: false } } as V)),
        );
    };
}
