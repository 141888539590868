export enum StartWithUnit {
    /** B2b */
    RequestBatches,
    RequestAddPrintedTicketBatch,
    RequestRemovePrintedTicketBatch,
    RequestB2BPrograms,
    RequestAddB2BProgram,
    RequestUpdateB2BProgram,
    RequestRemoveB2BProgram,
    RequestB2BServices,
    RequestB2BProgramLoads,
    RequestB2BProgramUploadFile,
    RequestSearchInB2BLoads,
    RequestB2BProgramParticipants,
    B2BAddRider,
    /** Events */
    RequestEvents,
    RequestGetDataEvent,
    RequestAddEvent,
    RequestDeleteEvent,
    RequestEventListPackages,
    RequestInventoryListPackages,
    RequestInventoryAddPackage,
    RequestInventoryRepackPackage,
    RequestInventoryDeletePackage,
    RequestUnlinkPackageFromEvent,
    RequestExportEventListId,
    /** MonTech */
    RequestMonTechValidators,
    /** Ref */
    RequestDocTypes,
    RequestAddDocType,
    RequestDeleteDocType,
    /** Load */
    RequestLoadHistory,
    RequestUploadFile,
    /** Agents */
    RequestAgentAdd,
    RequestAgentDelete,
    RequestAgentDepartmentAdd,
    RequestAgentDepartmentDelete,
    RequestAgentUploadCards,
    RequestAddFundTransfer,
    RequestSetStatusFundTransfer,
    RequestFundsTransfers,
    /* Refs */
    RequestLocations,
    AddLocation,
    EditLocationShiftMode,
    AddValidatorToLocation,
    RemoveValidatorFromLocation,
    CloseValidatorShift,
    RemoveLocation,
    RemoveProvider,
    AddProvider,
    SetProviderRate,
    SetProviderInsurance,
    AddProviderDepartment,
    RemoveProviderDepartment,
    AddProviderValidators,
    RemoveProviderValidators,
    AddProviderService,
    RemoveProviderService,
    /* SysAdm */
    AddOrEditDesignElementsVersion,
    RemoveDesignElementsVersion,
    AddOrEditDesignElement,
    RemoveDesignElement,
    RequestDesignElementList,
    AddBanner,
    RemoveBanner,
}
