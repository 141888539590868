import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { CustomRxState } from '@core/state/rx.state';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { Observable } from 'rxjs';
import { PrvListResponse } from '@api/models/ProviderEditorApi/Contract/prv-list-response';
import { ProviderEditorService } from '@api/services/provider-editor.service';
import { tap } from 'rxjs/operators';
import { startWithLoading } from '@core/operators/start-with-loading';
import { PrvSetRequest } from '@api/models/ProviderEditorApi/Contract/prv-set-request';
import { PrvAddRequest } from '@api/models/ProviderEditorApi/Contract/prv-add-request';
import { PrvAddResponse } from '@api/models/ProviderEditorApi/Contract/prv-add-response';
import { PrvCaListResponse } from '@api/models/ProviderEditorApi/Contract/prv-ca-list-response';
import { PrvRateListResponse } from '@api/models/ProviderEditorApi/Contract/prv-rate-list-response';
import { PrvRateSetRequest } from '@api/models/ProviderEditorApi/Contract/prv-rate-set-request';
import { PrvInsurListResponse } from '@api/models/ProviderEditorApi/Contract/prv-insur-list-response';
import { PrvInsurSetRequest } from '@api/models/ProviderEditorApi/Contract/prv-insur-set-request';
import { startWithErrors } from '@core/operators/start-with-errors';
import { ErrorResponse } from '@core/api/models/error-response.model';
import { PrvDeptListResponse } from '@api/models/ProviderEditorApi/Contract/prv-dept-list-response';
import { PrvDeptAddRequest } from '@api/models/ProviderEditorApi/Contract/prv-dept-add-request';
import { PrvDeptAddResponse } from '@api/models/ProviderEditorApi/Contract/prv-dept-add-response';
import { PrvDeptSetRequest } from '@api/models/ProviderEditorApi/Contract/prv-dept-set-request';
import { PrvValidatorListResponse } from '@api/models/ProviderEditorApi/Contract/prv-validator-list-response';
import { PrvValidatorAddRequest } from '@api/models/ProviderEditorApi/Contract/prv-validator-add-request';
import { PrvServiceListResponse } from '@api/models/ProviderApi/Contract/prv-service-list-response';
import { ProviderService } from '@api/services/provider.service';
import { PrvServiceAddRequest } from '@api/models/ProviderApi/Contract/prv-service-add-request';
import { PrvServiceAddResponse } from '@api/models/ProviderApi/Contract/prv-service-add-response';


interface IProvidersState {
    loading: Record<StartWithUnit, boolean>;
    errors: Record<StartWithUnit, ErrorResponse>;
    providers: PrvListResponse[];
    providerRates: PrvRateListResponse[];
    providerInsurances: PrvInsurListResponse[];
    providerDepartments: PrvDeptListResponse[];
    providerValidators: PrvValidatorListResponse[];
    providerServices: PrvServiceListResponse[];
}

interface ProvidersActions {
    requestProviders: PrvListResponse[];
    requestProviderRates: PrvRateListResponse[];
    requestProviderInsurances: PrvInsurListResponse[];
    requestProviderDepartments: PrvDeptListResponse[];
    requestProviderValidators: PrvValidatorListResponse[];
    requestProviderServices: PrvServiceListResponse[];
}

@Injectable()
export class ProvidersState extends CustomRxState<IProvidersState> {
    readonly #providerEditorService: ProviderEditorService = inject(ProviderEditorService);
    readonly #providerService: ProviderService = inject(ProviderService);
    readonly #actions: RxActions<ProvidersActions> = new RxActionFactory<ProvidersActions>().create();

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly errors$: Observable<Record<StartWithUnit, ErrorResponse>> = this.select('errors');
    public readonly providers$: Observable<PrvListResponse[]> = this.select('providers');
    public readonly providerRates$: Observable<PrvRateListResponse[]> = this.select('providerRates');
    public readonly providerInsurances$: Observable<PrvInsurListResponse[]> = this.select('providerInsurances');
    public readonly providerDepartments$: Observable<PrvDeptListResponse[]> = this.select('providerDepartments');
    public readonly providerValidators$: Observable<PrvValidatorListResponse[]> = this.select('providerValidators');
    public readonly providerServices$: Observable<PrvServiceListResponse[]> = this.select('providerServices');

    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.requestProviders();
    }

    public requestProviders(): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvListGet(),
            this.#actions.requestProviders,
        );
    }

    public requestProviderRates(prvId: number): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvRateListGet({ prvId }),
            this.#actions.requestProviderRates,
        );
    }

    public requestProviderInsurances(prvId: number): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvInsurListGet({ prvId }),
            this.#actions.requestProviderInsurances,
        );
    }

    public requestProviderDepartments(prvId: number): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvDeptListGet({ prvId }),
            this.#actions.requestProviderDepartments,
        );
    }

    public requestProviderValidators(prvId: number, storage: 0 | 1): void {
        this.hold(
            this.#providerEditorService.apiProviderEditorPrvValidatorListGet({ prvId, storage }),
            this.#actions.requestProviderValidators,
        );
    }

    public requestProviderServices(prvId: number): void {
        this.hold(
            this.#providerService.apiProviderPrvServiceListGet({ prvId }),
            this.#actions.requestProviderServices,
        );
    }

    public createProvider(body: PrvAddRequest): Observable<PrvAddResponse> {
        return this.#providerEditorService.apiProviderEditorPrvAddPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProvider),
            tap(() => this.requestProviders()),
        );
    }

    public editProvider(body: PrvSetRequest): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvSetPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProvider),
            tap(() => this.requestProviders()),
        );
    }

    public removeProvider(prvId: number): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvRemoveDelete({
            prvId,
        }).pipe(
            startWithLoading(this, StartWithUnit.RemoveProvider),
            tap(() => this.requestProviders()),
        );
    }

    public getCustomProviderFields(prvId: number): Observable<PrvCaListResponse[]> {
        return this.#providerEditorService.apiProviderEditorPrvCaListGet({
            prvId,
        });
    }

    public setProviderRatePeriod(body: PrvRateSetRequest): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvRateSetPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.SetProviderRate),
            startWithErrors(this, StartWithUnit.SetProviderRate),
            tap(() => this.requestProviderRates(body.prvId)),
        );
    }

    public setProviderInsurancePeriod(body: PrvInsurSetRequest): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvInsurSetPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.SetProviderInsurance),
            startWithErrors(this, StartWithUnit.SetProviderInsurance),
            tap(() => this.requestProviderInsurances(body.prvId)),
        );
    }

    public addProviderDepartment(body: PrvDeptAddRequest): Observable<PrvDeptAddResponse> {
        return this.#providerEditorService.apiProviderEditorPrvDeptAddPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProviderDepartment),
            tap(() => this.requestProviderDepartments(body.prvId)),
        );
    }

    public editProviderDepartment(body: PrvDeptSetRequest, prvId: number): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvDeptSetPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProviderDepartment),
            tap(() => this.requestProviderDepartments(prvId)),
        );
    }

    public removeProviderDepartment(prdtId: number, prvId: number): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvDeptRemoveDelete({
            prdtId,
        }).pipe(
            startWithLoading(this, StartWithUnit.RemoveProviderDepartment),
            tap(() => this.requestProviderDepartments(prvId)),
        );
    }

    public addProviderValidators(body: PrvValidatorAddRequest): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvValidatorAddPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProviderValidators),
            tap(() => this.requestProviderValidators(body.prvId, 1)),
        );
    }

    public removeProviderValidators(prvId: number, vlNFirst: number, vlNLast: number): Observable<void> {
        return this.#providerEditorService.apiProviderEditorPrvValidatorRemoveDelete({
            prvId, vlNFirst, vlNLast,
        }).pipe(
            startWithLoading(this, StartWithUnit.RemoveProviderValidators),
            tap(() => this.requestProviderValidators(prvId, 1)),
        );
    }

    public addProviderService(body: PrvServiceAddRequest): Observable<PrvServiceAddResponse> {
        return this.#providerService.apiProviderPrvServiceAddPost({
            body,
        }).pipe(
            startWithLoading(this, StartWithUnit.AddProviderService),
            tap(() => this.requestProviderServices(body.prvId)),
        );
    }

    public removeProviderService(prvId?: number, psdId?: number): Observable<void> {
        return this.#providerService.apiProviderPrvServiceRemoveDelete({
            prvId, psdId,
        }).pipe(
            startWithLoading(this, StartWithUnit.RemoveProviderService),
            tap(() => this.requestProviderServices(prvId)),
        );
    }

    private connectSelectors(): void {
        this.connect('providers', this.#actions.requestProviders$);
        this.connect('providerRates', this.#actions.requestProviderRates$);
        this.connect('providerInsurances', this.#actions.requestProviderInsurances$);
        this.connect('providerDepartments', this.#actions.requestProviderDepartments$);
        this.connect('providerValidators', this.#actions.requestProviderValidators$);
        this.connect('providerServices', this.#actions.requestProviderServices$);
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            errors: null,
            providers: null,
            providerRates: null,
            providerInsurances: null,
            providerDepartments: null,
            providerValidators: null,
            providerServices: null,
        });
    }
}
