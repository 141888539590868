<form class="row" [formGroup]="form">
  <div class="col-12">
    <h2 class="mb-5" *ngIf="zoneId !== null">
      {{ 'global.actions.edit' | translate }}
    </h2>
    <h2 class="mb-2" *ngIf="zoneId === null">
      {{ 'containers.zone-form.head' | translate }}
    </h2>
    <ui-multilang-input
      [label]="'global.forms.labels.name'"
      [languages]="languages"
      [formControlName]="'names'"
      [patch]="names"
      [required]="true"
    ></ui-multilang-input>
    <ui-input
      class="mb-5"
      [type]="'number'"
      [label]="'global.labels.number'"
      [formControlName]="'zoneNum'"
    ></ui-input>
  </div>
  <div class="col-12">
    <ui-button-row>
      <ui-button
        *ngIf="zoneId !== null"
        [disabled]="form?.status === 'INVALID' || pending || !form.dirty"
        [label]="'global.labels.save'"
        (handlerClick)="handlerEditStop()"
      ></ui-button>
      <ui-button
        *ngIf="zoneId === null"
        [disabled]="form?.status === 'INVALID' || pending"
        [label]="'global.actions.add'"
        (handlerClick)="handlerAddStop()"
      ></ui-button>
      <ui-button
        [disabled]="pending"
        [label]="'global.labels.cancel'"
        (handlerClick)="handlerReset()"
        type="outline"
      ></ui-button>
    </ui-button-row>
  </div>
</form>
