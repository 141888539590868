import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangCheckerComponent } from './lang-checker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [LangCheckerComponent],
    imports: [CommonModule, NgSelectModule, FormsModule],
    exports: [LangCheckerComponent],
})
export class LangCheckerModule {
}
