import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { StopsService } from '@libs/stops/stops.service';
import { Observable, Subject } from 'rxjs';
import { StopModel, StopModelId } from '@libs/stops/stop.model';
import { LangModel } from '@libs/lang/lang.model';
import { LangService } from '@libs/lang/lang.service';
import { GOOGLE_MAPS_STOP_DETAIL_ZOOM } from '@libs/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleMapCoords } from '@libs/interfaces';
import { AddStopPointRequest } from '@api/models/BoLanka/Contract/add-stop-point-request';
import { PushCodeModel } from '@core/push/push-code.model';
import { RoutesService } from '@services/routes/routes.service';
import { OrganizationModel } from '@libs/organization/organization.model';
import { PushService } from '@core/push/push.service';


/**
 * Form stop
 */
@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-edit-stop',
    templateUrl: './popup-edit-stop.component.html',
    styleUrls: ['./popup-edit-stop.component.scss'],
})
export class PopupEditStopComponent {
    readonly #routesService: RoutesService = inject(RoutesService);
    readonly #push: PushService = inject(PushService);

    public stId: StopModelId = +this.data.params?.serviceParams?.stId;
    public stop$: Observable<StopModel> = this.stopsService.getById(this.stId);
    public languages$: Observable<LangModel[]> = this.langService.languages;
    public patchCoord$: Subject<GoogleMapCoords> = new Subject<GoogleMapCoords>();

    public zoom: number = GOOGLE_MAPS_STOP_DETAIL_ZOOM;

    public readonly organizations$: Observable<OrganizationModel[]> = this.#routesService.organisationsForExternalIds;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupEditStopComponent>,
        private stopsService: StopsService,
        private langService: LangService,
    ) {
    }

    public handlerEditStop(body: AddStopPointRequest): void {
        this.stopsService
            .update(this.stId, body)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessChange);
                this.dialogRef.close();
            });
    }

    public patchCoord(coord: GoogleMapCoords): void {
        this.patchCoord$.next(coord);
    }

    public handlerCancel(): void {
        this.dialogRef.close();
    }
}
