import { Injectable } from '@angular/core';
import { TariffServiceModelId } from '../tariff-service/tariff-service.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  GetPriceZoneBodyModel,
  GetPriceZoneModel,
  PriceZoneBodyModel,
  TariffZoneBody,
  TariffZoneModel,
  TariffZoneModelId,
} from './tariff-zone.model';
import { catchError, finalize, repeatWhen, tap } from 'rxjs/operators';
import { CacheService } from '../cache/cache.service';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { ErrorResponseModel } from '@libs/error/error.model';

/**
 * Zone service
 */
@Injectable({
  providedIn: 'root',
})
export class TariffZoneService {
  private pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private readonly refresh$: Subject<unknown> = new Subject();

  constructor(
    private cache: CacheService,
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
  ) {}

  get pending(): Observable<boolean> {
    return this.pending$;
  }

  /**
   * List zone by service Id
   *
   * @param srvId - Service Id
   */
  getByServiceId(srvId: TariffServiceModelId): Observable<TariffZoneModel[]> {
    if (!srvId) {
      return of(null);
    }

    const cacheKey = srvId.toString();
    if (this.cache.getItem('tariffZones', cacheKey)) {
      return of(this.cache.getItem('tariffZones', cacheKey));
    } else {
      return this.http
        .get<TariffZoneModel[]>(
          this.apiConfigService.getMethodUrl('boservice.tariff.listzones'),
          {
            params: {
              srvId: cacheKey,
            },
          },
        )
        .pipe(
          repeatWhen(() => this.refresh$),
          tap((response) =>
            this.cache.setItem('tariffZones', cacheKey, response),
          ),
          catchError((error) => of(null)),
        );
    }
  }

  /**
   * Set zone price
   *
   * @param params - price object PriceZoneBodyModel
   */
  setZonePrice(params: PriceZoneBodyModel): Observable<any> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.tariff.setpricezone'),
        params,
      )
      .pipe(
        tap(() => this.refresh$.next(null)),
        finalize(() => this.pending$.next(false)),
        catchError((error) => of(null)),
      );
  }

  /**
   * Returns a list of prices for travel between zones
   * Used to compile the zone matrix in the zone tariff detail page
   *
   * @param params
   */
  getZonePrice(params: GetPriceZoneBodyModel): Observable<GetPriceZoneModel[]> {
    if (!params.trfId) {
      return of(null);
    }
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.tariff.listpricezone'),
        params,
      )
      .pipe(
        repeatWhen(() => this.refresh$),
        catchError((error) => of(null)),
      );
  }

  /**
   * Delete zone
   *
   * @param zoneId
   */
  delete(zoneId: TariffZoneModelId): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .delete(this.apiConfigService.getMethodUrl('boservice.routes.delzone'), {
        params: {
          zoneId,
        },
      })
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }
  /**
   * Add new zone
   *
   * @param body
   */
  add(body: TariffZoneBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.routes.addzone'),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }
  /**
   * Get detail zone data
   *
   * @param zoneId - Zone id
   */
  getById(zoneId: number): Observable<TariffZoneModel> {
    this.pending$.next(true);
    return this.http
      .get(this.apiConfigService.getMethodUrl('boservice.routes.getzone'), {
        params: {
          zoneId,
        },
      })
      .pipe(
        finalize(() => this.pending$.next(false)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      ) as Observable<TariffZoneModel>;
  }
  /**
   * Edit zone data
   *
   * @param body - Changed zone data
   */
  edit(body: TariffZoneBody): Observable<any> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.routes.setzone'),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }
  /**
   * Error handler
   *
   * @param error - error object
   * @private
   */
  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }
}
