import { Injectable } from '@angular/core';
import { LangService } from '@libs/lang/lang.service';
import { DEFAULT_LANG, TIME_FORMAT } from '@libs/constants';
import { TimeFormatModel } from '@libs/time-format/time-format.model';
import * as moment from 'moment';

/**
 * Time format service
 */
@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {
  private readonly meridianLangArray = ['en-us'];

  constructor(private lanService: LangService) {}

  get format(): TimeFormatModel {
    return {
      momentJsFormat: TIME_FORMAT.hasOwnProperty(
        this.lanService.currentLangCode.toLowerCase(),
      )
        ? // @ts-ignore
          TIME_FORMAT[this.lanService.currentLangCode.toLowerCase()]
        : TIME_FORMAT[DEFAULT_LANG],
      showMeridian:
        this.meridianLangArray.indexOf(
          this.lanService.currentLangCode.toLowerCase(),
        ) > -1,
    };
  }

  static convertTimeToSeconds(date: Date): number {
    return moment
      .duration(date.getHours() + ':' + date.getMinutes())
      .asSeconds();
  }
}
