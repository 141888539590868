import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MultilangInputModel } from '@ui/multilang-input/multilang-input.model';
import { AbstractForm } from '@core/abstract/form';
import { ExternalCode } from '@api/models/BoLanka/Contract/Routes/external-code';


export enum StopFormField {
    Lon = 'lon',
    Lat = 'lat',
    Code = 'code',
    Names = 'names',
    ExternalCodes = 'externalCodes',
    OrgId = 'orgId',
    ExtCode = 'extCode',
}

export interface StopExternalCodesFormModel {
    [StopFormField.OrgId]: FormControl<number>;
    [StopFormField.ExtCode]: FormControl<string>;
}

export interface StopFormModel {
    [StopFormField.Lon]: FormControl<number>;
    [StopFormField.Lat]: FormControl<number>;
    [StopFormField.Code]: FormControl<string>;
    [StopFormField.Names]: FormControl<MultilangInputModel[]>;
    [StopFormField.ExternalCodes]: FormArray<FormGroup<StopExternalCodesFormModel>>;
}

@Injectable()
export class StopFormService extends AbstractForm<StopFormField, StopFormModel> {

    public createReactiveForm(): FormGroup<StopFormModel> {
        this.build({
            [StopFormField.Lon]: new FormControl<number>(null, [Validators.required]),
            [StopFormField.Lat]: new FormControl<number>(null, [Validators.required]),
            [StopFormField.Code]: new FormControl<string>(null, [Validators.maxLength(10)]),
            [StopFormField.Names]: new FormControl<MultilangInputModel[]>(null, [Validators.required]),
            [StopFormField.ExternalCodes]: new FormArray<FormGroup<StopExternalCodesFormModel>>([]),
        });

        return this.form;
    }

    public addExternalCode(data?: ExternalCode): void {
        const externalCodeForm: FormGroup<StopExternalCodesFormModel> = new FormGroup<StopExternalCodesFormModel>({
            [StopFormField.OrgId]: new FormControl<number>(data?.[StopFormField.OrgId] || null, [Validators.required]),
            [StopFormField.ExtCode]: new FormControl<string>(data?.[StopFormField.ExtCode] || null, [Validators.required, Validators.maxLength(100)]),
        });

        this.getControlByPath<FormArray>([StopFormField.ExternalCodes])
            .push(externalCodeForm);
    }

    public removeExternalCode(index: number): void {
        this.getControlByPath<FormArray>([StopFormField.ExternalCodes])
            .removeAt(index);
    }
}
