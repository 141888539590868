import { Injectable } from '@angular/core';
import { LangModel } from './lang.model';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import { filter, publishReplay, refCount, repeatWhen } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG, TIME_FORMAT } from '../constants';
import { SettingsService } from '@libs/settings';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { StorageKeys } from '@core/storage/storage.keys';
import { RouteEntity } from '@core/router/route.entity';


export type SystemLangCode = 'en-gb' & 'ru-ru' & 'es-es' & 'en-us' & 'hu-hu';

/**
 * System language service
 */
@Injectable({
    providedIn: 'root',
})
export class LangService {
    readonly #version: string = 'Roaming';
    #lang: LangModel;

    readonly #repeaterRequest$: Subject<any> = new Subject();

    readonly #getRequestLanguages: Observable<LangModel[]> = this.http
        .get<LangModel[]>(
            this.apiConfigService.getUrl(['ListLanguages'], this.#version),
        )
        .pipe(
            repeatWhen(() => this.#repeaterRequest$),
            filter((response: LangModel[]) => !!response),
            publishReplay(1),
            refCount(),
        ) as ConnectableObservable<LangModel[]>;

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
        private translateService: TranslateService,
        private settingsService: SettingsService,
        private cookieService: CookieService,
    ) {
    }

    /**
     * Get the current value of the system language code from memory
     */
    public get currentLangCode(): string {
        if (!this.#lang) {
            window.console.warn('Languages not load from ngx-translate');
        }
        return this.#lang ? this.#lang.code : DEFAULT_LANG.toUpperCase();
    }

    /**
     * Get the current value of the time display format according to the language
     */
    public get timeFormat(): string {
        if (!this.#lang) {
            // @ts-ignore
            return TIME_FORMAT[DEFAULT_LANG];
        }
        // @ts-ignore
        return TIME_FORMAT.hasOwnProperty(this.currentLangCode.toLowerCase())
            ? // @ts-ignore
            TIME_FORMAT[this.currentLangCode.toLowerCase()]
            : // @ts-ignore
            TIME_FORMAT[DEFAULT_LANG];
    }

    /**
     * Get the Get parameter string for the request to the server
     */
    public get langForRequest(): string {
        return '?lang=' + this.currentLangCode;
    }

    /**
     * Get a list of system languages
     */
    public get languages(): Observable<LangModel[]> {
        return this.#getRequestLanguages;
    }

    /**
     * Get current system language value from memory
     */
    public get currentLang(): LangModel {
        return this.#lang;
    }

    /**
     * Set the current value of the system language to memory and cookie
     */
    public set currentLang(lang: LangModel) {
        this.#lang = lang;

        const cookieDomain: string = environment.localSettings
            ? null
            : this.settingsService.settings.APP_SETTINGS_BASE_DOMAIN;
        this.cookieService.set(
            StorageKeys.CookieLang,
            this.currentLang.code,
            null,
            RouteEntity.Root,
            cookieDomain,
        );
    }

    /**
     *Initialize system language setting
     * Either from the cookie value or the first default
     */
    public initLang(): void {
        this.languages.subscribe((languages) => {
            const cookieLang: string = this.cookieService.get(StorageKeys.CookieLang);
            const cookieLangInDb: LangModel = languages?.find((lang: LangModel) => lang.code === cookieLang);

            this.currentLang =
                cookieLang && cookieLangInDb ? cookieLangInDb : languages[0];

            this.translateService.setDefaultLang(DEFAULT_LANG);
            this.translateService.use(this.currentLangCode.toLowerCase());
        });
    }
}
