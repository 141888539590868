import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SdIdType } from '@services/routes/routes.model';
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  repeatWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  RouteStopBody,
  RouteStopDirectionType,
  RouteStopModel,
  RouteStopVersionType,
} from '@services/route-stops/route-stops.model';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';
import { ErrorResponseModel } from '@libs/error/error.model';
import { RoutesService } from '@services/routes/routes.service';
import { isNil, isNumber, map as mapLodash, omitBy } from "lodash-es";

/**
 * Stop by route management service
 */
@Injectable({
  providedIn: 'root',
})
export class RouteStopsService {
  private pending$: Subject<boolean> = new Subject();
  private refresh$: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
    private routeService: RoutesService,
  ) {}

  get pending(): Observable<boolean> {
    return this.pending$;
  }
  /**
   * List stop on route
   *
   * @param sdId - Route Id
   * @param version - route version
   * @param direction - route direction
   */
  list(
    sdId: SdIdType,
    version?: RouteStopVersionType,
    direction?: RouteStopDirectionType,
  ): Observable<RouteStopModel[]> {
    return this.routeService.getById(sdId).pipe(
      switchMap((route) =>
        this.http
          .get<RouteStopModel[]>(
            this.apiConfigService.getMethodUrl(
              'boservice.routes.listservicedetailstp',
            ),
            {
              params: {
                ...{ sdId },
                ...(isNumber(direction) ? { direction } : {}),
                ...(isNumber(version) ? { version } : {}),
              },
            },
          )
          .pipe(
            /** Copy current version stops, for added new version */
            map((items) => [
              ...items,
              ...(!route.nextDate
                ? mapLodash(items, (item) => ({ ...item, ...{ version: 1 } }))
                : []),
            ]),
          ),
      ),
      repeatWhen(() => this.refresh$),
      publishReplay(),
      refCount(),
    );
  }

  /**
   * Add stop point on route
   *
   * @param body
   */
  add(body: RouteStopBody): Observable<any> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.routes.addservicedetailstp',
        ),
        omitBy(body, isNil),
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Edit stop point on route
   */
  edit(body: RouteStopBody): Observable<any> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.routes.setservicedetailstp',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Delete stop from route
   *
   * @param stpId - Stop Id
   * @param nextDate - Stop next version date
   */
  delete(stpId: number, nextDate: string): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .delete(
        this.apiConfigService.getMethodUrl(
          'boservice.routes.removeservicedetailstp',
        ),
        {
          params: {
            stpId,
            nextDate,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap(() => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }
}
