import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { JwtService } from './jwt.service';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { LOCAL_LOGIN_URL } from '../constants';
import { SettingsService } from '@libs/settings';
import { environment } from '@environments/environment';
import { LangService } from '@libs/lang/lang.service';
import { RouteEntity } from '@core/router/route.entity';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
    excludeUrl = ['/api/Roaming/ListLanguages', '/api/Login/GetUserData'];

    constructor(
        private jwtService: JwtService,
        private langService: LangService,
        private errorService: ErrorService,
        private settingsService: SettingsService,
        @Inject('window') private window: Window,
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf('.json') === -1) {
            const token = this.jwtService.accessToken;
            const clone = req.clone({
                ...(this.excludeUrl.indexOf(req.url) === -1
                    ? {
                        url: req.url + this.langService.langForRequest,
                    }
                    : {}),
                ...(token
                    ? {
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: token,
                        },
                    }
                    : null),
            });
            return next.handle(clone).pipe(this.errorHandler());
        } else {
            return next.handle(req);
        }
    }

    errorHandler(): any {
        return catchError((error: HttpErrorResponse) => {
            switch (error.status.toString()) {
                case '401':
                    if (error.url.indexOf('/api/Login/GetUserData') === -1) {
                        this.jwtService.accessToken = null;
                        setTimeout(() =>
                            this.window.location.assign(
                                environment.localSettings
                                    ? LOCAL_LOGIN_URL
                                    : this.settingsService.baseUrl + RouteEntity.Root + RouteEntity.Login,
                            ),
                        );
                    }
                    break;
                case '400':
                case '504':
                case '502':
                case '503':
                case '500':
                    if (!error.url.includes('/api/EventModuleApi/EvtTagList')) {
                        this.errorService.show(typeof error.error === 'string' ? JSON.parse(error.error) : error.error );
                    }
                    break;
                default:
                    break;
            }
            return throwError(error);
        });
    }
}
