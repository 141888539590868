import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { SpecificValidityPeriodService } from '@libs/specific-validity-period/specific-validity-period.service';
import { Observable } from 'rxjs';
import {
    TariffSpecificValidityPeriodIntervalBody,
} from '@libs/specific-validity-period/specific-validity-period.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-add-specific-period-interval',
    templateUrl: './popup-add-specific-period-interval.component.html',
    styleUrls: ['./popup-add-specific-period-interval.component.scss'],
})
export class PopupAddSpecificPeriodIntervalComponent {
    readonly #push: PushService = inject(PushService);

    public pending$: Observable<boolean> = this.specificValidityPeriodService.pending;
    readonly id: number = +this.data.params?.serviceParams?.ttpId;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        public dialogRef: MatDialogRef<PopupAddSpecificPeriodIntervalComponent>,
        private specificValidityPeriodService: SpecificValidityPeriodService,
    ) {
    }

    handlerSubmitForm(body: TariffSpecificValidityPeriodIntervalBody): void {
        this.specificValidityPeriodService
            .addInterval(body)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessAdd);
                this.dialogRef.close();
            });
    }
}
