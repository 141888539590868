import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card.component';
import { UserCardMenuModule } from '../user-card-menu/user-card-menu.module';
import { SafeHtmlPipe } from '@pipes/safe-html/safe-html.pipe';


@NgModule({
    declarations: [UserCardComponent],
    imports: [CommonModule, UserCardMenuModule, SafeHtmlPipe],
    exports: [UserCardComponent],
})
export class UserCardModule {
}
