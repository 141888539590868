import { inject, Injectable } from '@angular/core';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import {
    catchError,
    finalize,
    publishReplay,
    refCount,
    repeatWhen,
    tap,
} from 'rxjs/operators';
import { StopModel, StopModelId } from './stop.model';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { ErrorResponseModel } from '../error/error.model';
import { GoogleMapCoords } from '../interfaces';
import { AbstractListModel } from '@libs/factory/abstract-list/abstract-list.model';
import { AddStopPointRequest } from '@api/models/BoLanka/Contract/add-stop-point-request';
import { RoutesService } from '@api/services/routes.service';

const stopTypes: AbstractListModel[] = [
    {
        id: 0,
        title: 'data.stop-types.0',
    },
    {
        id: 1,
        title: 'data.stop-types.1',
    },
    {
        id: 2,
        title: 'data.stop-types.2',
    },
];

/**
 * Stops service
 */
@Injectable({
    providedIn: 'root',
})
export class StopsService {
    readonly #routesService: RoutesService = inject(RoutesService);
    private readonly pending$: Subject<boolean> = new Subject();
    private readonly refreshStopsData$: Subject<boolean> = new Subject();
    private readonly outputCoords$: Subject<GoogleMapCoords> =
        new Subject<GoogleMapCoords>();

    private readonly requestToGetStops: Observable<StopModel[]> = this.http
        .get<StopModel[]>(
            this.apiConfigService.getMethodUrl('boservice.routes.liststpoints'),
        )
        .pipe(
            repeatWhen(() => this.refreshStopsData$),
            publishReplay(1),
            refCount(),
        ) as ConnectableObservable<StopModel[]>;

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
    ) {
    }

    /**
     * Stop types list
     */
    get stopTypes(): AbstractListModel[] {
        return stopTypes;
    }

    /**
     * Get a progress bar on the server
     */
    get pending(): Observable<boolean> {
        return this.pending$;
    }

    /**
     * Stops list
     */
    get stops(): Observable<StopModel[]> {
        return this.requestToGetStops;
    }

    /**
     * Return changed coordinates from Google Map
     */
    get outputCoords(): Observable<GoogleMapCoords> {
        return this.outputCoords$.asObservable();
    }

    /**
     * Patch coordinates Google Map
     */
    set patchCoords(coords: GoogleMapCoords) {
        this.outputCoords$.next(coords);
    }

    /**
     * Create new stop
     *
     * @param body - stop object
     */
    add(body: AddStopPointRequest): Observable<unknown> {
        this.pending$.next(true);
        return this.#routesService.apiRoutesStopPointPost({body})
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshStopsData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Get stop detail data
     *
     * @param stId - Route id
     */
    getById(stId: number): Observable<StopModel> {
        this.pending$.next(true);
        return this.http
            .get(
                this.apiConfigService.getMethodUrl('boservice.routes.getstoppoint'),
                {
                    params: {
                        stId,
                    },
                },
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            ) as Observable<StopModel>;
    }

    /**
     * Delete stop
     *
     * @param stId - Stop Id
     */
    delete(stId: StopModelId): Observable<unknown> {
        this.pending$.next(true);
        return this.http
            .delete(
                this.apiConfigService.getMethodUrl('boservice.routes.removestoppoint', {
                    stId: stId.toString(),
                }),
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshStopsData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Update stop data
     *
     * @param stId - Stop Id
     * @param data - Stop data
     */
    update(stId: StopModelId, data: AddStopPointRequest): Observable<unknown> {
        data.lon = +data.lon;
        data.lat = +data.lat;
        this.pending$.next(true);
        return this.http
            .put(
                this.apiConfigService.getMethodUrl('boservice.routes.setstoppoint', {
                    stId: stId.toString(),
                }),
                data,
            )
            .pipe(
                finalize(() => this.pending$.next(false)),
                tap((_) => this.refreshStopsData$.next(null)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    private errorHandler(error: ErrorResponseModel): any {
        this.pending$.next(false);
        throw error;
    }
}
