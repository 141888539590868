import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopMapComponent } from './stop-map.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [StopMapComponent],
  exports: [StopMapComponent],
  imports: [CommonModule, AgmCoreModule],
})
export class StopMapModule {}
