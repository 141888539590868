<ui-stop-by-route-form
  class="d-block mt-3"
  [versions]="versions$ | async"
  [sdId]="sdId"
  [stNumber]="+(stopsSelected$ | async)?.length + 1"
  [pending]="pending$ | async"
  [direction]="direction"
  [zones]="zones$ | async"
  [stopTypes]="stopTypes"
  [stops]="stopsForDirection$ | async"
  [stop]="stop ? stop : null"
  (submitForm)="handlerAddStopToRoute($event)"
></ui-stop-by-route-form>
