import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarControlComponent } from './calendar-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [CalendarControlComponent],
  exports: [CalendarControlComponent],
  imports: [CommonModule, TranslateModule, BsDatepickerModule],
})
export class CalendarControlModule {}
