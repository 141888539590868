export enum PrivilegesModules {
    EventsControl = 'bo.events.control',
    EventsPt = 'bo.events.pt',
    AcmanUsers = 'bo.acman.users',
    AcmanOrgRoles = 'bo.acman.orgroles',
    RefsRoutes = 'bo.refs.routes',
    RefsPlaces = 'bo.refs.places',
    RefsValidators = 'bo.refs.validators',
    RefsTariffs = 'bo.refs.tariffs',
    RefsProviders = 'bo.refs.providers',
    RefsPrivcat = 'bo.refs.privcat',
    DiscCoupons = 'bo.disc.coupons',
    ValmanMonitoring = 'bo.valman.monitoring',
    Load = 'bo.load',
    AgentsAgents = 'bo.agents.agents',
    AgentsOrders = 'bo.agents.orders',
    B2bLoad = 'bo.b2b.load',
    B2bRidersSearch = 'bo.b2b.search',
    B2bRiderAdd = 'bo.b2b.rider',
    SysadmParams = 'bo.sysadm.params',
    SysadmAppearance = 'bo.sysadm.appearance',
}
