import { MenuItemsModel } from '@libs/menu/menu.model';
import { RouteEntity } from '@core/router/route.entity';
import { PrivilegesModules } from '@core/privileges/privileges-modules';


export const submenu: MenuItemsModel[] = [
    {
        mdId: 1,
        mdName: 'places.head',
        url: RouteEntity.Root + RouteEntity.Places,
        objName: [PrivilegesModules.RefsPlaces],
    },
    {
        mdId: 2,
        mdName: 'validators.head',
        url: RouteEntity.Root + RouteEntity.Validators,
        objName: [PrivilegesModules.RefsValidators],
    },
    {
        mdId: 3,
        mdName: 'tariffs.head',
        url: RouteEntity.Root + RouteEntity.Tariffs,
        objName: [PrivilegesModules.RefsTariffs],
    },
    {
        mdId: 4,
        mdName: 'routes.head',
        url: RouteEntity.Root + RouteEntity.Routes,
        objName: [PrivilegesModules.RefsRoutes],
    },
    {
        mdId: 6,
        mdName: 'providers.head',
        url: RouteEntity.Root + RouteEntity.Providers,
        objName: [PrivilegesModules.RefsProviders],
    },
    {
        mdId: 7,
        mdName: 'privcats.head',
        url: RouteEntity.Root + RouteEntity.Privcats,
        objName: [PrivilegesModules.RefsPrivcat],
    }
];

export const thirdLevelMenu: { [key: string]: MenuItemsModel[] } = {
    tariffVersion: [
        {
            mdId: 1,
            mdName: 'tariffs.head',
            url: RouteEntity.Root + RouteEntity.Tariffs + '/' + RouteEntity.Fares,
        },
        {
            mdId: 2,
            mdName: 'product-groups.head',
            url:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/' +
                RouteEntity.ProductGroups,
        },
        {
            mdId: 3,
            mdName: 'report-groups.head',
            url:
                RouteEntity.Root + RouteEntity.Tariffs + '/' + RouteEntity.ReportGroups,
        },
        {
            mdId: 4,
            mdName: 'specific-validity-period.head',
            url:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/' +
                RouteEntity.SpecificValidityPeriods,
        },
        {
            mdId: 5,
            mdName: 'specific-dates.head',
            url:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/' +
                RouteEntity.SpecificDates,
        },
    ],
    tariffs: [
        {
            mdId: 1,
            mdName: 'submenu.tariffs.pass',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/' +
                RouteEntity.Pass,
        },
        {
            mdId: 2,
            mdName: 'submenu.tariffs.ticket',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/' +
                RouteEntity.Ticket,
        },
        {
            mdId: 3,
            mdName: 'submenu.tariffs.cap',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/' +
                RouteEntity.Cap,
        },
        {
            mdId: 4,
            mdName: 'submenu.tariffs.domain',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/' +
                RouteEntity.Domain,
        },
    ],
    tariff: [
        {
            mdId: 1,
            mdName: 'global.labels.info',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/form-#pathMode#/',
        },
        {
            mdId: 2,
            mdName: 'submenu.tariff.zoned-price',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/' +
                RouteEntity.ZonePrice,
        },
        {
            mdId: 3,
            mdName: 'submenu.tariff.fare-stage-tariff',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/' +
                RouteEntity.FireStage,
        },
        {
            mdId: 4,
            mdName: 'submenu.tariff.provider-shares',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/' +
                RouteEntity.ProviderShares,
        },
        {
            mdId: 5,
            mdName: 'submenu.tariff.balance-limits',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/' +
                RouteEntity.BalanceLimits,
        },
        {
            mdId: 6,
            mdName: 'submenu.tariff.upcharge',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Tariffs +
                '/#versionId#/#pathMode#/#tariffId#/' +
                RouteEntity.UpCharge,
        },
    ],
    providers: [
        {
            mdId: 1,
            mdName: 'submenu.providers.profile',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Profile,
        },
        {
            mdId: 2,
            mdName: 'submenu.providers.depots',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Depts,
        },
        {
            mdId: 3,
            mdName: 'submenu.providers.services',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Services,
        },
        {
            mdId: 4,
            mdName: 'submenu.providers.fees',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Rate,
        },
        {
            mdId: 5,
            mdName: 'submenu.providers.insurance',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Insurance,
        },
        {
            mdId: 6,
            mdName: 'submenu.providers.validators',
            urlTemplate:
                RouteEntity.Root +
                RouteEntity.Providers +
                '/#providerId#/' +
                RouteEntity.Validators,
        },
    ],
    routes: [
        {
            mdId: 1,
            mdName: 'routes.head',
            url: RouteEntity.Root + RouteEntity.Routes + '/' + RouteEntity.List,
        },
        {
            mdId: 2,
            mdName: 'stops.head',
            url: RouteEntity.Root + RouteEntity.Routes + '/' + RouteEntity.Stops,
        },
        {
            mdId: 3,
            mdName: 'zones.head',
            url: RouteEntity.Root + RouteEntity.Routes + '/' + RouteEntity.Zones,
        },
    ],
    route: [
        {
            mdId: 1,
            mdName: 'global.labels.info',
            urlTemplate:
                RouteEntity.Root + RouteEntity.Routes + '/#sdId#/' + RouteEntity.Common,
        },
        {
            mdId: 2,
            mdName: 'stops.head',
            urlTemplate:
                RouteEntity.Root + RouteEntity.Routes + '/#sdId#/' + RouteEntity.Stops,
        },
        {
            mdId: 3,
            mdName: 'lines.head',
            urlTemplate:
                RouteEntity.Root + RouteEntity.Routes + '/#sdId#/' + RouteEntity.Lines,
        },
    ],
    privilegeCategory: [
        {
            mdId: 7,
            mdName: 'privcats.head',
            url: RouteEntity.Root + RouteEntity.Privcats + '/' + RouteEntity.List,
        },
        {
            mdId: 8,
            mdName: 'docTypes.head',
            url: RouteEntity.Root + RouteEntity.Privcats + '/' + RouteEntity.DocTypes,
        },
    ],
};

