import { Injectable } from '@angular/core';
import {
  CacheItemKeyType,
  CacheModel,
  CacheSectionKeyValue,
} from './cache.model';

/**
 * Cache service
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  /**
   * Keys cache data
   *
   * @private
   */
  private cache: CacheModel = {
    mediumTickets: {},
    agentDetail: {},
    user: {},
    users: {},
    userTypes: {},
    groupTypesByParentId: {},
    priceTypesBySrvId: {},
    tariffDetail: {},
    tariffZones: {},
    places: {},
    discountsPrograms: {},
    groups: {},
    groupTypes: {},
    services: {},
    validators: {},
    depts: {},
    supportClientIds: {},
    supportClientTickets: {},
    runs: {},
    routes: {},
    employers: {},
    ticketDescription: {},
    mediumZones: {},
    routeStops: {},
    systemList: {},
    docTypes: {},
    permitsList: {},
    supportClientDetailTicket: {},
    designElementsList: {},
    specificDateConditionalList: {},
    specificDateConditionalDetail: {},
    monitoringStations: {},
    monitoringScheme: {},
    monitoringStationScheme: {},
    courtesyStId: {},
    courtesySrvId: {},
    courtesySdId: {},
  };

  constructor() {}
  /**
   * Get cache section value
   *
   * @param key - key code of cache section
   */
  section<K extends CacheSectionKeyValue>(key: K): any {
    if (
      this.cache !== undefined &&
      this.cache[key] !== null &&
      this.cache.hasOwnProperty(key)
    ) {
      return this.cache[key];
    }
  }
  /**
   * Get cache item
   *
   * @param key - key code of cache section
   * @param valueKey - key code of cache item
   */
  getItem<K extends CacheSectionKeyValue>(
    key: K,
    valueKey: CacheItemKeyType,
  ): any {
    if (
      this.cache.hasOwnProperty(key) &&
      this.cache[key] !== undefined &&
      this.cache[key] !== null &&
      this.cache[key].hasOwnProperty(valueKey) &&
      this.cache[key][valueKey] !== undefined &&
      this.cache[key][valueKey] !== null
    ) {
      return this.cache[key][valueKey];
    }
  }

  /**
   * Set cache item
   *
   * @param key - key code of cache section
   * @param valueKey - key code of cache item
   * @param value - value cache
   */
  setItem<K extends CacheSectionKeyValue>(
    key: K,
    valueKey: CacheItemKeyType,
    value: any,
  ): void {
    this.cache[key][valueKey] = value;
  }
  /**
   * Delete cache item
   *
   * @param key - key code of cache section
   * @param valueKey - key code of cache item
   */
  removeItem<K extends CacheSectionKeyValue>(
    key: K,
    valueKey: CacheItemKeyType,
  ): void {
    if (
      this.cache.hasOwnProperty(key) &&
      this.cache[key] !== undefined &&
      this.cache[key] !== null &&
      this.cache[key].hasOwnProperty(valueKey)
    ) {
      delete this.cache[key][valueKey];
    }
  }

  /**
   * Clear cache section
   *
   * @param key - key code of cache section
   */
  clearSection<K extends CacheSectionKeyValue>(key: K): any {
    if (
      this.cache !== undefined &&
      this.cache[key] !== null &&
      this.cache.hasOwnProperty(key)
    ) {
      this.cache[key] = {};
    }
  }
}
