import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

export interface AbstractNoteFormBody {
  note?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-form-abstract-note',
  templateUrl: './form-abstract-note.component.html',
  styleUrls: ['./form-abstract-note.component.scss'],
})
export class FormAbstractNoteComponent {
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * Submit form event
   *
   * @returns form data
   */
  @Output() submitForm: EventEmitter<AbstractNoteFormBody> =
    new EventEmitter<AbstractNoteFormBody>();
  /**
   * Cancel click event
   */
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Form object
   */
  readonly form: FormGroup = this.fb.group({
    note: this.fb.control(null, []),
  });

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

  get noteControl(): AbstractControl {
    return this.form.get('note');
  }
  /**
   * Required note flag
   */
  @Input() set noteRequired(flag: boolean) {
    if (flag) {
      this.noteControl.setValidators([Validators.required]);
    } else {
      this.noteControl.clearValidators();
    }
  }

  handlerSubmit(): void {
    this.submitForm.emit(this.form.getRawValue() as AbstractNoteFormBody);
    this.noteControl.clearValidators();
    this.noteControl.reset();
  }
}
