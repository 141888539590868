<h2 class="mb-3" *ngIf="stpIdControl.value !== null">{{ stopName }}</h2>
<form [formGroup]="form">
  <ui-form-group-wrapper
    *ngIf="stpIdControl.value === null"
    [required]="true"
    [label]="'global.labels.station'"
  >
    <ng-select
      [clearAllText]="'global.labels.ng-select.clear' | translate"
      [notFoundText]="'global.labels.no_items_found' | translate"
      [class.ng-select--loading]="!stops"
      [items]="stops"
      [ngClass]="'ng-select--form-control'"
      [formControlName]="'stId'"
      bindLabel="stName"
      bindValue="stId"
    >
    </ng-select>
  </ui-form-group-wrapper>

  <ui-form-group-wrapper
    [required]="true"
    [label]="'global.forms.labels.stationType'"
  >
    <ng-select
      [clearAllText]="'global.labels.ng-select.clear' | translate"
      [notFoundText]="'global.labels.no_items_found' | translate"
      [class.ng-select--loading]="!stopTypes"
      [items]="stopTypes"
      [ngClass]="'ng-select--form-control'"
      [formControlName]="'typ'"
      bindLabel="title"
      bindValue="id"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item?.title | translate }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item?.title | translate }}
      </ng-template>
    </ng-select>
  </ui-form-group-wrapper>

  <ui-form-group-wrapper [label]="'global.forms.labels.zone'">
    <ng-select
      [clearAllText]="'global.labels.ng-select.clear' | translate"
      [notFoundText]="'global.labels.no_items_found' | translate"
      [class.ng-select--loading]="!zones"
      [items]="zones"
      [ngClass]="'ng-select--form-control'"
      [formControlName]="'zoneId'"
      bindLabel="zoneNum"
      bindValue="zoneId"
    >
    </ng-select>
  </ui-form-group-wrapper>

  <ui-input
    class="mb-5"
    [type]="'number'"
    [label]="'global.forms.labels.fareStage'"
    [formControlName]="'fareStage'"
  >
  </ui-input>
  <ui-input
    class="mb-5"
    [type]="'number'"
    [label]="'global.forms.labels.distance'"
    [formControlName]="'distance'"
  >
  </ui-input>

  <ui-button-row>
    <ui-button
      [disabled]="form?.status === 'INVALID' || pending || !form.dirty"
      [label]="
        'global.labels.' + (stpIdControl.value !== null ? 'save' : 'add')
      "
      (handlerClick)="handlerSubmit()"
    >
    </ui-button>
  </ui-button-row>
</form>
