// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlStatus, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { takeUntil } from 'rxjs/operators';

/**
 * Date reactive form control
 * Work by ngx-bootstrap/datepicker
 * https://valor-software.com/ngx-bootstrap/#/datepicker#usage
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-calendar-control',
    templateUrl: './calendar-control.component.html',
    styleUrls: ['./calendar-control.component.scss'],
})
export class CalendarControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
    /**
     * Label text
     */
    @Input() readonly label: string;
    /**
     * Placeholder value
     */
    @Input() readonly placeholder = '';
    /**
     * Min-date
     */
    @Input() readonly minDate: Date;
    /**
     * Max-date
     */
    @Input() readonly maxDate: Date;
    /**
     * Angular reactive form control name
     */
    @Input() readonly formControlName: string;
    /**
     * Disable flag
     */
    @Input() disabled: boolean;
    /**
     * Horizontal align control and label
     */
    @Input() readonly horizontal = false;
    /**
     * #Ref object
     */
    @ViewChild('calendar', { static: true }) inputRef: BsDatepickerDirective;

    private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);
    /**
     * FormControl object
     */
    control: FormControl = null;
    /**
     * Changed flag
     */
    changed: boolean;
    /**
     * Attribute ID
     */
    @Input() identifier: string =
        'cc__' + Math.random().toString().replace('.', '');

    private readonly ngControl: NgControl = inject(NgControl, { self: true, optional: true });
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor() {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public get isRequiredField(): boolean {
        return this.control?.hasValidator(Validators.required);
    }

    ngOnInit(): void {
        this.control = this.ngControl?.control as FormControl;
        this.disabled = this.control.status === 'DISABLED';

        this.control.statusChanges.pipe(
            takeUntil(this.destroy$),
        ).subscribe((status: FormControlStatus) => {
            this.disabled = status === 'DISABLED';
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    onCalendarChange(value: string | Date): void {
        this.changeValue(value);
    }

    onKeyUp(value: Event): void {
        this.changeValue(value.target.value as string | Date);
    }

    changeValue(value: string | Date): void {
        if (value?.getTime && this.control) {
            this.control.setValue(value);
            this.control.markAsDirty();
            this.control.markAsTouched();
            this.cd.markForCheck();
        }
    }

    public writeValue(value: string | Date): void {
        this.inputRef.bsValue = value;
    }

    public registerOnChange(fn: (v: string) => void): void {
    }

    public registerOnTouched(fn: () => void): void {
    }
}
