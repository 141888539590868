<div class="container-fluid bg-brand d-print-none">
  <div class="row">
    <div class="col px-md-8">
      <footer class="d-flex align-items-center flex-wrap flex-row">
        <div class="copyright">&copy;&nbsp;{{ year }}&nbsp;</div>
        <ui-logo class="ms-auto" [logo]="logo"></ui-logo>
      </footer>
    </div>
  </div>
</div>
