import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Row of buttons completing the form
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-button-row',
  templateUrl: './button-row.component.html',
  styleUrls: ['./button-row.component.scss'],
})
export class ButtonRowComponent {
  /**
   * Align class for flex container
   */
  @Input() classAlign = 'justify-content-between';
  /**
   * Read-only mode flag
   */
  @Input() readonly lyView: boolean;
}
