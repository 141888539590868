import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LangModel } from '@libs/lang/lang.model';
import {
  TariffZoneBody,
  TariffZoneModel,
  TariffZoneModelId,
} from '@libs/tariff-zone/tariff-zone.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MultilangInputModel } from '@ui/multilang-input/multilang-input.model';

/**
 * Zone add/edit form
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.scss'],
})
export class ZoneFormComponent {
  /**
   * Zone id
   */
  @Input() zoneId: TariffZoneModelId;
  /**
   * List languages
   */
  @Input() languages: LangModel[];
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * Cancel event
   *
   * @returns Zone Id
   */
  @Output() cancel: EventEmitter<TariffZoneModelId> =
    new EventEmitter<TariffZoneModelId>();
  /**
   * Edit event
   *
   * @returns Body zone object
   */
  @Output() edit: EventEmitter<TariffZoneBody> =
    new EventEmitter<TariffZoneBody>();
  /**
   * Add event
   *
   * @returns Body zone object
   */
  @Output() add: EventEmitter<TariffZoneBody> =
    new EventEmitter<TariffZoneBody>();
  /**
   * Form object
   */
  readonly form: FormGroup = this.fb.group({
    names: this.fb.control(null, [Validators.required]),
    zoneNum: this.fb.control(null, [Validators.required]),
    srvId: this.fb.control(null, [Validators.required]),
  });
  /**
   * Name Multilang patch variable
   */
  names: MultilangInputModel[];

  constructor(private fb: FormBuilder) {}

  /**
   * Service id
   */
  @Input() set srvId(srvId: number) {
    if (srvId) {
      this.form.patchValue({ srvId });
    }
  }

  /**
   * Service id
   */
  @Input() set zone(zone: TariffZoneModel) {
    if (zone) {
      this.form.patchValue(zone);
      this.names = zone.names;
    }
  }

  handlerReset() {
    this.cancel.emit(this.zoneId);
  }

  handlerEditStop() {
    this.edit.emit(this.form.getRawValue());
  }

  handlerAddStop() {
    const { names, srvId } = this.form.getRawValue();
    this.add.emit({
      ...{ names, srvId },
      ...(this.form.get('zoneNum').value
        ? { zoneNum: +this.form.get('zoneNum').value }
        : {}),
    });
  }
}
