import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

/**
 * UI Button
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    /**
     * Size xs | sm | md | lg | xl
     */
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
    /**
     * Icon
     */
    @Input() icon: string;
    /**
     * Style type primary|outline
     */
    @Input() type: 'primary' | 'outline' = 'primary';
    /**
     * Button text or ngx-translate code
     */
    @Input() label: string;
    /**
     * Text badge
     */
    @Input() badgeText: string;
    /**
     * Text badge style
     */
    @Input() badgeColorClass: string = 'bg-danger';
    /**
     * Text badge for screen reader
     */
    @Input() badgeLabelSr: string;
    /**
     * Disable flag
     */
    @Input() disabled: boolean;
    /**
     * 100% width btn
     */
    @Input() wide: boolean;
    /**
     * Click handler
     */
    @Output() handlerClick: EventEmitter<unknown> = new EventEmitter<unknown>();
}
