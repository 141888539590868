<h2 class="mb-3">{{ 'global.forms.labels.dateFrom' | translate }}</h2>
<form class="d-flex align-items-center" [formGroup]="form">
  <ui-calendar-control
    class="mb-0"
    [minDate]="minDate"
    [formControlName]="'dateFrom'"
  >
  </ui-calendar-control>
  <timepicker
    class="ms-5"
    [formControlName]="'timeFrom'"
    [showMeridian]="timeFormat.showMeridian"
  ></timepicker>
</form>
<ui-button-row>
  <ui-button
    [size]="'lg'"
    [label]="'global.labels.save'"
    (handlerClick)="handlerSetNextVersion()"
  >
  </ui-button>
</ui-button-row>
