import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { RouteStopBody, RouteStopDirectionType, RouteStopModel } from '@services/route-stops/route-stops.model';
import { SdIdType } from '@services/routes/routes.model';
import { StopsService } from '@libs/stops/stops.service';
import { Observable } from 'rxjs';
import { StopModel } from '@libs/stops/stop.model';
import { AbstractListModel } from '@libs/factory/abstract-list/abstract-list.model';
import { RouteStopsService } from '@services/route-stops/route-stops.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteVersionService } from '@services/route-version/route-version.service';
import { RouteVersionEntriesModel } from '@services/route-version/route-version.model';
import { map, switchMap } from 'rxjs/operators';
import { TariffZoneService } from '@libs/tariff-zone/tariff-zone.service';
import { TariffZoneModel } from '@libs/tariff-zone/tariff-zone.model';
import { GetServiceDetailResponse } from '@api/models/BoLanka/Contract/get-service-detail-response';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-add-stop-to-route',
    templateUrl: './popup-add-stop-to-route.component.html',
    styleUrls: ['./popup-add-stop-to-route.component.scss'],
})
export class PopupAddStopToRouteComponent {
    readonly #push: PushService = inject(PushService);
    readonly #stopsService: StopsService = inject(StopsService);
    readonly #routeStopsService: RouteStopsService = inject(RouteStopsService);
    readonly #routeVersionService: RouteVersionService = inject(RouteVersionService);
    readonly #tariffZoneService: TariffZoneService = inject(TariffZoneService);

    readonly direction: RouteStopDirectionType =
        this.data.params?.serviceParams?.direction;
    readonly sdId: SdIdType = this.data.params?.serviceParams?.sdId;

    readonly route: GetServiceDetailResponse = this.data.params?.serviceParams?.route;
    readonly stop: RouteStopModel = this.data.params?.serviceParams?.stop;

    readonly versions$: Observable<RouteVersionEntriesModel> =
        this.#routeVersionService.getVersion(this.sdId);
    readonly stopsSelected$: Observable<RouteStopModel[]> =
        this.data.params?.serviceParams?.stopsSelected;
    readonly stopTypes: AbstractListModel[] = this.#stopsService.stopTypes;
    readonly pending$: Observable<boolean> = this.#routeStopsService.pending;
    readonly zones$: Observable<TariffZoneModel[]> =
        this.#tariffZoneService.getByServiceId(this.route.srvId);

    readonly stopsForDirection$: Observable<StopModel[]> =
        this.stopsSelected$.pipe(
            switchMap((stopsSelected) =>
                this.#stopsService.stops.pipe(
                    map((stops) => {
                        const ids = stopsSelected.map((stop) => stop.stId);
                        return stops.filter((stop) => ids?.indexOf(stop.stId) === -1);
                    }),
                ),
            ),
        );

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        public dialogRef: MatDialogRef<PopupAddStopToRouteComponent>,
    ) {
    }

    handlerAddStopToRoute(body: RouteStopBody): void {
        if (!this.stop) {
            this.#routeStopsService
                .add(body)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.#push.success(PushCodeModel.SuccessAdd);
                    this.dialogRef.close();
                });
        } else {
            this.#routeStopsService
                .edit(body)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.#push.success(PushCodeModel.SuccessChange);
                    this.dialogRef.close();
                });
        }
    }
}
